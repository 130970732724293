import React,{useEffect,useState} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
// import Header from '../components/Header/Header'
import { Link, useParams } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import User_icon from "../assets/images/user-icon.png"
import ReactStars from 'react-stars'
import Usericon from "../assets/images/user-icon.png"
import { toast } from 'react-toastify'
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"

const Appointment = () => {
    const user_id=localStorage.getItem('user_id')
const token=Cookiesservices.get('jwt')
const[profilehome,setprofilehome]=useState([])
  // useEffect(()=>{
  //   const fetchdatauser=async()=>{
  //       const respose=await axios.get(`https://creen-program.com/api/users/${user_id}`,{
  //           headers:{
  //               Authorization:`Bearer ${token}`
  //           }
  //       })
  //       setprofilehome(respose.data.data)
  //   }
  //   fetchdatauser()
  // },[user_id, token]
  // )
const {id}=useParams()
const [user , setuser]=useState([[]])
//  const[reserve,setreserve]=useState([])
 const[doctor,setdoctor]=useState([])

  // useEffect(()=>{
  //   const fetchdetails=async()=>{
  //       const respose=await axios.get(`https://creen-program.com/api/doctors/prescription/${id}`,{
  //           headers:{
  //               Authorization:`Bearer ${token}`
  //           }
  //       })
  //       // setreserve(respose.data.data.trainer)
  //       setuser(respose.data.data.trainer.user)
  //       setdoctor(respose.data.data.trainer.doctor)


  //   }
  //   fetchdetails()

  // },[id,token])
  const[pharmaceuticals,setpharmaceuticals]=useState()
  const[details,setdetails]=useState()
  
    const fetchdetails=async()=>{
        const respose=await axios.post(`https://creen-program.com/api/doctors/reservations/details?pharmaceuticals=${pharmaceuticals}&details=${details}&reservation_id=${id}`,{},{
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
respose.status===200?toast.success("تم انشاء مذكرة")&&window.location.reload():toast.error("error")

    }
    useEffect(() => {
      window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
    }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch user and doctor prescription data concurrently
                const [userResponse, doctorResponse] = await Promise.all([
                    axios.get(`https://creen-program.com/api/users/${user_id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }),
                    axios.get(`https://creen-program.com/api/doctors/prescription/${id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                ]);
    
                // Extract data from responses and update state
                setprofilehome(userResponse.data.data);
                setuser(doctorResponse.data.data.trainer.user);
                setdoctor(doctorResponse.data.data.trainer.doctor);
            } catch (error) {
                // Handle errors
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [user_id, id, token]);
    
  return (
    <>
   <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>
<div className="appointment" style={{width:"100%",height:"100vh"}}>
<div style={{borderBottom:"1px solid #ddd",paddingRight:"10px",paddingTop:"10px"}}>
<Link style={{textDecoration:"none",color:"#000"}} /*to={`/trainer/${data.id}`}*/>
        <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"35px"}}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column",alignItems:"end"}}>
        <span style={{    fontSize: "23px",
        color: "rgb(102 99 99)"}}>{doctor.name}</span>
        <span style={{    fontSize: "24px",
        color: "rgb(102 99 99)"}}>{doctor.joptitle}</span>
        <ReactStars
  count={5} 
 value={5}
  size={32}
  color={'#ffd700'} />
      </div>
      <img src={profilehome.profile!==null?profilehome.profile:User_icon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
    </div>
    <h3 style={{textAlign:"end"}}>{doctor.hospital}</h3>
        </Link>
</div>

<div className="appoint" style={{    display: "flex",
    width: "100%",
    justifyContent: "end",paddingLeft:"10px",paddingTop:"10px",paddingRight:"10px"}}>
    <div className="left" style={{textAlign: "end",
    width: "100%"}}>
        <h3>{user.name}</h3>
        <span><span>{user.gender==="male"?"ذكر":"انثي"}</span>:<span> الجنس</span></span>
        <div style={{display: "flex",
    width: "100%",
    justifyContent: "space-between"}}><span>الرياض</span><span>العمر</span></div>
    </div>
    <div className="right">      <img src={user.profile!==null?user.profile:Usericon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} /></div>
</div>
<div className="formm" style={{display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginTop: "18px",
    justifyContent: "center",
    alignItems: "center"}}>
    <textarea name="" id="" onChange={(e)=>{setdetails(e.currentTarget.value)}} placeholder='الحالة' style={{width: "95%",
    border: "none",
    outline: "1px solid green",
    textAlign: "end",
    padding: "6px",
    borderRadius: "4px"}}></textarea>
    <textarea name="" id="" onChange={(e)=>{setpharmaceuticals(e.currentTarget.value)}} placeholder='الادوية' style={{width: "95%",
    border: "none",
    outline: "1px solid green",
    textAlign: "end",
    padding: "6px",
    borderRadius: "4px"}}></textarea>
    <button style={{    color: "#fff",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#0ac70a",
    padding: "2px 10px",
    borderRadius: "4px"}} onClick={    fetchdetails    }>انشاء مذكرة</button>
</div>
</div>

        

      </section>
      <LinksHome />

    </section>
    <Footer/>

  </>  
  )
}

export default Appointment