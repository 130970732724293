import React from 'react'
import Links from '../../assets/data/Links'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import "../links-home/Links.css"

const LinksHome = () => {
  return (
    <section className='section_home' style={{width:"20%"}} >
    <div className='links_home' style={{paddingRight:"7px"}}>
    {
      Links.map((link , index)=>(
        
        <div className="link_home" style={{display:"flex",alignItems:"center",fontSize:"12px" , gap:"1px"}} key={index} >
        <Link  to={  link.src} key={link.id}><motion.span whileTap={{scale:1.2}}>{link.name}</motion.span></Link>
        <img src={link.imgUrl} alt="" style={{width:"25px",height:"25"}} /></div>
      ))
    }
    </div>
  </section>
  )
}

export default LinksHome