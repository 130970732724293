import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { cartActions } from '../../redux/slices/cartSlice'
import {  toast } from 'react-toastify';
import "../../styles/product.css"
import { useNavigate } from 'react-router-dom';
import Cookiesservices from '../../Services/Cookiesservices';
const Product = ({item , index}) => {
  const token=Cookiesservices.get("jwt")
  const navigate=useNavigate()
    const dispatch=useDispatch()
    const imgurl=item.image !==null ? item.image.url : item.category.icon
    const addToCart=()=>{
        dispatch(cartActions.addItem({
          id:item.id,
          productName:item.title,
          price:item.price,
          imgUrl: imgurl
        }))
        
        toast.success('Product added successfully')
      }
    
  return (
    
        
        item.image != null ?
            <Link  className="product" key={index}>
            <div className="img_product" style={{position:"relative"}}><span style={{    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    height: "50px",
    background: "#fff",
    borderRadius: "20px",
    cursor: "pointer",
    position: "absolute",
    right: "5px",
    bottom: "5px"}}onClick={addToCart}><i class="ri-shopping-cart-line"  style={{fontSize:"1.2rem"}}></i></span><img   className="img-product" src={item.image.url} style={{borderRadius:"10px",width:"190px",height:"160px"}} alt="" /></div>
            <div className="desc"><p>{item.description}</p></div>
            <div className="price" style={{display:"flex",justifyContent:"end",alignItems:"center"}}><span>ريال سعودي</span><span style={{fontSize:"larger"}}> {item.price}</span></div>
           <Link to={`/products/${item.id}`}><button>شاهد المنتج</button></Link> 
        </Link>
            :item.category.icon?<Link  className="product" key={index}>
            <div className="img_product" style={{position:"relative"}}><span style={{    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    height: "50px",
    background: "#fff",
    borderRadius: "20px",
    cursor: "pointer",
    position: "absolute",
    right: "5px",
    bottom: "5px"}}onClick={()=>{token?addToCart():navigate("/login")}}><i class="ri-shopping-cart-line" style={{fontSize:"1.2rem"}}></i></span><img src={item.category.icon} style={{borderRadius:"10px",width:"190px",height:"160px"}} alt="" /></div>
            <div className="desc"><p>{item.description}</p></div>
            <div className="price" style={{display:"flex",justifyContent:"end",alignItems:"center"}}><span>ريال سعودي</span><span style={{fontSize:"larger"}}> {item.price}</span></div>
            <Link to={`/products/${item.id}`}><button>شاهد المنتج</button></Link> 
        </Link>:<></>
          )
}

export default Product