import { useEffect, useState } from "react"

const  ScrollComponent  = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://creen-program.com/api/blogs?page=${page}`, {
                    method: 'post',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'accept': 'application/json'
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const newData = await response.json();
                setData((prevData) => [...prevData, ...newData.data.data]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [page]); 

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
        ) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div>
            {data.map((post, index) => (
                <h1 key={index}>{post.title}</h1>
            ))}
        </div>
    );
}

export default  ScrollComponent ;