import React, { useState , useEffect} from 'react'
import "../styles/Winderthrow.css"
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import { toast } from 'react-toastify'
// import Header from '../components/Header/Header'
import Headermob from '../components/headermobile/Headermob'
import Footer from '../components/footer/Footer'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
const Winderthrow = () => {
    const [amount,setamount]=useState();
    const token=Cookiesservices.get('jwt')
    const upload = async () => {
      try {
        const response = await axios.post(
          `https://creen-program.com/api/wallets/recieve_money?payment=2&amount=${amount}`,
          {}, // جسم الطلب هنا يجب أن يكون فارغًا
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json; charset=UTF-8',
              Accept: 'application/json'
            }
          }
        );
        if(response.status===200){
          toast.success("تم ارسال طلبك بنجاح")

        }
        // التحقق من الاستجابة والتعامل معها بشكل مناسب
        if ( !response.data.status && response.status === 400 && response.data.message==="your request is in queue") {
          console.log(response.data.status);
          toast.success("طلبك قيد التنفيذ");
        } 
      } catch (error) {
        // التعامل مع الخطأ في حال حدوثه
        console.error("حدث خطأ:", error);
        toast.error("حدث خطأ ما");
       
      }
    };
    
    
  
    useEffect(() => {
      window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
    }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
    
  return (<>
<Headermob/>  
 
< section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%", display: "flex", justifyContent: "center", gap: "19px" }}>

        <div style={{width:"80%"}}>
          <div class="container " style={{padding:"0px",height:"100%",minHeight:"100vh"}}>
        {/* <div className='wallet-bg'>
            <div class="d-flex align-items-center pt-5" style={{ minHeight:"28vh",
    display: "flex",
    justifyContent: "",
    paddingRight: "15px",
    flexDirection: "row-reverse",
    paddingLeft: "10px"}}>
                <img src={usericon} class="rounded-circle mx-3" style={{width:"50px",height:"50px"}} alt=""/>
                <h1 class="text-light">احمد محمد جمعة</h1>
            </div>
            <div class="d-flex justify-content-end align-items-center" style={{minHeight:"100%",textAlign: "end"}}>
                <p class="mx-3 my-4 text-light" style={{fontSize: "23px"}}>الرصيد المتوفر
                    <span class="d-block" style={{fontWeight: "bold",direction: "rtl",textAlign: "right"}}>5000 SAR</span>
                </p>
                <img src={creen} style={{width:"120px"}} alt=""/>
            </div>
        </div> */}
        <div class="wrapper">
            <div class="app-wrapper">
                <content class="transactions-wrapper">
                    <div class="row row4">
                        <h3>سحب رصيد </h3>
                    </div>
                </content>
                <div class="container p-5">
                    <div class="form-group fill " id="part">
                        <input type="number" name="payment" onChange={(e)=>{setamount(e.currentTarget.value)}} class="form-control form-control-input myinput" id="amount" placeholder="SAR.0"
                            required=""/>
                    </div>
                    <button onClick={()=>{upload()}} type="submit" class="btn btn-success mt-2"><i
                            class="feather mr-2 icon-credit-card"></i>&nbsp;سحب</button>
                </div>
            </div>
        </div>
    </div>
    </div>
          

        </section>
        <LinksHome />

      </section>
     

 <Footer/>
  </>
    
    
  )
}

export default Winderthrow