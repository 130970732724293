import React, { useEffect, useState} from 'react'
import background from "../assets/images/background.jpg"
import usericon from "../assets/images/user-icon.png"
import ReactStars from 'react-stars'
import right from "../assets/images/right-removebg-preview.png"
import parcode from "../assets/images/WhatsApp Image 2024-01-28 at 21.39.49_e9437ea8.jpg"
import "../styles/Homeuser.css"
import play from "../assets/images/play-button_icon-icons.com_53922.png"
import documents from "../assets/images/Theme256_25022.png"
import services from "../assets/images/clinic_heh_hand_hospital_cross_services_medical_icon_259713.png"
import ads from "../assets/images/istockphoto-1297171607-1024x1024-removebg-preview.png"
import products from "../assets/images/shopping-bag-icon-png-0.jpg"
import basket from "../assets/images/shopping-cart-line.png"
import money from "../assets/images/grow_portfolio_icon_147031.png"
import chats from "../assets/images/icon-removebg-preview.png"
import exist from "../assets/images/share-2-line.png"
import deleteaccount from "../assets/images/delete-bin-7-fill.png"
import privacy from "../assets/images/privacy.jpg"
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import Headermob from "../components/headermobile/Headermob"
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Footer from "../components/footer/Footer"

const Homeuser = () => {
  const {id}=useParams()
  const user_id=localStorage.getItem('user_id')
  const token=Cookiesservices.get('jwt')
  const[data,setdata]=useState([])
  

const [followerss, setfellowerss] = useState("")
  const [followingg, setfellowingg] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        // جلب بيانات المستخدم
        const userResponse = await axios.get(`https://creen-program.com/api/users/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setdata(userResponse.data.data);
  
        // جلب عدد المتابعين
        const followersResponse = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${id}`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        if (followersResponse.status === 200) {
          setfellowerss(followersResponse.data.data);
        }
  
        // جلب عدد المتابعات
        const followingResponse = await axios.post(`https://creen-program.com/api/followers/followingcount?user_id=${id}`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        if (followingResponse.status === 200) {
          setfellowingg(followingResponse.data.data);
        }
      } catch (error) {
        // التعامل مع الأخطاء
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [id, token, user_id]);
  
const navigate=useNavigate();
const handle_logout=()=>{
  setTimeout( ()=>{ Cookiesservices.remove('jwt')},1000  );
setTimeout(  ()=>{toast.success("تم تسجيل الخروج بنجاح")},1005) 
 setTimeout(() => {
  navigate("/home")
 }, 1010);
  
 
  
}
useEffect(() => {
  window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
}, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
const [showalert,setshowalert]=useState(false);
localStorage.setItem("userid",id)
    return (
   <>
 <Headermob/>
   < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>

        <div className="home-user" style={{    width: "100%",
        background: `${showalert?"#ddd":"#fff"}`,
        height: "100%",minHeight:"127vh",position:"relative"}}>

    
<div className="background" style={{    display: "flex",
    flexDirection: "column",
    position: "relative"}}>
            {<img src={data.cover!==null?data.cover:background} alt="" style={{height:"221px"}} />}
            <div className="user-profile" style={{display: "flex",justifyContent: "space-around"}}>
            <div style={{position: "absolute",
    left: "11px",
    bottom: "-96px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "14px"}}>
        <span style={{    border: "3px solid black",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center"}}><i style={{fontSize:"1.7rem"}} class="ri-run-line"></i></span>
        <Link to={"https://creen-program.com/services/allusers"}  style={{    textDecoration:"none",color: "#7d7d7d",
    border: "2px solid #848484c7",
    padding: "3px",
    fontSize: "13px",borderRadius:"5px"}}>المشتركين</Link>
    </div>
            <div className="info-user-icon" style={{        display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "-145px",
    left: "50%",
    transform: "translateX(-50%)"}}>
            <img src={data.profile?data.profile:usericon} alt="" style={{width:"100px",height:"100px",borderRadius:"50%"}} />
            <span>
                {data.name}
            </span>
            <span style={{    fontSize: "10px",
    width: "162px",
    textAlign: "center"}}>{data.email}</span>
            <ReactStars
  count={5} 
  value={data.num_of_rating}
  size={24}
  color={'#ffd700'} />
  <span>مدرب لياقه</span>
            </div>
<div className="mode" style={{position: "absolute",
    right: "16px",
    bottom: "-50px",
}}>            <span><i style={{fontSize:"1.7rem"}} class="ri-sun-line"></i></span>
</div>
            </div>
        </div>
        <div className="follwers-followingright" style={{display: "flex",
    marginTop: "151px",
    alignItems: "center",
    justifyContent: "space-around"}}>
            <div className="img"><img src={right} alt="" style={{width:"30px",height:"30px"}} /></div>
            <div style={{    display: "flex",
    gap: "5px"}}>
                <Link to={`https://creen-program.com/users/getfolower/${id}`} style={{display: "flex",textDecoration:"none",color:"#000",
    flexDirection: "column",
    alignItems: "center",fontSize:"14px"}}><span>المتابعين</span><span>{followerss}</span></Link><hr  />
                <Link to={`https://creen-program.com/users/myfolower/${id}`} style={{display: "flex",textDecoration:"none",color:"#000",
    flexDirection: "column",
    alignItems: "center",fontSize:"14px"}}><span>يتابع</span><span>{followingg}</span></Link><hr  /> 
                <span style={{display: "flex",
    flexDirection: "column",
    alignItems: "center",fontSize:"14px"}}><span>الأعجابات</span><span>0</span></span>
            </div>
            <div className="parcode"><img src={parcode} alt="" style={{width:"30px",height:"30px"}} /></div>
        </div>
        <div className="action-user" style={{padding: "10px",
    display: "grid",
    gridTemplateColumns:"repeat(3,1fr)",
   columnGap:"10px" ,   
    rowGap: "14px"}}>
         <Link to={"/towatch"} style={{textDecoration:"none",color:"#000"}}>
         <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",    border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
               
                <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={play} alt="" />
            <span style={{    fontSize: "14px"}}>فيديو</span>
            
        </div>
         </Link>
        <Link to={"/livenow"} className="link-user" style={{   color:"#000" ,textDecoration:"none",display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <span style={{    background: "red",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    textAlign: "center",
    color: "#fff"}}>live</span>
            <span style={{    fontSize: "14px"}}>بث مباشر</span>
        </Link>
        <Link to={`/my_topics/${id}`} style={{textDecoration:"none",color:"#000"}}>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={documents} alt="" />
            <span style={{    fontSize: "14px"}}>المواضيع</span>
        </div>
        </Link>

        
        <Link  className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px",textDecoration:"none",color:"#000"}} to={"/services"}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={services} alt="" />
            <span style={{    fontSize: "14px"}}>الخدمات</span>
        </Link>
        <Link to={"https://creen-program.com/promotion/myads"} className="link-user" style={{   textDecoration:"none",color:"#000", display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={ads} alt="" />
            <span style={{    fontSize: "14px"}}>إعلاناتي</span>
        </Link>
        <Link to={"/myproducts"} style={{textDecoration:"none",color:"#000"}}>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px"}} src={products} alt="" />
            <span style={{    fontSize: "14px"}}>منتجاتي</span>
        </div>
        </Link>
       <Link to={"/cart"} style={{textDecoration:"none",color:"#000"}}> 
       <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={basket} alt="" />
            <span style={{    fontSize: "14px"}}>السلة</span>
        </div></Link> 
        
        <Link to={"/wallet"} style={{textDecoration:"none",color:"#000"}}>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={money} alt="" />
            <span style={{    fontSize: "14px"}}>المحفظة</span>
        </div></Link>
        <Link to={"https://creen-program.com/chats"} className="link-user" style={{  textDecoration:"none"  ,display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px" , color:"#000"}}>
           <i style={{fontSize:"1.4rem"}} class="ri-chat-3-line"></i>
            <span style={{    fontSize: "14px"}}>المحادثات</span>
        </Link>
        <div onClick={handle_logout} className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%",transform: "rotate(89deg)"}} src={exist} alt="" />
            <span style={{    fontSize: "14px"}}>تسجيل الخروج</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}} onClick={()=>setshowalert(!showalert)}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={deleteaccount} alt="" />
            <span style={{    fontSize: "14px"}}>حذف الحساب</span>
        </div>
        <Link  to={"https://creen-program.com/policies_center"} className="link-user" style={{    textDecoration:"none",color:"#000",display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={privacy} alt="" />
            <span style={{    fontSize: "14px"}}>الخصوصية</span>
        </Link>

        </div>
        
          {showalert &&
            <div className='alert' style={{    width: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",    top: "50%",position:"relative",
            left: "50%",
            transform: "translate(-50%, -50%)"}}>
                                <h4>هل تريد حذف الحساب؟</h4>
                                <div style={{    width: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row-reverse"}}>
                                  <button style={{    background: "#08ac08",
            color: "#fff",
            border: "none",
            padding: "5px",
            borderRadius: "4px",
            width: "50px"}} >نعم</button>
                                  <button style={{    background: "red",
            color: "#fff",
            border: "none",
            padding: "5px",
            borderRadius: "4px",
            width: "50px"}} onClick={()=>{setshowalert(false)}} >لا</button>
                                </div>
                              
                              </div>
          }
        
       

    </div>
          
        </section>
        <LinksHome />

      </section>
   
      <Footer/>
   </>
  )
}

export default Homeuser