import React from 'react'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ForgotPassword from '../pages/ForgotPassword'
import Home from '../pages/Home'
import { Routes , Route , Navigate} from 'react-router-dom' 
import Wallet from '../pages/Wallet'
import Towatch from '../pages/Towatch'
import ScrollComponent from '../pages/Scroll'
import Products from "../pages/Products"
import ProductDetails from '../pages/ProductDetails'
import Cart from '../pages/Cart'
import Services from '../pages/Services'
import Mytopics from '../pages/Mytopics'
import Videos from "../pages/Videos"
import Homeuser from '../pages/Homeuser'
import Service from "../pages/Service"
import DoneBuy from '../pages/DoneBuy'
import Trainer from '../pages/Trainer'
import Poll from '../pages/Poll'
import Trainerprofile from '../pages/Trainerprofile'
import Charge from "../pages/Charge"
import Winderthrow from '../pages/Winderthrow' 
import Appointment from '../pages/Appointment'
import Doctordetails from '../pages/Doctorsdetails'
import Participants from '../pages/Participants'
import Details from '../pages/Details'
import Creatememo from '../pages/Creatememo'
import Creatememonow from '../pages/Creatememonow'
import Live from '../pages/Live'
import Points from '../pages/Points'
import Livenow from '../pages/Livenow'
import Categorychildren from '../pages/Categorychildren'
import Myproducts from '../pages/Myproducts'
import Notfound from '../pages/Notfound'
import Postview from '../pages/Postview'
const Routers = () => {
  return (
    <Routes>
             <Route path='/' element={<Navigate to={"/home"}/>} /> 

        <Route path='/home' element={<Home/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/forgot-password' element={<ForgotPassword/>}/>
        <Route path='/wallet'  element={<Wallet/>}/>
        <Route path='/towatch'  element={<Towatch/>}/>
        <Route path='/scroll'  element={<ScrollComponent/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/products/:id' element={<ProductDetails/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/my_topics/:id' element={<Mytopics/>}/>
        <Route path='/my_videos' element={<Videos/>}/>
        <Route path='/user/:id' element={<Homeuser/>}/>
        <Route path='/Donebuy' element={<DoneBuy/>}/>
        <Route path='/services/trainer/show/:id' element={<Trainer/>}/>
        <Route path='/poll/:trainer_id' element={<Poll/>}/>
        <Route path='/services/:id' element={<Service/>}/>
        <Route path='/trainer/:id' element={<Trainerprofile/>}/>
        <Route path='/charge' element={<Charge/>}/>
        <Route path='/Winderthrow' element={<Winderthrow/>}/>
        <Route path='/Appointment/:id' element={<Appointment/>}/>
        <Route path='/doctordetails/:id' element={<Doctordetails/>}/>
        <Route path='/Participants' element={<Participants/>} />
        <Route path='/Details/:id' element={<Details/>} />
        <Route path='/memo/:id' element={<Creatememo/>} />
        <Route path='/Creatememonow/:id' element={<Creatememonow/>} />
        <Route path='/live' element={<Live/>} />
        <Route path='/points' element={<Points/>} />
        <Route path='/livenow' element={<Livenow/>} />
        <Route path='/myproducts' element={<Myproducts/>} />
        <Route path='/categorychildren/:id' element={<Categorychildren/>} />
        <Route path='/*' element={<Notfound/>}/>
        <Route path='/postview/:id' element={<Postview/>}/>




    </Routes>
  )
}

export default Routers