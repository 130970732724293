import React from 'react'
import weghit from "../assets/images/03-removebg-preview.png"
import Yes from "../assets/images/yes.png"
import Header from '../components/Header/Header'

const DoneBuy = () => {
  return (
<>
<Header/>
<div className="donebuy" style={{    width: "100%",
    height: "100%",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"}}>
    <img src={weghit} alt="" />
    <img src={Yes} alt="" style={{    width: "200px",
    height: "176px"}} />
    <span style={{    fontSize: "32px",
    fontWeight: "bold",
    color: "green"}}>تم عملية الشراء</span>
    <button style={{border: "none",
    width: "80%",
    padding: "10px",
    background: "#082e34",
    color: "white",
    borderRadius: "5px",
    marginTop: "10px",
    marginBottom: "19px"}}>تواصل مع المدرب</button>
    
</div>
</>
  )
}

export default DoneBuy