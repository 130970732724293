import React , {useEffect , useState,useRef} from 'react'
// import Header from '../components/Header/Header'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import User_icon from "../assets/images/user-icon.png"
import { toast } from 'react-toastify'
import Headermob from "../components/headermobile/Headermob"
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom'
import gift from "../assets/images/gift.jpg"
import barcode from "../assets/images/WhatsApp Image 2024-01-28 at 21.39.49_e9437ea8.jpg"
import Footer from "../components/footer/Footer"



const Mytopics = () => {
    const[data,setData]=useState([])
    const [page, setPage] = useState(1);
   const token=Cookiesservices.get("jwt")
   const user_id = (localStorage.getItem('user_id'));
  //  const[isclicked , setisclicked]=useState(false)
  // const [post_id , setpost_id]=useState("")
  // const [post_id_comment , setpost_id_comment]=useState("")
  const[comment , setcomment]=useState("")
  
  // const follow=async(postId)=>{
  //   setData((prevPosts) =>
  //     prevPosts.map((post) =>
  //       post.id === postId
  //         ? {
  //           ...post,
  //           is_follow: post.is_follow ? false : true,
  //           is_following: !post.is_follow,
  //         }
  //         : post
  //     )
  //   );

  // }
  const[more,setmore]=useState(false)
// const heightcontent=useRef();
const moree=(post_id)=>{
  // setmore((prevmore)=>({
  // ...prevmore,
  // [post_id]:!more[post_id],  
  // more
  // }));
  // setData((prevPosts) =>
  //     prevPosts.map((post) =>
  //       post.id === post_id
  //         ? setmore(!more)
  //         : setmore(false)
  //     ))

    setmore(prevMore => ({
      ...prevMore,
      [post_id]: !prevMore[post_id],
    }));
  };
  const navigate=useNavigate()
  const handlelikepost = (postId) => {
if(token){
  setData((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            likes_count: post.is_like ? post.likes_count - 1 : post.likes_count + 1,
            is_like: !post.is_like,
          }
          : post
      )
    );
    localStorage.setItem("post_id", postId)
}else{
  navigate("/login")
}
  
  };
  
  


  const handelchangecomment=(e)=>{
    if(e.target.value){
      setcomment(e.target.value)
    }else{
      setcomment("")
    }
  }
  
    // useEffect(()=>{
    //     const handlemytopics=async()=>{
    //   try{
    //     const response=await axios.post(`https://creen-program.com/api/blogs/user?user_id=${user_id}`,{},{

    //       headers: {
    //         'Content-type': 'application/json; charset=UTF-8',
    //         'accept': 'application/json',
    //     Authorization:`Bearer ${token}`
    //     },
      
    //     })
    //     if(response.status === 200){
    //       const response=await axios.post(`https://creen-program.com/api/blogs/user?user_id=${user_id}`,{},{
    //         headers: {
    //           'Content-type': 'application/json; charset=UTF-8',
    //           'accept': 'application/json',
    //           Authorization:`Bearer ${token}`,

    //       },
        
    //       })
    //       setData((prevData) => [...prevData, ...response.data.data.data]);
          
    //     }
        
    //   }catch(error){}
      
    //     }
    //     handlemytopics()
    //   },[page, token, user_id])
      const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
        ) {
            setPage((prevPage) => prevPage + 1);
        }
      };
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      const fetchcomment = async (post_id_comment) => {
        try {
          if (post_id_comment) {
            const response = await axios.post(
              `https://creen-program.com/api/comments/create?blog_id=${post_id_comment}&comment=${comment}&user_id=${user_id}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
      
      
      
            if (response.status === 200) {
      
              toast.success("created comment successfully")
      
              setcomment((prevComments) => [...prevComments, response.data.data]);
              //window.location.reload()
              setcomment("")
              fetchcomments(post_id_comment)
              
              
            }
      
          }
        } catch (error) {
          if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            console.error('Status code:', error.response.status);
          } else if (error.request) {
            console.error('No response received from the server');
          } else {
            console.error('Error setting up the request:', error.message);
          }
        }
      
      
      }
      
  const fetchcomments=async(post_id)=>{
    try {
      if(post_id){
        const response = await axios.post(`https://creen-program.com/api/comments/posts?post_id=${post_id}`, {},{
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'accept': 'application/json',
            Authorization: `Bearer ${token}`
          },
  
        })
  
  
        setcomments(response.data.data);
       
      }
      




    } catch (error) { }

  }
  const [comments , setcomments]=useState([])

      const menuRef=useRef(null);
      
      const menuToggle=()=>{
        menuRef.current.classList.toggle('active_menu')
      }
      
      const [retweetState, setRetweetState] = useState({});

   const handleretweet = (postId) => {
    if(token){
      setData((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
              ...post,
              retweets_count: retweetState[postId] ? post.retweets_count - 1 : post.retweets_count + 1,
            }
          : post
      )
    );
    setRetweetState((prevRetweetState) => ({
      ...prevRetweetState,
      [postId]: !prevRetweetState[postId],
    }));
    }else{
      navigate("/login")
    }
    
  
  };
  const [under, setunder] = useState(false);

  const openmodelunder = (post_id) => {
    if(token){
      setunder((prevunder) => ({
        ...prevunder,
        [post_id]: !prevunder[post_id],
      }));
      document.body.style.overflow="hidden"
    }else{
      navigate("/login")
    }
    
  }
  const [expand, setexpand] = useState(false)

  const Togglercomment = (post_id) => {
    if(token){
      setexpand((prevExpand) => ({
        ...prevExpand,
        [post_id]: !prevExpand[post_id],
      }));
    }else{
      navigate("/login")
    }
    
  };
  const[profilehome,setprofilehome]=useState([])
  // useEffect(()=>{
  //   const fetchdatauser=async()=>{
  //       const respose=await axios.get(`https://creen-program.com/api/users/${user_id}`,{
  //           headers:{
  //               Authorization:`Bearer ${token}`
  //           }
  //       })
  //       setprofilehome(respose.data.data)
  //   }
  //   fetchdatauser()
  // },[user_id, token]
  // )
  const user_name = (localStorage.getItem('user_name'));
  const email = (localStorage.getItem('email'));
  const [sun, setsun] = useState("sun")
  const [followerss, setfellowerss] = useState("")
  const [followingg, setfellowingg] = useState([])

  // useEffect(() => {
  //   const followers = async () => {
  //     try {
  //       const response = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         })
  //       if (response.status === 200) {
  //         // setfellowerss((prev)=>[...prev , ...response.data.data])
  //         //  console.log(followerss)
  //         setfellowerss(response.data.data)

  //       }
  //     } catch (error) { }
  //   }
  //   const following = async () => {
  //     try {
  //       const response = await axios.post(`https://creen-program.com/api/followers/followingcount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         })
  //       if (response.status === 200) {
  //         // setfellowerss((prev)=>[...prev , ...response.data.data])
  //         //  console.log(followerss)
  //         setfellowingg(response.data.data)

  //       }
  //     } catch (error) { }
  //   }
  //   followers()
  //   following()
  // }, [token, user_id])
  const [files, setFiles] = useState([]);
  const [title, settitle] = useState("");
  const [documentt, setdocument] = useState("");
  const [youtube, setyoutube] = useState("");
  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handletitle = (e) => {
    settitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setdocument(e.target.value);
  };

  const handleyoutube = (e) => {
    setyoutube(e.target.value);
  };
  const [category_id, setcategoryid] = useState("")
  const [categories, setcatigories] = useState([])
 



  const [modaledit, setmodaledit] = useState(false);
  const [modal, setmodal] = useState(false);
  const modedit = (post_id, User_id) => {
    // eslint-disable-next-line eqeqeq
    if (User_id == user_id) {
      setmodaledit((prevunder) => ({
        ...prevunder,
        [post_id]: !prevunder[post_id],
      }));
      // eslint-disable-next-line eqeqeq
    } else if (User_id != user_id) {
      setmodal((prevunder) => ({
        ...prevunder,
        [post_id]: !prevunder[post_id],
      }));
    } else {
      setmodal(false)
      setmodaledit(false)
    }


  }
  const deletepost = async (blog_id) => {
    try {
      const response = await axios.post(`https://creen-program.com/api/blogs/delete?blog_id=${blog_id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === true) {
        toast.success("تم حذف البوست");
        setmodal(false);
        setmodaledit(false);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      // يمكنك إضافة إخطار للمستخدم هنا في حالة فشل الحذف
    }
  };
  const [show2, setshow2] = useState(false)
  const updatepost = async (blog_id) => {


    try {
      const apiUrl = "https://creen-program.com/api/blogs/update";


      const formData = new FormData();
      formData.append("blog_id", blog_id)
      files.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });
      formData.append("category_id", category_id);
      // formData.append("images", files[0]);
      formData.append("title", title);
      formData.append("content", documentt);
      formData.append("youtube", youtube);



      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,

        },
        body: formData,
      });

      if (response.status === 200) {
        console.log(response)
        toast.success("File update successfully");
        setshow2(!show2)
        //  window.location.reload();
      }
    } catch (error) {
      console.error('Error creating post:', error);
      if (error.response && error.response.status === 400) {
        console.error('Server response data:', error.response.data);
      }
      toast.error('An error occurred while creating the post');
    }

  }
  // useEffect(() => {
  //   const handlefetchcategortid = async () => {
  //     const respose = await axios.get(`https://creen-program.com/api/categories`, {
  //       headers: {
  //         'Content-type': 'application/json; charset=UTF-8',
  //         'accept': 'application/json'
  //         , Authorization: `Bearer ${token}`
  //       },
  //     })
  //     setcatigories(respose.data.data)


  //   }
  //   handlefetchcategortid()
  // }, [token])
  const handlechangecategoryid = (e) => {
    if (e.target.value) {
      setcategoryid(e.target.value)

    } else {
      // setcategoryid("")
      // setcatigories()
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // جلب بيانات المواضيع الخاصة بالمستخدم
  //       const myTopicsResponse = await axios.post(`https://creen-program.com/api/blogs/user?user_id=${user_id}`,{},{
  //         headers: {
  //           'Content-type': 'application/json; charset=UTF-8',
  //           'accept': 'application/json',
  //           Authorization:`Bearer ${token}`
  //         },
  //       });
  
  //       // جلب بيانات المستخدم
  //       const userDataResponse = await axios.get(`https://creen-program.com/api/users/${user_id}`,{
  //         headers:{
  //           Authorization:`Bearer ${token}`
  //         }
  //       });
  
  //       // جلب عدد المتابعين
  //       const followersResponse = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         });
  
  //       // جلب عدد المتابعات
  //       const followingResponse = await axios.post(`https://creen-program.com/api/followers/followingcount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         });
  
  //       // جلب قائمة الفئات
  //       const categoriesResponse = await axios.get(`https://creen-program.com/api/categories`, {
  //         headers: {
  //           'Content-type': 'application/json; charset=UTF-8',
  //           'accept': 'application/json',
  //           Authorization: `Bearer ${token}`
  //         },
  //       });
  
  //       // تحديث الحالة بناءً على البيانات المستلمة
  //       if (myTopicsResponse.status === 200) {
  //         setData((prevData) => [...prevData, ...myTopicsResponse.data.data.data]);
  //       }
  
  //       if (userDataResponse.status === 200) {
  //         setprofilehome(userDataResponse.data.data);
  //       }
  
  //       if (followersResponse.status === 200) {
  //         setfellowerss(followersResponse.data.data);
  //       }
  
  //       if (followingResponse.status === 200) {
  //         setfellowingg(followingResponse.data.data);
  //       }
  
  //       if (categoriesResponse.status === 200) {
  //         setcatigories(categoriesResponse.data.data);
  //       }
  
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       toast.error('Error fetching data:', error.message);
  //     }
  //   };
  
  //   // استدعاء دالة جلب البيانات عند تغيير أحد الاعتماديات
  //   fetchData();
  // }, [page, token, user_id]);
  const id=localStorage.getItem("userid")
  const fetchData = async () => {
    try {
        // Parallel requests
        const [myTopicsResponse, userDataResponse, followersResponse, followingResponse, categoriesResponse] = await Promise.all([
            axios.post(`https://creen-program.com/api/blogs/user?user_id=${id}`, {}, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }),
            axios.get(`https://creen-program.com/api/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }),
            axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }),
            axios.post(`https://creen-program.com/api/followers/followingcount?user_id=${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }),
            axios.get(`https://creen-program.com/api/categories`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
        ]);

        // Update state based on responses
        if (myTopicsResponse && myTopicsResponse.status === 200) {
            setData(prevData => [...prevData, ...myTopicsResponse.data.data.data]);
        }
        if (userDataResponse && userDataResponse.status === 200) {
            setprofilehome(userDataResponse.data.data);
        }
        if (followersResponse && followersResponse.status === 200) {
            setfellowerss(followersResponse.data.data);
        }
        if (followingResponse && followingResponse.status === 200) {
            setfellowingg(followingResponse.data.data);
        }
        if (categoriesResponse && categoriesResponse.status === 200) {
            setcatigories(categoriesResponse.data.data);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data:', error.message);
    }
};

  const closeshare=(post_id)=>{
    setunder((prevunder) => ({
      ...prevunder,
      [post_id]: !prevunder[post_id],
    }));
    document.body.style.overflow="auto"


  }
  
  useEffect(() => {
    fetchData();
}, [ token, id]);
  return (
    <>
<Headermob/> 
  < section style={{width:"100%",display:'flex',justifyContent:"space-between"}}>
    <ChatHome/>
      <section className='center' style={{width:"59%",display:"flex",justifyContent:"space-between",gap:"5px",height:`${data.length===0?"100vh":"100%"}`}}>
      <div className="left" style={{ width: "35%", display: "flex", flexDirection: "column", gap: ".5rem" }}>
            <div className="card" style={{ backgroundImage: "#fff", boxShadow: " 0 1px 2px rgba(0, 0, 0, 0.2)", marginTop: "24px" }}>
              <div className="image" style={{ height: '120px', background: " linear-gradient(180deg, #102b1d, #57ed94)" }}>

              </div>
              <div className="log_info">
                <img src={profilehome && token ? profilehome.profile : User_icon} alt="" />
                <Link>{profilehome.name&&token?profilehome.name:"مستخدم جديد"}</Link>
                <span style={{ color: " #bababa", fontSize: "14px" }}>{email}</span>
              </div>
              <div className="log_followers">
                <Link><span><b>الاعجابات</b></span>
                  <span style={{ color: "#bababa" }}>0</span></Link>
                <Link><span><b>متابَعون</b></span>
                  <span style={{ color: "#bababa" }}>{followerss}</span></Link>
                <Link>
                  <span><b>متابِعون</b></span>
                  <span style={{ color: "#bababa" }}>{followingg}</span></Link>
              </div>
            </div>
            <div className="barcode-darkmode" style={{ background: "#fff", borderRadius: "5px", padding: '10px' }}>
              <div className="header" style={{ textAlign: "end", fontSize: "14.5px", display: "flex", justifyContent: "space-around" }} >

                <img style={{ width: "40px", height: "40px" }} src={barcode} alt="" />
                <div className="dark_mood">
                  {
                    sun === "sun" ?
                      <span onClick={() => setsun('dark')}><i style={{ fontSize: "1.8rem" }} class="ri-sun-line"></i></span> : <span onClick={() => setsun("sun")}><i style={{ fontSize: "1.8rem" }} class="ri-moon-fill"></i></span>
                  }
                </div>


              </div>
            </div>
            <div className="group" style={{ background: "#fff", borderRadius: "5px", padding: '10px' }}>
              <div className="header" style={{ textAlign: "end", fontSize: "14.5px" }} ><span><b>!الهاشتاغات النشطة</b></span></div>
            </div>
            <div className="featured-users" style={{ position: "sticky", top: "76px" }}>
              <div className="header">
                <span style={{ fontSize: "14px" }}>الأعضاء المحترفون</span>
                <span style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <i class="ri-vip-crown-line"></i>
                  <span style={{ fontWeight: "bold", fontSize: "13px", color: "#666" }}>اشتركاتي</span>
                </span>
              </div>
            </div>
            <div className="users_know" style={{ position: "sticky", top: "127px" }}>
              <div className="header">
                <span><i class="ri-restart-line"></i></span>
                <span>أشخاص قد تعرفهم</span>
              </div>
            </div>
            <div className="online" style={{ position: "sticky", top: "176px" }}>
              <span><i class="ri-group-fill"></i></span>
              <span><span>2</span> من المستخدمين متصلين</span>
            </div>
            <div className="email" style={{ position: "sticky", top: "264px" }}>
              <span><i class="ri-mail-open-fill" style={{ color: "orange", fontSize: "40px" }}></i></span>
              <span style={{ color: "#986600" }}>دعوة اصدقائك</span>
              <form>

                <div class="buttons" style={{ display: "flex", marginTop: "8px" }}>
                  <button style={{
                    color: "#c0d2bc",
                    backgroundColor: "#062b34",
                  }}>
                    <i class="ri-send-plane-2-fill"></i>
                  </button>
                  <input type="text" placeholder="البريد الألكتروني" />
                </div>

              </form>
            </div>
            <div className="activities" style={{ position: "sticky", top: "429px" }}>
              <div className="header" style={{ textAlign: "end" }}>
                <span>أحدث المنتجات</span>
              </div>
            </div>
            <div className="activities" style={{ position: "sticky", top: "479px" }}>
              <div className="header" style={{ textAlign: "end" }}>
                <span>أخر الانشطة</span>
              </div>
            </div>
            <div className="activities" style={{ position: "sticky", top: "529px" }}>
              <div className="header" style={{ textAlign: "end" }}>
                <span>أخر الاخبار</span>
              </div>
            </div>
          </div>
     
      <div className="posts posts_my" style={{display:"flex",flexDirection:"column",gap:"10px",width:"65%",marginTop:"22px"}}>
    
                          {
                
                            data.map((item,index)=>(
                              <div className="post" style={{position:"relative",background:`${under[item.id]?"#ddd":"#fff"}`}} key={index}>

{
                        modaledit[item.id] && <div className="modal-edit" style={{
                          width: "max-content",
                          padding: "10px 10px 5px 40px",
                          position: "absolute",
                          background: "#fff",
                          top: "52px",
                          left: "7px",
                          borderRadius: "4px"
                        }}>
                          <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "end",
                            marginBottom: "0px", paddingLeft: "0px"
                          }}>
                            <li style={{ cursor: "pointer" }} onClick={() => { deletepost(item.id) }}>حذف</li>
                            <li style={{ cursor: "pointer" }} onClick={() => { setshow2(!show2) }} >تعديل</li>
                          </ul>
                        </div>
                      }
                      {
                        show2 === true && <div className="add_post_click "   >
                          <div className="con"  >
                            <div className="header">
                              <span><i class="ri-emotion-happy-line" style={{ fontSize: "1.2rem" }}></i></span>
                              <div className="info_user">
                                <span>{user_name}</span>
                                <img src={profilehome && token ? profilehome.profile : User_icon ? User_icon : ""} alt="" />
                              </div>

                            </div>
                            <span style={{ display: "flex", alignItems: "center", gap: "2px", marginTop: "10px" }}>
                              <select required name="" id="" value={category_id} style={{ width: "100%" }} onChange={handlechangecategoryid}>
                                <option value="أختر"> أختر الاقسام</option>
                                {
                                  categories.map((item) => (

                                    <option value={item.id}>{item.name}</option>
                                  ))
                                }
                              </select>
                            </span>
                            <input type="text" placeholder='العنوان' value={title} onChange={handletitle} style={{ marginTop: "10px", width: "100%" }} />
                            <input type="text" placeholder='رابط اليوتيوب' value={youtube} onChange={handleyoutube} style={{ marginTop: "10px", width: "100%" }} />
                            <input style={{ marginTop: "10px", height: "110px", width: "100%" }} value={documentt} onChange={handleContentChange} type="text" placeholder='الموضوع' />
                            <div class="footer">
                              <div class="buttons" style={{ textAlign: "center" }}>
                                <div class="img">
                                  <span style={{ fontSize: "14px" }}>تحميل الصورة</span>
                                  <span><i class="ri-image-fill" style={{ fontSize: "1.2rem", color: "#0dcaf0" }}></i></span>
                                  <input type="file" id="" multiple onChange={handleFileChange} />
                                </div>
                              </div>
                              <div className="button_share" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                                <button className='btn_add_post' onClick={() => { updatepost(item.id) }} >شارك</button>
                                <button style={{
                                  border: "none",
                                  background: "none",
                                  fontSize: "1.4rem",
                                  paddingRight: "5px"
                                }} onClick={() => { setshow2(!show2) }}><i class="ri-close-line"></i></button>



                              </div>

                            </div>
                          </div>
                        </div>
                      }
    <div className="post_header" style={{    paddingRight: "9px"}}>
                      <div className="left" style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px", marginLeft: "4px"
                      }}><span onClick={() => { modedit(item.id, item.user_id) }}><i class="ri-more-2-line" style={{fontSize:"1.2rem",cursor:"pointer"}}></i></span>
                        </div>
                      <Link to={`/user/${item.user_id}` }style={{textDecoration:"none",color:"#000"}}>
                      <div className="right">
                        <div className="left">
                          <div className="user" style={{ textAlign: "end" }}>{item.user.name}</div>
                          <div className="type_category" style={{ justifyContent: "end" }}>
                            <span style={{ textAlign: "end", color: "red" }}>{item.category.name}</span>
                            <span style={{ direction: "rtl", display: "none" }}>{item.created_at}</span>
                          </div>
                        </div>
                        <div className="right">
                          {
                            item.user.profile == null ? <img loading='lazy' src={User_icon} alt="" style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }} /> : <img loading='lazy' src={item.user.profile} alt="" style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%"
                            }} />
                          }
                        </div>
                      </div>

                      </Link>                    </div>
                      <div className="post_content" style={{ textAlign: "end", marginTop: "5px", display: "flex", flexDirection: "column", gap: "10px" }}>
                        {
                          item.content !== "" && item.image === "" && item.youtube === "" ? <p style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end", padding: "10px 13px 0 10px"
                          }}>
                            <h2 style={{    overflow: "hidden",
    textAlign: "end",
    width: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color:"#000"}}>{item.title}</h2>
                            <p style={{
                            height: `${more[item.id] ? "100%" : "30px"}`,
                            overflow: "hidden",
                            textAlign: "end", width: "100%", fontSize: "18px",
                            fontWeight: "bold",
                            color: "#07070796",
                          }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p> :
                            item.content === "" && item.image !== "" && item.youtube === "" ? <div><img loading='lazy' onClick={menuToggle} src={item.image} style={{ width: "100%", height: "350px" }} alt=''></img>  </div> :
                              item.content === "" && item.images_count === 1 && item.youtube !== "" ? <iframe id="ytplayer" title='youtube' width="100%" height="340"
                                src={item.youtube} frameborder="0"></iframe> :
                                item.content !== "" && item.images_count === 1 && item.youtube === "" ? <div ><img loading='lazy' style={{ width: "100%", height: "350px" }} src={item.image} alt=''></img> <p style={{
                                  display: `${item.content ===null?"none":"flex"}`,
                                  flexDirection: "column",
                                  alignItems: "end",
                                  paddingTop: "4px",
                                  paddingRight: "11px"
                                }}>
                                   <h2 style={{    overflow: "hidden",
    textAlign: "end",
    width: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color:"#000"}}>{item.title}</h2>
                                  <p style={{
                                  height: `${more[item.id] ? "100%" : "50px"}`,
                                  overflow: "hidden",
                                  textAlign: "end", width: "100%", fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#07070796"
                                }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p></div> :
                                  item.content !== "" && item.image === "" && item.youtube !== "" ? <><iframe id="ytplayer" title='youtube' width="100%" height="340"
                                    src={item.youtube} frameborder="0"></iframe>
                                    <p style={{
                                     display: `${item.content ===null?"none":"flex"}`,
                                      flexDirection: "column",
                                      alignItems: "end", width: "100%"
                                    }}><h2 style={{    overflow: "hidden",
                                    textAlign: "end",
                                    width: "100%",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color:"#000"}}>{item.title}</h2>
                                                                  <p style={{
                                                                  height: `${more[item.id] ? "100%" : "50px"}`,
                                                                  overflow: "hidden",
                                                                  textAlign: "end", width: "100%", fontSize: "18px",
                                                                  fontWeight: "bold",
                                                                  color: "#07070796"
                                                                }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p></> :
                                    item.images_count > 1 ?
                                      <Swiper
                                        style={{
                                          width: "100%",
                                          height: "370px", marginLeft: "0px"
                                        }}
                                        // install Swiper modules
                                        modules={[Navigation, Pagination, Autoplay]}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        navigation


                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 3000 }}
                                        loop={true} // تكرار العرض


                                      // scrollbar={{ draggable: true }}

                                      >
                                        {
                                          item.images.map((item, index) => (

                                            <SwiperSlide style={{ width: "100%", marginLeft: "0px" }} key={index}><img loading='lazy' src={item.url} style={{
                                              width: "100%",
                                              height: "350px"
                                            }} alt='' /></SwiperSlide>
                                          ))
                                        }

                                      </Swiper>
                                      : <>""</>

                        }
                      </div>
                    <div className="seen-addfollow" style={{     padding: "0px 10px"
,display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <span style={{ marginLeft: "4px" }}><img src={gift} style={{
                        width: "40px",
                        height: "40px"
                      }} alt="" /></span>
                      <span style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px", marginRight: "4px"
                      }}><span style={{ color: "green" }}>{item.seen}</span><span><i class="ri-eye-line" style={{ fontSize: "1.4rem",  color: "#c0c0c0" }}></i></span></span>
                    </div>
                    <div className="buttons_action" style={{
                      display: "flex", justifyContent: "space-around", borderTop: "1px solid #c0c0c0",
                      marginTop: "5px"
                    }}>
                      <Link to={`https://creen-program.com/chats/share/${item.id}`} className='internal_share' style={{ transform: "scaleX(-1)",textDecoration:"none" }}><i class="ri-share-forward-line" style={{
                        fontSize: "1.9rem", cursor: "pointer",  color: "#c0c0c0", transform: "scaleX(-1)"


                      }}></i></Link>
                      <span className='retweet' onClick={() => {  handleretweet(item.id); }} style={{ display: "flex", flexDirection: "column", alignItems: "center",  color: "#c0c0c0" }}>
                        {
                           retweetState[item.id] ? <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "green" }}></i> : <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer" }}></i>
                        }<span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.retweets_count}</span></span>

                      <span className='external_share' onClick={() => { openmodelunder(item.id) }}><i class="ri-share-line" style={{ fontSize: "1.9rem", cursor: "pointer",  color: "#c0c0c0" }}></i></span>

                      <span className='comment' onClick={() => { Togglercomment(item.id);fetchcomments(item.id) }} style={{ display: "flex", flexDirection: "column", alignItems: "center",color: "#c0c0c0"   }}><i class="ri-message-3-line" style={{ fontSize: "1.9rem", cursor: "pointer",color: "#c0c0c0" }}></i><span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.comments_count}</span></span>

                      <span
                        className='heart'
                        onClick={() => handlelikepost(item.id)}
                        style={{ display: "flex", flexDirection: "column", alignItems: "center",color:"#c0c0c0" }}
                      >
                        {item.is_like ? (
                          <>      <i className="ri-heart-fill" style={{color:"red" ,fontSize: "1.9rem", cursor: "pointer" }}></i>

                          </>
                        ) : (
                          <>      <i className="ri-heart-line" style={{ fontSize: "1.9rem", cursor: "pointer" }}></i>

                          </>
                        )}
                        <span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.likes_count}</span>

                      </span>



                    </div>
                    {
                        under[item.id] &&
                        <div>

<div className="modal-share" style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "10px", position: "absolute",
                          top: `${item.youtube !== ""?"70%":"50%"}`,
                          left: "50%", zIndex: "9999999",transform:"translate(-50%,-50%)"
                        }}>
                          <button style={{
                            padding: "10px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#000",
                            backgroundColor: "transparent",border:"none",fontSize:"1.4rem"
                          }} onClick={()=>{closeshare(item.id)}}><i class="ri-close-line"></i></button>
                          <Link to={`https://wa.me/?text=https://creen.creen-program.com/postview/${item.id}`} style={{ textDecoration: "none", color: "green" }}><i style={{ fontSize: "2.4rem" }} class="ri-whatsapp-fill"></i></Link>
                          <Link style={{ textDecoration: "none", color: "#1DA1F2" }} to={`https://twitter.com/intent/tweet?text=Default+share+text&amp;url=https://creen.creen-program.com/postview/${item.id}`}><i style={{ fontSize: "2.4rem" }} class="ri-twitter-fill"></i></Link>
                          <Link style={{ textDecoration: "none", color: "#3b5998" }} to={`https://www.facebook.com/sharer/sharer.php?u=https://creen.creen-program.com/postview/${item.id}`}><i style={{ fontSize: "2.4rem" }} class="ri-facebook-box-fill"></i></Link>
                        </div>
                        </div>
                      }
                    {
                      <> {
                      comments.length !== 0 && expand[item.id] &&

                        comments.map((item, index) => (

                          <div className="comment" key={index} style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            marginBottom: "15px",
                            padding: "10px"
                          }}>
                            <div className="left" style={{ width: "100%" }}>
                              <div className="top" style={{ display: "flex", justifyContent: "space-between " ,paddingRight:"10px"}}>
                                <div className="action"><i class="ri-more-2-line"></i></div>
                                <div className="user_name">{item.user_name}</div>
                              </div>
                              <div className="info" style={{
                                background: "rgb(221 221 221 / 30%)",
                                padding: "15px",
                                textAlign: "end",
                                marginTop: "5px", marginLeft: "15px"
                              }}>

                                <div className="content_comment" style={{ lineBreak: "anywhere" }}>{item.comment}</div>
                              </div>
                            </div>
                            <div className="righ"><img style={{ width: "25px", height: "25px", borderRadius: "50%" }} src={item.user !== "" ? item.logo : User_icon} alt=''></img></div>
                          </div>

                        ))


                      }

                      </>
                    }
                    {
                      expand[item.id] &&
                      <div className="add_comment" style={{ marginTop: "10px" }}>
                        <div className="form" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <div className="send" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <span onClick={() =>{ fetchcomment(item.id);}}><i class="ri-send-plane-2-fill" style={{ fontSize: "1.5rem", cursor: "pointer",paddingLeft: "11px" }}></i></span>

                          </div>
                          < input type="text" placeholder="اكتب تعليق وأضغط أنتر .." style={{ background: "#fff", border: "1px solid #7d7d7d" }} onChange={handelchangecomment} />

                          <img src={User_icon} style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="" />
                        </div>

                      </div>
                    }


                          </div>
                            ))
                          }
                        </div>
                       
      </section>
      <LinksHome/>
     
    </section>
   <Footer/>
    </>
  )
}

export default Mytopics