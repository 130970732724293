import React , {useState , useEffect} from 'react'
import "../Productcard/ProductCard.css"
import { toast } from 'react-toastify'
import Product from './Product'
//import { motion } from 'framer-motion';
import axios from 'axios'

const ProductsCard = () => {

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

   
useEffect(() => {
    // إنشاء دالة لجلب البيانات من الخادم
    const fetchData = async () => {
        try {
            // إرسال الطلب إلى الخادم لجلب البيانات
            const response = await axios.post(
                `  https://creen-program.com/api/products?page=${page}   
                `, 
                {},
                {
                    // إضافة الرؤوس اللازمة للطلب
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Accept': 'application/json',
                    },
                }
            );
  
            // تحديث حالة البيانات باستخدام البيانات المستلمة من الطلب
            setData((prevData) => {
              const newData = response.data.data.products.data;
              // استخدم Set لتحديد القيم الفريدة فقط
              const uniqueData = Array.from(new Set([...prevData, ...newData]));
              return uniqueData;
            });      } catch (error) {
            // معالجة الأخطاء في حالة وجودها
            // console.error('Error fetching data:', error);
            toast.error('Error fetching data:', error.message);
        }
    };
  
    // استدعاء دالة جلب البيانات عند تغيير أحد الاعتماديات
    fetchData();
  }, [page]);
    
    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
        ) {
            setPage((prevPage) => prevPage + 1);
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
  /*  const addToCart=()=>{
        dispatch(cartActions.addItem({
            id:data.id,
            productName:newItem.productName,
            description:newItem.description,
            imgUrl:newItem.imgUrl,
            price:newItem.price,
            quantity:1,
            totalPrice:newItem.price
        })
           )
           toast.success('Product added successfully')
      }*/
    

  return (
    <div className="products">
    {
        data.map((item , index)=>(
            <Product item={item} index={index}/>
           
        ))
    }
</div>
  )
}

export default ProductsCard