import React ,{ useEffect,useState,useCallback}  from 'react'
// import Header from '../components/Header/Header'
import "../styles/Wallet.css"
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
import user_icon from "../assets/images/user-icon.png"
import { Link } from 'react-router-dom'
import creen from "../assets/images/ic_launcher.png"
import "../styles/charge.css"
import axios from 'axios'
import Headermob from '../components/headermobile/Headermob'
import Footer from '../components/footer/Footer'
import Cookiesservices from '../Services/Cookiesservices'
const Wallet = () => {
    
  
 useEffect(() => {
  window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
}, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
const [profilehome, setProfileHome] = useState({});
const [loadingProfile, setLoadingProfile] = useState(true);
const user_id=localStorage.getItem('user_id')
const token=Cookiesservices.get('jwt')
useEffect(() => {
  const fetchDataUser = async (user_id, token) => {
    try {
      const response = await axios.get(`https://creen-program.com/api/users/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('خطأ في الطلبات المتكررة:', error);
        return null; // توقف عملية الاسترجاع في حالة حدوث خطأ 429
      } else {
        throw error; // رمي الخطأ في حالة حدوث أي خطأ آخر
      }
    }
  };

  const source = axios.CancelToken.source(); // إنشاء مصدر لإلغاء الطلب
  let retries = 0; // عدد مرات المحاولات
  const maxRetries = 3; // الحد الأقصى لعدد مرات المحاولة
  const retryDelay = 3000; // تأخير بين كل محاولة (بالميللي ثانية)

  const fetchUserProfile = async () => {
    try {
      const userProfile = await fetchDataUser(user_id, token);
      if (userProfile !== null) {
        setProfileHome(userProfile);
        setLoadingProfile(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 429 && retries < maxRetries) {
        console.error('تم تجاوز الحد الأقصى لعدد المحاولات. يتم إعادة المحاولة بعد التأخير.');
        setTimeout(fetchUserProfile, retryDelay);
        retries++;
      } else {
        console.error('حدث خطأ في جلب ملف المستخدم:', error);
        setLoadingProfile(false);
      }
    }
  };

  if (user_id && token) {
    fetchUserProfile();
  }

  return () => {
    source.cancel('تم إلغاء الطلب'); // إلغاء الطلب عند إلغاء المكون
  };
}, [user_id, token]);  
const [walletData, setWalletData] = useState([]);

const fetchWalletData = useCallback(async () => {
  try {
    const response = await axios.post(
      'https://creen-program.com/api/wallets',
      {},
      {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'accept': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (response.status === 200) {
      setWalletData(response.data.data);
    } else {
      throw new Error('Failed to fetch wallet data');
    }
  } catch (error) {
    console.error('Error fetching wallet data:', error);
    // يمكنك التعامل مع الخطأ هنا أو تمريره إلى مكان آخر للتعامل معه
  }
}, [token]);

useEffect(() => {
  fetchWalletData();
}, [token,fetchWalletData]);
  return (
    <>
<Headermob/>
<section style={{width:"100%",display:'flex',justifyContent:"space-between"}}> 
    <ChatHome/>
<section style={{width:"100%"}}>
<div class="container " style={{padding:"0px",margin:"0px",maxWidth:"100%"}}>
        <div className='wallet-bg'>
            <div  style={{       height: "100%",
    display: "flex",
    gap:"10px",
    padding: "20px 36px 0 20px",    flexDirection: "row-reverse"}}>
                <img src={profilehome.profile!==null?profilehome.profile:user_icon} class="rounded-circle" style={{width:"50px",height:"50px"}} alt=""/>
                <h1 class="text-light">{profilehome.name}</h1>
            </div>
            <div  style={{ textAlign: "end",height:"100%",minHeight:"22vh",    display: "flex",
    alignItems: "center"}}>
                <p class="mx-3 my-4 text-light" style={{fontSize: "23px",width:"100%"}}>الرصيد المتوفر
                    <span class="d-block" style={{fontWeight: "bold",direction: "rtl",textAlign: "right"}}> {walletData.total ? `${walletData.total} SAR` : 'Loading...'}</span>
                </p>
                <div style={{width:"65%"}}>                <img src={creen}  alt="" style={{width:"120px"}}/>
</div>
            </div>
        </div>
        <div class="wrapper" style={{marginTop:"10px"}}>
            <div class="app-wrapper">
                <div class="wallet-wrapper">
                    <div class="cards">
                        <div class="cards" style={{display:"flex"}}>
                        <div class="credit-card mc">
                                <Link to={"/points"} style={{textDecoration:"none",color:"#000"}}>
                                <div class="card-image">
                                    <h2 style={{padding:"0",fontSize:"20px"}}>النقاط</h2>
                                </div></Link>
                            </div>
                            
                            <div class="credit-card amex">
                                <Link to={"/Winderthrow"} style={{textDecoration:"none",color:"#000"}}>
                                <div class="card-image">
                                    <h2 style={{padding:"0",fontSize:"20px"}}>سحب</h2>
                                </div>
                                </Link>
                            </div>
                            <div class="credit-card visa active">
                                <Link to={"https://creen-program.com/payment/add"} style={{textDecoration:"none",color:"#000"}}>
                                    <div class="card-image">
                                    
                                    <h2 style={{padding:"0",fontSize:"20px"}}>شحن</h2>
                            </div></Link>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div class="transactions-wrapper" style={{marginTop:"-35px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                    <div class="row row1 " style={{width:"88%",height:"135px"}}>
                <h3 style={{fontSize:"30px"}}>رصيد المحفظة</h3>
                        <p class="text-center" style={{color:"#fff",fontSize:"30px"}}> {walletData.total ? `${walletData.total} SAR` : 'Loading...'}</p>
                    </div>
                    <div class="row row2" style={{width:"88%",height:"135px"}}>
                        <h3 style={{    color: "#03767d",fontSize:"30px"}}>المشتريات </h3>
                        <p class="text-center" style={{    color:" #03767d",fontSize:"30px"}}>4000 SAR</p>
                    </div>
                    <div class="row row3" style={{width:"88%",height:"135px"}}>
                        <h3 style={{fontSize:"30px",    color: "#03767d"}}>المبيعات </h3>
                        <p class="text-center" style={{    color: "#03767d",fontSize:"30px"}}>2600 SAR</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </section>
    <LinksHome/>
</section>
<Footer/>
    </>

    )
}

export default Wallet