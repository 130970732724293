import React from 'react'
import  { useState} from 'react'
import { Link } from 'react-router-dom'
import "../chat-home/chat_home.css"
const ChatHome = () => {
  const [tab,settab]=useState('group')

  return (
<section className='chat_home'>
        <div className="header_chat">
          <div className="icons">
            <span><i class="ri-settings-4-line"></i></span>
            <span><i class="ri-user-add-line"></i></span>
          </div>
          <span style={{width:"max-content"}}>الأصدقاء المتصلين</span>
        </div>
        <div className="buttons">
          <Link><span className={ `${tab==='group'?'chat_active': ''} `} onClick={()=>settab('group')}><i class="ri-team-line"></i></span></Link>
          <Link><span className={ `${tab==='contacts'?'chat_active': ''} `} onClick={()=>settab('contacts')}><i class="ri-contacts-line"></i></span></Link>
        </div>
<div className="content" >
  {
    tab === 'group'?<div className="group"> </div>: <div className="contacts"></div>
  }
</div>
<div className="search_box" style={{position: "absolute",
    bottom: "3px",
    width: "100%"}}>
<input type="text" placeholder="إبحث عن مستخدمين" />
</div>
      </section>
  )
}

export default ChatHome