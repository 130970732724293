import React,{useEffect,useState} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
import { Link } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"
const Live = () => {
const token=Cookiesservices.get('jwt')

  const [live,setlive]=useState([])
  useEffect(() => {
    const fetchlive = async () => {
        try {
            const response = await axios.get(`https://creen-program.com/api/video/live`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setlive(response.data.data);
            console.log("Live videos fetched successfully:", response.data.data);
        } catch (error) {
            console.error("Error fetching live videos:", error);
        }
    };
    fetchlive();
}, [token]);
  const [active,setactive]=useState("شرائح")
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون 
  return (
    <>
   <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", flexDirection:"column", gap: "19px" }}>
   <div style={{    marginTop: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "space-around"}}>
      <div style={{width:"100%"}}>
      <button style={{background:"transparent",border:"none",paddingBottom:"10px",width:"100%"}} onClick={()=>{setactive("شاشات")}} >شاشات مفتوحة</button>
      <div style={{background:`${active==="شاشات"?"green":""}`,width: "100%",height: "4px"}}></div>
</div>
<div style={{width:"100%"}}>    
  <button style={{background:"transparent",border:"none",paddingBottom:"10px",width:"100%"}} onClick={()=>{setactive('شرائح')}} >شرائح</button>
<div style={{background: `${active==="شرائح"?"green":""}`,width: "100%",height: "4px"}}></div>
   </div> 
   </div>
<div className="lives" style={{height:"max-content",minHeight:"100vh",display:`${active==='شاشات'?"flex":"grid"}`,width:"100%",direction:"rtl",flexDirection:`${active==="شاشات"?"column":""}`,gridTemplateColumns:"repeat(2,1fr)"}}>
 

    {
        live.map((item)=>(
            <Link to={item.join_url} className="live" style={{textDecoration:"none",height:"max-content",position:"relative",width:`${active==="شاشات"?"100%":""}`}}>
        <img src={item.image} style={{width: "100%",
    height: `${active==="شاشات"?"81vh":"350px"}`,
    padding: "10px",
    borderRadius: "14px"}} alt="" />
    <div className="info" style={{    alignItems: "center",
    display: "flex",
    position: "absolute",
    bottom: "18px",
    textAlign: "end",
    right: "17px",
    gap:"13px",
    direction:"ltr"
}}>
        <div className="left" style={{    display: "flex",
    flexDirection: "column",color:"#fff",fontSize:"11px",direction:"ltr",}}>
            <span>{item.user.name}</span>
            <span>{item.title}</span>
        </div>
        <div className="right"><img style={{width:"50px",height:"50px",borderRadius:"50%"}} src={item.user.profile} alt="" /></div>
    </div>
    </Link>
        ))
    }
</div>

        

      </section>
      <LinksHome />

    </section>
    <Footer/>

  </>  
  )
}

export default Live