import React , {useEffect , useState} from 'react'
import Headermob from '../components/headermobile/Headermob'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import { Link } from 'react-router-dom'
import User_icon from "../assets/images/user-icon.png"

const Notfound = () => {
  const[profilehome,setprofilehome]=useState([])
  const user_id=localStorage.getItem('user_id')
     const token=Cookiesservices.get('jwt')
  useEffect(()=>{
    const fetchdatauser=async()=>{
        const respose=await axios.get(`https://creen-program.com/api/users/${user_id}`,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
        setprofilehome(respose.data.data)
    }
    fetchdatauser()
  },[user_id, token]
  )
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  return (
    <>
    <Headermob/>
    <div className="not-found" style={{    margin: "auto",
    textAlign: "center",
    position: "relative",
    height: "100vh",
    background: "#fff"}}>
     <div style={{ position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    height: "100vh",
    top: "38%"}}>
        <h2 style={{fontSize: "73px"}}>404</h2>
        <h5>لم يتم العثور علي الصفحة</h5>
     </div>
    </div>
    <div class="footer-mobile" style={{
        background: "#fff",
        border: "none",
        height: "48px",
        position: "sticky",
        zIndex: "9999",
        bottom: "0", display: "none"
      }}>
        <div class="container">
          <ul style={{
            paddingLeft: "0", display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
                 <Link to={`${token ?`/user/${user_id}`:`/login`}`}>
            <img src={profilehome.profile!==null?profilehome.profile:User_icon} alt="" style={{ width: "40px", height: "40px", cursor: "pointer",borderRadius:"50%" }} />
</Link>   
          
<Link style={{textDecoration:"none"}} to={"/products"}>
              <li>
                        <span><i style={{fontSize:"1.9rem",cursor:"pointer",color:"#bcbcbc"}} class="ri-shopping-bag-line"></i></span>
                </li></Link>
            <Link to={"/live"}>
          <li style={{    background: "#ddd",
    padding: "5px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center"}}>
              <img src="https://creen-program.com/new/images/m1.png" style={{ width: "1.9rem" }} alt="" />
            </li> 
          </Link>
            <li>
              <Link to={"/towatch"} style={{ textDecoration: "none", color: "#fff" }} > <i style={{
                fontSize: "1.7rem",
                color: "#fff",
                cursor: "pointer",
                borderRadius: "10px",
                width: "40px",
                height: "40px",
                textAlign: "center",
                padding: "2px 8px 2px 8px",
                background: "#7d7d7d",
              }} class="ri-play-line"></i></Link>
            </li>
            <Link to={"/home"}  style={{textDecoration:"none",color:"#7d7d7d"}} >                <i  style={{fontSize:"1.9rem",cursor:"pointer",color:"#7d7d7d"}} class="ri-home-4-line"></i>
</Link>          </ul>
        </div>
      </div>
    </>
   
  )
}

export default Notfound