import React , {useEffect} from 'react'
import usericon from "../assets/images/user-icon.png"
import creen from "../assets/images/ic_launcher.png"
import "../styles/charge.css"

const Charge = () => {
    useEffect(() => {
        // Load Moyasar JavaScript SDK dynamically
        const script = document.createElement('script');
        script.src = 'https://cdn.moyasar.com/mpf/1.13.0/moyasar.js';
        script.async = true;
        document.body.appendChild(script);
    
        // Load Moyasar CSS dynamically
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.moyasar.com/mpf/1.10.0/moyasar.css';
        document.head.appendChild(link);
    
        return () => {
          // Clean up resources when the component unmounts
          document.body.removeChild(script);
          document.head.removeChild(link);
        };
      }, []);
    
       window.Moyasar.init({
        element: '.mysr-form',
        // Amount in the smallest currency unit.
        // For example:
        // 10 SAR = 10 * 100 Halalas
        // 10 KWD = 10 * 1000 Fils
        // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
        amount: 1000,
        currency: 'SAR',
        description: 'Coffee Order #1',
        publishable_api_key: 'pk_test_AQpxBV31a29qhkhUYFYUFjhwllaDVrxSq5ydVNui',
        callback_url: 'https://moyasar.com/thanks',
        methods: ['creditcard']
    })

    
  return (
    <div>
        <div class="container " style={{padding:"0px",margin:"0px"}}>
        <div className='wallet-bg'>
            <div class="d-flex align-items-center pt-5" style={{    flexDirection: "row-reverse"}}>
                <img src={usericon} class="rounded-circle mx-3" style={{width:"50px",height:"50px"}} alt=""/>
                <h1 class="text-light">احمد محمد جمعة</h1>
            </div>
            <div class="d-flex justify-content-between align-items-center" style={{flexDirection: "row-reverse",textAlign: "end"}}>
                <p class="mx-3 my-4 text-light" style={{fontSize: "29px"}}>الرصيد المتوفر
                    <span class="d-block" style={{fontWeight: "bold",direction: "rtl",textAlign: "right"}}>5000 SAR</span>
                </p>
                <img src={creen} style={{width:"120px"}} alt=""/>
            </div>
        </div>
        <div class="wrapper">
            <div class="app-wrapper">
                <content class="transactions-wrapper">
                    <div class="row row4">
                        <h3>شحن المحفظة</h3>
                    </div>

                </content>
                <div class="mysr-form container p-2"></div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Charge