import React,{useEffect,useState} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
// import Header from '../components/Header/Header'
import { useNavigate } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
//import ReactStars from 'react-stars'
import Usericon from "../assets/images/user-icon.png"
//import data from "../assets/images/schedule.png"
import "../styles/Participants.css"
import Headermob from "../components/headermobile/Headermob"
import Footer from '../components/footer/Footer'
const Participants= () => {
const token=Cookiesservices.get('jwt')

 
  const usser_id=localStorage.getItem("Number_id")
  const[reserve,setreserve]=useState([])

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`https://creen-program.com/api/services/showusers2/${usser_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setreserve(response.data.data.users);
      } catch (error) {
        console.error("Error fetching details:", error);
        // يمكنك هنا التعامل مع الخطأ بشكل مناسب، مثلاً عرض رسالة خطأ للمستخدم
      }
    };
  
    fetchDetails();
  }, [usser_id, token]);
  const navigate=useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  
  return (
    <>
    <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>
<div style={{height:"100%",width:"100%"}}>
{
    reserve.length===0?"":reserve.map((item,index)=>(
        <div className="participants" style={{    padding:"20px",width: "100%",marginBottom:"20px",
    display: "flex",justifyContent:"space-between",
    flexDirection: "row-reverse",
    alignItems: "center",borderBottom:"1px solid #ddd"}}>
    <div className="right" style={{display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",gap:"10px"}}>
        <div className="right">      <img src={item.user.profile?item.user.profile:Usericon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
</div>
<div className="left" >
    <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"35px"}}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column",alignItems:"end"}}>
        <span style={{    fontSize: "16px",
        color: "#000"}}>{item.user.name}</span>
        <span style={{    fontSize: "16px",
        color: "#000"}}>{item.type==="online"?"موعد اونلاين":item.type==="ofline"?"موعد في العيادة":"استشارة سريعه"}</span>
        <div className="buttonsss" style={{    marginTop: "12px",
    display: "flex",
    gap: "10px"}}>
            <button style={{border: "2px solid #05bc05",
    background: "transparent",
    padding: "0px 5px",
    borderRadius: "4px",
    color: "#05bc05"}}>مراجع</button>
            <button style={{    background: "rgb(6 43 52)",
    padding: "0px 5px",
    border: "none",
    borderRadius: "4px",
    color: "#fff"}} onClick={()=>{navigate(`/Details/${item.id}`)}}>الاعدادات</button>
        </div>
        
      </div>
    </div>
    </div>
    </div>
    <div className="left" style={{    display: "flex",color:"red",
    flexDirection: "column",
    textAlign: "end"}}>
        <span>{"الجمعه"}</span>
<span style={{display:"flex",gap:"5px"}}><span style={{display:"flex"}}><span>مساء</span><span style={{direction:"rtl"}}>{item.date}</span></span>
</span>
    </div>
    
</div>
    ))
}
</div>

        

      </section>
      <LinksHome />

    </section>
    <Footer/>

  </>  
  )
}

export default Participants