import React,{useEffect,useState} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
// import Header from '../components/Header/Header'
import {  useParams } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import User_icon from "../assets/images/user-icon.png"
//import ReactStars from 'react-stars'
// import Usericon from "../assets/images/user-icon.png"
//import data from "../assets/images/schedule.png"
import edir from "../assets/images/edit2.png"
import { useNavigate } from 'react-router-dom'
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"

const Creatememo= () => {
    const user_id=localStorage.getItem('user_id')
const token=Cookiesservices.get('jwt')
const[profilehome,setprofilehome]=useState([])
const navigate=useNavigate()
useEffect(() => {
  window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
}, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  // useEffect(()=>{
  //   const fetchdatauser=async()=>{
  //       const respose=await axios.get(`https://creen-program.com/api/users/${user_id}`,{
  //           headers:{
  //               Authorization:`Bearer ${token}`
  //           }
  //       })
  //       setprofilehome(respose.data.data)
  //   }
  //   fetchdatauser()
  // },[user_id, token]
  // )
 const{id}=useParams();
 const [user , setuser]=useState([[]])
//  const[reserve,setreserve]=useState([])
 const[doctor,setdoctor]=useState([])

  // useEffect(()=>{
  //   const fetchdetails=async()=>{
  //       const respose=await axios.get(`https://creen-program.com/api/doctors/prescription/${id}`,{
  //           headers:{
  //               Authorization:`Bearer ${token}`
  //           }
  //       })
  //       // setreserve(respose.data.data.trainer)
  //       setuser(respose.data.data.trainer.details)
  //       setdoctor(respose.data.data.trainer.doctor)


  //   }
  //   fetchdetails()

  // },[id,token])
  useEffect(() => {
    const fetchData = async () => {
        try {
            // Fetch user data and doctor prescription data concurrently
            const [userResponse, doctorResponse] = await Promise.all([
                axios.get(`https://creen-program.com/api/users/${user_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }),
                axios.get(`https://creen-program.com/api/doctors/prescription/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            ]);

            // Extract data from responses and update state
            setprofilehome(userResponse.data.data);
            setuser(doctorResponse.data.data.trainer.details);
            setdoctor(doctorResponse.data.data.trainer.doctor);
        } catch (error) {
            // Handle errors
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [user_id, id, token]);

  return (
    <>
   <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>
<div style={{height:"100%",width:"100%"}}>
<div className="creatememo" style={{    width: "100%"}}>
  <div className="headd" style={{    borderBottom:"1px solid #ddd",display: "flex",
    alignItems: "center",
    justifyContent: "end",paddingBottom:"10px",gap:"10px",marginTop:"10px"}}>
    <div className="left" style={{display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: "5px"}}>
        <span>{doctor.name}</span>
        <button style={{border:"none",
    background: "#08d208",
    color: "#fff",
    padding: "2px 6px",
    borderRadius: "4px",width:"100%"}} onClick={()=>{navigate(`/Creatememonow/${id}`)}}>انشاء مذكرة</button>
    </div>
    <div className="right">
    <img src={profilehome.profile!==null?profilehome.profile:User_icon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
    </div>
  </div>
    {
        user.map((item,index)=>(
            <div className="contenttt" key={index} style={{ borderBottom:"1px solid #ddd",display: "flex",
    justifyContent: "space-between",    marginTop: "20px",
    paddingBottom: "10px",
}}>
        <div className="left" style={{display:"flex",marginLeft:"5px",flexDirection:"column"}}>
            <button style={{border: "1px solid #05ce05",
    color: "#05ce05",
    background: "transparent",
    borderRadius: "4px",width:"100%"}}>تعديل</button>
            <img src={edir} alt=""  style={{width:"50px"}}/>
        </div>
        <div className="right" style={{display:"flex",flexDirection:"column",alignItems:"end",gap:"5px"}}>
            <span style={{display:"flex"}}><span>{item.time_ago}</span>:<span>تاريخ الزيارة</span></span>
            <span style={{display:"flex",flexDirection:"row-reverse"}}><span>الحالة</span>:<span>{item.details}</span></span>
            <span style={{display:"flex",flexDirection:"row-reverse"}}><span>الادوية</span>:<span>{item.pharmaceuticals}</span></span>
        </div>
    </div>
        ))
    }
    
</div>
</div>

        

      </section>
      <LinksHome />

    </section>
    <Footer/>

  </>  
  )
}

export default Creatememo