import React , {useRef,useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import { Link} from 'react-router-dom'
import { toast } from 'react-toastify'
import user_icon from "../../assets/images/user-icon.png"
import logo from "../../assets/images/ic_launcher.png"
import "./header.css"
import Cookiesservices from '../../Services/Cookiesservices'
import iconchat from "../../assets/images/icon-removebg-preview.png"
import axios from 'axios'
 import Links from '../../assets/data/Links'
//  import { useNavigate } from 'react-router-dom'
// import { textDecoration } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'






const Header = () => {
  const [tab,settab]=useState('main')
  const menuRef_mobile=useRef(null);
  const token=Cookiesservices.get('jwt')
  // const navigate=useNavigate()
  const navigate=useNavigate()
  const user_id=localStorage.getItem("user_id")
  const handle_logout=()=>{
    toast.success("You have successfully logged out");
    Cookiesservices.remove('jwt')
}
  const[links,setlinks]=useState(false)
  const menuToggle_mobile=(e)=>{
setlinks(!links)

    
  }
  const [notifications , setnotifications]=useState([])
  const [shownoti,setshownoti]=useState(false)
  const [shownotii,setshownotii]=useState(false)
  const togglenoti=()=>{
    setshownoti(!shownoti)
  
    document.body.style.overflow = "hidden"
  }
  const togglenotii=()=>{
    setshownotii(!shownotii)
  
    document.body.style.overflow = "hidden"
  }
  const overref = useRef(null);
  const overreff = useRef(null);
  // const openmodel = () => {
  //   setover(true)
  //   document.body.style.overflow = "hidden"
  // }
  const close = (e) => {
    if (overref.current && !overref.current.contains(e.target)) {
      setshownoti(false)
      document.body.style.overflow = "auto"


    }
  }
  const closef = (e) => {
    if (overreff.current && !overreff.current.contains(e.target)) {
      setshownotii(false)
      document.body.style.overflow = "auto"


    }
  }
  const closemenu = (e) => {
    if (menuRef_mobile.current && !menuRef_mobile.current.contains(e.target)) {
      setlinks(false)
      document.body.style.overflow = "auto"


    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", closemenu)
    return () => {
      document.removeEventListener("mousedown", closemenu)
    }
  }, [])   
  useEffect(() => {
    document.addEventListener("mousedown", closef)
    return () => {
      document.removeEventListener("mousedown", closef)
    }
  }, [])
  
  useEffect(() => {
    document.addEventListener("mousedown", close)
    return () => {
      document.removeEventListener("mousedown", close)
    }
  }, [])
  
  const [dataLoaded, setDataLoaded] = useState(false); // حالة لتتبع ما إذا كانت البيانات قد تمت تحميلها بالفعل


const Navv=()=>{
  if(token){
    navigate(`/user/${user_id}`)
  }else{
    navigate("/login")
  }
}
const [profilehome, setprofilehome] = useState([])
const [loadingProfile, setLoadingProfile] = useState(true); // إضافة حالة للتحقق من حدوث عمليات التحميل المتكررة


const [noti,setnoti]=useState(false);
useEffect(() => {
  const fetchData = async () => {
    try {
      const [userData, profileData] = await Promise.all([
        axios.get(`https://creen-program.com/api/users/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }),
        axios.get('https://creen-program.com/api/notifications', {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'accept': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
      ]);

      if (userData && userData.data.data) {
        setprofilehome(userData.data.data);
      }

      if (profileData && profileData.data.data.data) {
        setnotifications(profileData.data.data.data);
        setDataLoaded(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Too many requests error:', error);
        toast.error('Too many requests. Please wait a moment and try again.');
        return;
      } else {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data. Please try again later.');
      }
    } finally {
      setLoadingProfile(false);
    }
  };

  if (user_id && token && loadingProfile && !dataLoaded) {
    setTimeout(fetchData, 3000); // تأخير الطلب بمقدار 1000 مللي ثانية (1 ثانية)
  }
}, [user_id, token, loadingProfile, dataLoaded]);
const deletenoti=async(notification_id)=>{
  try {
    const response = await axios.post(`https://creen-program.com/api/notifications/destroy?notification_id=${notification_id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data.status === true) {
      toast.success("تم حذف النوتيفيكشن");
      setshownoti(false)
      document.body.style.overflow="auto"
      
    }
  } catch (error) {
    console.error("Error deleting post:", error);
    // يمكنك إضافة إخطار للمستخدم هنا في حالة فشل الحذف
  }}
  // const n=(item)=>{
  //   // to={`${item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"?`/postview/${item.model_id}`
  //   // :item.url.includes("lives")?`${item.url}`:
  //   // `https://www.creen-program.com/programs_timeline/private/${item.model_id}`}`}
  //   if(item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"){
  //     navigate(`/postview/${item.model_id}`)
  //   }
  //   else if(item.url.includes("lives")){
  //     navigate(`${item.url}`)
  //   }else if(item.type==="program_timeline"){
  //     navigate(`https://www.creen-program.com/programs_timeline/private/${item.model_id}`)
  //   }
  
  // }

  return (
<>
<header className='header_mobile' style={{display:"none"}} >
{
 noti && <input type="text" placeholder="إبحث عن أعضاء, #هاشتاغ" style={{    position:"absolute",width: "76%",
  zIndex: "99999999999999999999999999999999999999999999999999999999",
  background: "#fff",
  top: "9px",left:"44px"}}/>
}
      <div className="nav_wrapper">
        <div className="icons">
        <div className='profile' onClick={()=>setnoti(!noti)} > <i class="ri-search-line" style={{fontSize:"1.7rem",color:"#fff"}}></i>  
        </div>
        <Link to={"https://creen-program.com/chats"} style={{textDecoration:"none"}}><img src={iconchat} alt="" style={{width: "50px",
    height: "50px",
    borderRadius: "50%",
    color: "#fff"}} /></Link>
        <Link to={"/home"}><div className="logo"><motion.img whileTap={{scale:1.2}} src={ /*currentUser? currentUser.photoURL :*/ logo}  alt="" /*onClick={toggleProfileActions}*/ /></div></Link>

        <Link onClick={togglenotii} style={{position:"relative",width:"max-content"}}><span style={{position: "absolute",
    top: "12px",
    left: "10px", background: "red",
    lineHeight:"1.6",width:"23px",height:"23px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%"}}><span style={{   
      borderRadius: "50%",
      color: "#fff"}}>{notifications.length}</span></span><span ><i class="ri-notification-line"></i></span></Link>
        <Link  className='icon_mobile' onClick={menuToggle_mobile}><span><i class="ri-function-line"></i></span></Link>


        </div>
        
        

        
      </div>
      {
        links&&<section className='section_home_header' style={{   position: "absolute" ,
        right: "0" ,
        backgroundColor: "#f0f2f5" ,
        overflow: "scroll" ,
        width: "max-content",    height: "561px",    padding: "10px",
        boxShadow:" 34px 1px 66px rgba(73, 34, 46, 0.2)",
        zIndex: "6000000000" }} ref={menuRef_mobile} onClick={menuToggle_mobile} >
        <div className='links_home_header' style={{display: "flex",
        flexDirection: "column",
        overflow: "scroll",
        alignItems: "end",lineHeight:"3",paddingBottom:"35px"}}>
        {
          Links.map((link , index)=>(
            
            <div className="link_home" style={{display:"flex",alignItems:"center" , gap:"2px"}} key={index} ><Link style={{textDecoration:"none",color:"#000"}}  to={link.src} key={link.id}><motion.span whileTap={{scale:1.2}}>{link.name}</motion.span></Link>
            <img src={link.imgUrl} alt="" style={{width:"30px",height:"30px"}} /></div>
          ))
        }
        </div>
      </section>
      }
      {/* <section className='links_hom links_home_header' style={{display:"none"}} ref={menuRef_mobile} onClick={menuToggle_mobile}>
    {
      Links.map((link , index)=>(
        
        <div className="link_hom link_home_header" style={{display:"flex",alignItems:"center" , gap:"2px"}} key={index} ><Link  to={link.src} key={link.id}><motion.span whileTap={{scale:1.2}}>{link.name}</motion.span></Link>
        <img src={link.imgUrl} alt="" style={{width:"30px",height:"30px"}} /></div>
      ))
    }
  </section> */}
    
      <div className='user_nouser user_nouser_mobile '  style={{display:"none"}}>
       {
        token?<div className="current_user">
          <Link to={"/my_topics"}>موضوعاتي</Link>
          <Link to={"/my_videos"}>فيديوهاتي</Link>
          <Link to="/products">المتجر</Link> 
          <Link to={"/cart"}>السله</Link>
          <Link to={"/services"}>الخدمات</Link>
          <Link to='/wallet' style={{display:"flex",alignItems:"center",gap:"10px"}}>محفظتي</Link>
          <Link to={"/login"}  onClick={handle_logout} style={{display:"flex",alignItems:"center",gap:"10px",flexDirection:"row-reverse"}}><span><i class="ri-shut-down-line"></i></span>تسجيل الخروج</Link>
               
        </div>: <div className="no_user">
          <Link to='/signup'>التسجيل</Link>
          <Link to='/login'>تسجيل الدخول</Link>
        </div>
       }
      </div>
      {
        shownotii && <div ref={overreff} className="notifications" style={{background: "#fff",
      width:"62%",
        textAlign: "end",
        padding: "10px",
        borderRadius: "10px",
        position: "absolute",
        zIndex: "100000000000000000000000000000000000",
         display: `${shownotii?'block':'none'}`,
        flexDirection: "column",gap: "6px",right:"0px",lineHeight:"normal",maxHeight:"100vh",overflow:"scroll"}}>
                {
                  notifications.map((item,index)=>(
                    <div style={{display:"flex",justifyContent:"space-between",borderBottom: "1px solid #ddd"}}>
                    <div onClick={()=>{deletenoti(item.id)}}>
                      <span><i style={{fontSize:"1.4rem"}} class="ri-close-line"></i></span>
                    </div>
                    <Link   to={`${item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"?`/postview/${item.model_id}`
   :item.url.includes("lives")?`${item.url}`:item.type==="program_timeline"?
   `https://www.creen-program.com/programs_timeline/private/${item.model_id}`:`${item.url}`}`}  className="notification" key={index} style={{    display: "flex",
        flexDirection: "row-reverse",justifyContent:"space-between",
        textDecoration:"none",color:"#000"}}>
                    <div>
                    <p style={{color: "#000",
        direction: "rtl",
        textAlign: "right",
        fontSize: "16px",
        fontWeight: "bold",    display: "flex",
        flexDirection: "row-reverse",
        gap: "5px",justifyContent:"flex-end",marginBottom: "5px"}}>{item.content}<span>{item.type==="comment"?<i class="ri-chat-1-line"></i>:item.type==="like"?<i class="ri-thumb-up-line"></i>:""}</span></p>
                      <span style={ {fontSize: "16px",
        fontWeight: "bold",    marginBottom: "5px"      }}>{item.time_ago}منذ</span>
                    </div>
                    
                    </Link>
                    </div>
                  ))
                }
              </div>
          
        
      }


  
</header>
<header className='header'>
  
      <div className="nav_wrapper">
        <div className="icons_left">
        <div className='profile'> <motion.img whileTap={{scale:1.2}}  onClick={Navv} src={ profilehome && token ? profilehome.profile : user_icon}  alt="" /*onClick={toggleProfileActions}*/ />

   
        
        </div>
        <Link style={{textDecoration:"none",color:"#fff",fontSize:"1.4rem"}} to={"/towatch"} ><i class="ri-live-line"></i></Link>
        <Link style={{textDecoration:"none",color:"#fff",fontSize:"1.4rem"}} to={"https://creen-program.com/chats"}><i class="ri-message-2-line"></i></Link>

        <Link style={{textDecoration:"none",color:"#fff",fontSize:"1.4rem"}} to={"/live"}><i class="ri-play-circle-line"></i></Link>
        </div>
        
        
        <div class="search_box">
          <input type="text" placeholder="إبحث عن أعضاء, #هاشتاغ"   />
          <span><i class="ri-search-line"></i></span>
          </div>
        
        <div className="icons_right">
          <Link><span className={ `${tab==='add'?'active_tab': ''}  add`} onClick={()=>settab('add')}  ><span className='establishment'>إنشاء</span><i class="ri-add-circle-line"></i></span></Link>
          <Link to={"/home"}><span className={ `${tab==='main'?'active_tab': ''}  main`} onClick={()=>settab('main')}><span className='home_page'>الصفحه الرئيسية </span><i class="ri-mail-open-line"></i></span></Link>
          <Link onClick={togglenoti} style={{position:"relative",width:"max-content"}}><span style={{position: "absolute",
    top: "12px",
    left: "10px", background: "red",
    lineHeight:"1.6",width:"23px",height:"23px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%"}}><span style={{   
      borderRadius: "50%",
      color: "#fff"}}>{notifications.length}</span></span><span ><i class="ri-notification-line"></i></span></Link>
                 <Link style={{display:'none'}} className='icon_mobile'><span><i class="ri-align-justify"></i></span></Link>
          <Link to={"/home"}><div className="logo"><motion.img whileTap={{scale:1.2}} src={ /*currentUser? currentUser.photoURL :*/ logo}  alt="" /*onClick={toggleProfileActions}*/ /></div></Link>
          
        </div>
      </div>
      
      {
        shownoti && <div ref={overref} className="notifications" style={{background: "#fff",
      width:"30%",
        textAlign: "end",
        padding: "10px",
        borderRadius: "10px",
        position: "absolute",
        display: `${shownoti?'block':'none'}`,
        zIndex: "100000000000000000000000000000000000",
        flexDirection: "column",gap: "6px",right:"0px",lineHeight:"normal",maxHeight:"100vh",overflow:"scroll"}}>
                {
                  notifications.map((item,index)=>(
                    <div style={{display:"flex",justifyContent:"space-between",borderBottom: "1px solid #ddd"}}>
                    <div onClick={()=>{deletenoti(item.id)}}>
                      <span><i style={{fontSize:"1.4rem"}} class="ri-close-line"></i></span>
                    </div>
                    <Link   to={`${item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"?`/postview/${item.model_id}`
   :item.url.includes("lives")?`${item.url}`:item.type==="program_timeline"?
   `https://www.creen-program.com/programs_timeline/private/${item.model_id}`:`${item.url}`}`}  className="notification" key={index} style={{    display: "flex",
        flexDirection: "row-reverse",justifyContent:"space-between",
        textDecoration:"none",color:"#000"}}>
                    <div>
                    <p style={{color: "#000",
        direction: "rtl",
        textAlign: "right",
        fontSize: "16px",
        fontWeight: "bold",    display: "flex",
        flexDirection: "row-reverse",
        gap: "5px",justifyContent:"flex-end",marginBottom: "5px"}}>{item.content}<span>{item.type==="comment"?<i class="ri-chat-1-line"></i>:item.type==="like"?<i class="ri-thumb-up-line"></i>:""}</span></p>
                      <span style={ {fontSize: "16px",
        fontWeight: "bold",    marginBottom: "5px"      }}>{item.time_ago}منذ</span>
                    </div>
                    
                    </Link>
                    </div>
                  ))
                }
              </div>
          
        
      }

  
</header>
</>
  )
}

export default Header