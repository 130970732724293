import React , {useEffect , useState ,useRef } from 'react'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import "../styles/Towatch.css"
import User_icon from "../assets/images/user-icon.png"
import { Link , useNavigate } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
// import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
// import user_icon from "../assets/images/user-icon.png"
// import logo from "../assets/images/ic_launcher.png"
// import iconchat from "../assets/images/icon-removebg-preview.png"
import axios from 'axios'
import Headermob from '../components/headermobile/Headermob'






const Towatch = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [autoplayMap, setAutoplayMap] = useState({}); // Map to track autoplay status for each video
  document.addEventListener('DOMContentLoaded', function() {
    var video = document.querySelector('video-item'); // تحديد العنصر video
    
    video.addEventListener('contextmenu', function(e) {
      e.preventDefault(); // منع القائمة المنبثقة
    });
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://creen-program.com/api/videos?page=${page}`, {
          method: 'post',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'accept': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const newData = await response.json();
        setData((prevData) => [...prevData, ...newData.data.videos.data]);
        setHasMore(newData.data.videos.next_page_url !== null);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    if (hasMore) {
      fetchData();
    }
  }, [page, hasMore]);
  useEffect(() => {
    const handleAutoplayVideos = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !entry.target.autoplayStarted) {
          if (entry.target.paused) {
            entry.target.play();
            entry.target.autoplayStarted = true;
          }
        } else if (!entry.isIntersecting && entry.target.autoplayStarted) {
          entry.target.pause();
        }
      });
    };
  
    const observer = new IntersectionObserver(handleAutoplayVideos, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
  
    const videos = document.querySelectorAll('.video-item');
    videos.forEach((video) => {
      observer.observe(video);
    });
  
    return () => {
      observer.disconnect();
    };
  }, [data]);
  
  // استخدم useEffect لتشغيل الفيديو الأول عند تحميل الصفحة
  useEffect(() => {
    const firstVideo = document.querySelector('.video-item');
    if (firstVideo && firstVideo.paused) {
      firstVideo.play();
      firstVideo.autoplayStarted = true;
    }
  
    return () => {
      if (firstVideo) {
        firstVideo.pause();
      }
    };
  }, []);
  
  // استخدم useEffect للتأكد من تشغيل كل فيديو عند التمرير إليه
  useEffect(() => {
    const handleVideoScroll = () => {
      const videos = document.querySelectorAll('.video-item');
      videos.forEach((video) => {
        const rect = video.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView && !video.autoplayStarted && video.paused) {
          video.play();
          video.autoplayStarted = true;
        }
      });
    };
  
    window.addEventListener('scroll', handleVideoScroll);
  
    return () => {
      window.removeEventListener('scroll', handleVideoScroll);
    };
  }, []);
  useEffect(() => {
    const handleVideoScroll = () => {
      const videos = document.querySelectorAll('.video-item');
      videos.forEach((video) => {
        const rect = video.getBoundingClientRect();
         const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView&&video.autoplayStarted && video.play) {
          video.pause();
          video.autoplayStarted = false;
        }
      });
    };
  
    window.addEventListener('click', handleVideoScroll);
  
    return () => {
      window.removeEventListener('click', handleVideoScroll);
    };
  }, []);
  useEffect(() => {
    const handleVideoScroll = () => {
      const videos = document.querySelectorAll('.video-item');
      videos.forEach((video) => {
        const rect = video.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView && !video.autoplayStarted && video.paused) {
          video.play();
          video.autoplayStarted = true;
        }
      });
    };
  
    window.addEventListener('click', handleVideoScroll);
  
    return () => {
      window.removeEventListener('click', handleVideoScroll);
    };
  }, []);
  // استخدم useEffect لتشغيل الفيديو عند العودة إلى الفيديو الذي كان عليه المستخدم بعد التمرير
  useEffect(() => {
    const handleVideoReturn = () => {
      const videos = document.querySelectorAll('.video-item');
      videos.forEach((video) => {
        const rect = video.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView && video.autoplayStarted && video.paused) {
          video.play();
        }
      });
    };
  
    window.addEventListener('scroll', handleVideoReturn);
  
    return () => {
      window.removeEventListener('scroll', handleVideoReturn);
    };
  }, []);
      
  
  
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 
//  const [tab,settab]=useState('main')
//  const menuRef=useRef(null);
//  const menuRef_mobile=useRef(null);
 const token=Cookiesservices.get('jwt')
//  const handle_logout=()=>{
//    Cookiesservices.remove('jwt')
//    localStorage.clear()
//    toast.success("You have successfully logged out");
//  }
//  const menuToggle=()=>{
//    menuRef.current.classList.toggle('active_menu')
//  }
//  const menuToggle_mobile=()=>{
//    menuRef_mobile.current.classList.toggle('active_menu')
//  }
 const navigate = useNavigate();
 const handlelikepost =async (postId) => {

 if(token){
  const respose=await axios.post(`https://creen-program.com/api/videos/like?video_id=${postId}`,{},{
  headers:{
    Authorization:`Bearer ${token}`
  }
})

  setData((prevPosts) =>
  prevPosts.map((post) =>
    post.id === postId
      ? {
        ...post,
        likes_count: post.is_like ? post.likes_count - 1 : post.likes_count + 1,
        is_like: !post.is_like,
      }
      : post
  )
);
localStorage.setItem("post_id", postId)
 }else{
  navigate("/login")
 }
};
const [expand, setexpand] = useState(false)

const Togglercomment = (post_id) => {
if(token){
  setexpand((prevExpand) => ({
    ...prevExpand,
    [post_id]: !prevExpand[post_id],
  }));
}else{
  navigate("/login")
}
};
const [comments , setcomments]=useState([])
const fetchcomments = async (video_id) => {
  try {
    if (video_id) {
      const response = await axios.post(
        'https://creen-program.com/api/comments/videos',
        { video_id }, // تمرير post_id كجزء من البيانات المرسلة
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      setcomments(response.data.data); // تعيين البيانات في حال نجاح الاستجابة
    }
  } catch (error) {
    console.error(error.response); // يمكنك استخدام error.response لطباعة الاستجابة المخصصة
  }
};


const [comment , setcomment]=useState("")
const user_id=localStorage.getItem("user_id")
const fetchcomment = async (post_id_comment) => {

  try {
    if (post_id_comment) {
      const response = await axios.post(
        `https://creen-program.com/api/videos/comment?video_id=${post_id_comment}&comment=${comment}&user_id=${user_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );



      if (response.status === 200) {

        toast.success("created comment successfully")
         fetchcomments(post_id_comment)
         setcomment("")

      }

    }
  } catch (error) {
    if (error.response) {
      console.error('Server responded with an error:', error.response.data);
      console.error('Status code:', error.response.status);
    } else if (error.request) {
      console.error('No response received from the server');
    } else {
      console.error('Error setting up the request:', error.message);
    }
  }


}
const [under, setunder] = useState(false);

const openmodelunder = (post_id) => {
if(token){
  setunder((prevunder) => ({
    ...prevunder,
    [post_id]: !prevunder[post_id],
  }));
  document.body.style.overflow="hidden"
}else{
  navigate("/login")
}
}
const [retweetState, setRetweetState] = useState({});

  const handleretweet = (postId) => {
  if(token){
    setData((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
              ...post,
              retweets_count: retweetState[postId] ? post.retweets_count - 1 : post.retweets_count + 1,
            }
          : post
      )
    );
  
    setRetweetState((prevRetweetState) => ({
      ...prevRetweetState,
      [postId]: !prevRetweetState[postId],
    }));

  } else{
    navigate("/login")
  }  
  };
  const [files , setFiles]=useState(null);
  const handleFileChange = (e) => {
    setFiles(e.target.files[0]);
  };
 const [show,setshow]=useState(false)
 const addvideo=()=>{
  setshow(!show)
 }
 const handleUpload = async () => {
  try {
    const apiUrl = "https://creen-program.com/api/videos/create";
    

    const formData = new FormData();

   formData.append('video',files)
    


    const response = await axios.post(apiUrl,formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    });

     if (response.status===200) {
       toast.success("File uploaded successfully");
       setshow(!show)
       window.location.reload();
     }
  } catch (error) {
    console.error('Error creating post:', error);
    if (error.response && error.response.status === 400) {
      console.error('Server response data:', error.response.data);
    }
    toast.error('An error occurred while creating the post');
  }

    }
const[profilehome,setprofilehome]=useState([])
  useEffect(()=>{
    const fetchdatauser=async()=>{
        const respose=await axios.get(`https://creen-program.com/api/users/${user_id}`,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
        setprofilehome(respose.data.data)
    }
    fetchdatauser()
  },[user_id, token]
  )

  
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  // useEffect(() => {
  //   const handleVideoScroll = () => {
  //     const videos = document.querySelectorAll('.video-item');
  //     videos.forEach((video) => {
  //       const rect = video.getBoundingClientRect();
  //       const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
  //       if ( video.pause) {
  //         video.play();
  //         video.autoplayStarted = true;
  //       }
  //     });
  //   };
  
  //   window.addEventListener('click', handleVideoScroll);
  
  //   return () => {
  //     window.removeEventListener('click', handleVideoScroll);
  //   };
  // }, []);
  

  const closeshare=(post_id)=>{
    setunder((prevunder) => ({
      ...prevunder,
      [post_id]: !prevunder[post_id],
    }));
    document.body.style.overflow="auto"


  }
  const overref = useRef(null);
  // const openmodel = () => {
  //   setover(true)
  //   document.body.style.overflow = "hidden"
  // }
  const close = (e) => {
    if (overref.current && !overref.current.contains(e.target)) {
      setunder(false)
      document.body.style.overflow = "auto"


    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", close)
    return () => {
      document.removeEventListener("mousedown", close)
    }
  }, [])
  return (
    <>
    <>
   <Headermob/>
</>
    <div className="towatch" style={{display:"flex",justifyContent:"space-between"}}>
        <ChatHome/>
        <section className='section-video' style={{width:"61%",display:"flex",justifyContent:"center",position:"relative",gap:"10px"}}>

          <span className='add-video-video' style={{position: "sticky",
    top: "506px",
    width: "40px",
    height: "40px",
    textAlign: "center",
    marginLeft: "11px"}}><i class="ri-add-line" style={{    color: "#fff",
    fontSize: "1.7rem",
    background: "#062b34",
    padding: "13px",
    borderRadius: "50%"}}></i></span>
                           <div className="all_posts_video" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",gap:"90px"}}>
                           {
                            data.map((item , index)=>(
                              <div className="posts posts_video" style={{display: "flex",background:`${under[item.id]?"#ddd":""}`,zIndex:`${under[item.id]?"99999999999999999999999999999999999999999":""}`,
                                flexDirection: "row-reverse",
                                gap:"20px",position:"relative"}}>
                                   <div className="post post_video" key={index} style={{    width: "66%",
                                background: "#000",
                                padding: "10px",
                                borderRadius: "8px"}}>
                               
                            
                            <div  className='content content_video' style={{    lineBreak: "anywhere",position:"relative"}}>
                             {
             <>
                                     
                                     <>
                                     <div key={index} >
          {/* Your video component here */}
          <video
            key={item.id}
            controls
            className="video-item" 
            id={`video-${item.id}`}
            autoPlay={autoplayMap[`video-${item.id}`]}
            onClick={(e) => {
              const video = e.target;
              if (!video.paused) {
                video.pause();
              }
            }}
            style={{ width: '100%', height: '630px', background: '#000', marginBottom: '20px' }}          >
            <source src={item.url} type="video/mp4" />
          </video>
        </div>
    
</>
{
  show && <div style={{ 
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    position: "fixed",
    top: "41%",
    zIndex: "9999999999999999999999999", 
    width: "90%",
    left: "4%",
    background: "#fff",
    padding: "15px",
    borderRadius: "10px"
  }}>
     <input style={{    
    top: "0",
    right: "0",
    minWidth: "100%",
    minHeight: "100%",
    textAlign: "right",
    outline: "0",
    background: "#fff",
    cursor: "inherit",
    display: "block"}} type="file" name="video" onChange={handleFileChange} accept="video/*" id="upload" hidden=""/>
    <button style={{color:" #c0d2bc",
    backgroundColor: "#0a453f",
    padding: "5px 25px 5px 25px",
    borderRadius: "7px",
    fontSize: "15px"}} onClick={handleUpload}>شارك</button>
  </div>
}

             </>
                             }
                             
                             
                             </div>
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                             {
                              expand[item.id] &&
                              <div style={{    position: "absolute",
                              top: "50%",
                              display: "flex",
                              width: "80%",
                              alignItems: "center",
                              flexDirection: "column",
                              background: "#fff",
                              padding: "20px",
                              gap: "17px",
                              left: "55%",
                              transform: "translate(-50%,-50%)",
                              zIndex: "99999999999"}}>
                                 {
                      <> {
                        comments.length !== 0  &&

                        comments.map((item, index) => (

                          <div className="comment" key={index} style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "baseline",
                            marginBottom: "15px",
                        
                            width:"100%"
                          }}>
                            <div className="left" style={{ width: "100%" }}>
                              <div className="top" style={{ display: "flex", justifyContent: "space-between " }}>
                                <div className="action"><i class="ri-more-2-line"></i></div>
                                <div className="user_name" >{item.user.name}</div>
                              </div>
                              <div className="info" style={{
                                background: "rgb(221 221 221 / 30%)",
                                padding: "15px",
                                textAlign: "end",
                                marginTop: "5px", marginLeft: "15px"
                              }}>

                                <div className="content_comment" style={{ lineBreak: "anywhere" }}>{item.comment}</div>
                              </div>
                            </div>
                            <div className="righ"><img style={{ width: "30px", height: "30px", borderRadius: "50px" }} src={item.user.profile !== null ? item.user.profile : User_icon} alt=''></img></div>
                          </div>

                        ))


                      }

                      </>
                    }
                                                    <div style={{display:"flex",flexDirection:"row-reverse",width:"100%",gap:"5px",alignItems:"center"}}>
                                                    <div className="add_comment" style={{gap:"10px",display: "flex",width: "100%"}}>
                                                      <input style={{width:"100%"}} value={comment} onChange={(e)=>e.target.value?setcomment(e.target.value):""} type="text" placeholder="اكتب تعليق وأضغط أنتر .." />
                                                    </div>
                                                    
                                                    <div className="send_comment" style={{alignItems:"center"}}>
                                                      <span onClick={() => fetchcomment(item.id)}><i style={{fontSize:"1.2rem"}} class="ri-send-plane-2-line"></i></span>
                                                      
                                                    </div>
</div>                                                       </div>
                             }
                            
                                                  </div>
                                                  <div className="post-left post-left-video" style={{display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    marginTop: "-85px",gap:"10px"}}>
                                                  <div className="header header-video" style={{display:"flex",justifyContent:"space-between",flexDirection:"column",alignItems:"flex-start",gap:"15px"}}>
<div className="left">
  <span className='iconmobile' style={{background: "#ddd",
    padding: "10px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"}}><i class="ri-arrow-down-s-line" style={{fontSize:"1.7rem",    color: "rgb(125, 125, 125)" }}></i></span>
</div>
<div className="right" style={{display: "flex",
alignItems: "flex-start",
gap: "17px",flexDirection:"column-reverse",marginLeft:"5px"}}>
  <div className="left" style={{textAlign:"end",marginRight:"5px",height:"28px"}}>
    <span><b style={{    fontSize: "15px",}}>{item.user.name.slice(0,11)}</b></span>
    <div className="time" style={{    display: "none",
justifyContent: "end",
gap: "16px",
flexDirection: "row-reverse"}}>
      <span style={{direction:"rtl"}}>22ساعة</span>
      <span className='privacy'>
      <i class="ri-global-line"></i>
      <span className='arrow'></span>
      </span>
    </div>
  </div>
  <div className="right" style={{height:"28px"}}>
    <img src={item.user.profile} alt="" style={{    width: "30px",
height: "30px",
borderRadius: "50%"}} />
  </div>
</div>
</div>
<div className="buttons_action" style={{    display: "flex",
    justifyContent: "space-around",
    marginTop: "59px",
    flexDirection: "column-reverse",
    textAlign: "center",
    alignItems:"flex-start",
    gap: "10px"}}>
<Link to={`https://creen-program.com/chats/share/${item.id}`} className='internal_share iconmobile' style={{textDecoration:"none",transform: "scaleX(-1)",background: "#ddd",
    padding: "10px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"}}>
  <i class="ri-share-forward-line" style={{transform: "scaleX(-1)",fontSize:"1.7rem", color: "rgb(125, 125, 125)"
}}></i></Link>
<span className='retweet iconmobile' onClick={() => {  handleretweet(item.id); }} style={{background: "#ddd",
    padding: "10px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column"}}>   {
      retweetState[item.id] ? <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "green" }}></i> : <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer",color:"#7d7d7d" }}></i>
   }
   </span>

  <span className='external_share iconmobile' onClick={() => { openmodelunder(item.id) }} style={{background: "#ddd",
    padding: "10px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"}}><i  class="ri-share-line" style={{fontSize:"1.7rem",color: "rgb(125, 125, 125)"}}></i></span>
<span>
<span  onClick={() => { Togglercomment(item.id);fetchcomments(item.id) }}  className='comment iconmobile'style={{background: "#ddd",
    padding: "10px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    }}>
  <i   class="ri-message-3-line" style={{fontSize:"1.7rem",color: "rgb(125, 125, 125)"}}></i>
  </span>
  
  <span style={{fontSize:"14px",color:"green"}}>{item.comments_count}</span>
</span>
<span>
<span
                        className='heart heart-towatch'
                        onClick={() => handlelikepost(item.id)}
                        style={{background: "#ddd",
    padding: "10px",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column"}}                   >
                        {item.is_like ? (
                          <>      <i className="ri-heart-fill" style={{ color: "red", fontSize: "1.7rem", cursor: "pointer" }}></i>

                          </>
                        ) : (
                          <>      <i className="ri-heart-line" style={{ fontSize: "1.7rem", cursor: "pointer", color: "#7d7d7d" }}></i>

                          </>
                        )}

                      </span>
                      <span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.likes_count}</span>

</span>
 

</div>
{
                      under[item.id] &&
                      <div  ref={overref} className="modal-share" style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        background: "#fff",
                        padding: "10px",
                        borderRadius: "10px", position: "absolute",
                        top: "50%",
                        left: "50%", zIndex: "9999999",transform: "translate(-50%, -50%)"
                      }}>
               <button style={{
                            padding: "10px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#000",
                            backgroundColor: "transparent",border:"none",fontSize:"1.4rem"
                          }} onClick={()=>{closeshare(item.id)}}><i id='i-x' class="ri-close-line" style={{color:"#000"}}></i></button>
                        <Link className='link' to={"https://wa.me/?text=https://kareem-r.github.io/creen-react/"} style={{ textDecoration: "none", color: "green" }}><i style={{ fontSize: "2.4rem !important" ,color: "green !important"}} className='whatsapp' class="ri-whatsapp-fill"></i></Link>
                        <Link className='link' style={{ textDecoration: "none", color: "#1DA1F2" }} to={"https://twitter.com/intent/tweet?text=Default+share+text&amp;url=https://kareem-r.github.io/creen-react/"}><i className='twitter' style={{ fontSize: "2.4rem !important",color: "#1DA1F2 !important"  }} class="ri-twitter-fill"></i></Link>
                        <Link className='linkface' style={{ textDecoration: "none", color: "#3b5998" }} to={"https://www.facebook.com/sharer/sharer.php?u=https://kareem-r.github.io/creen-react/"}><i  className='facebook' style={{ fontSize: "2.4rem !important",color: "#3b5998 !important" }} class="ri-facebook-box-fill"></i></Link>
                      </div>
                    }
                    
                                                  </div>

                              </div>
                           

                            
                            ))
                          }
                           </div>
          
 
        </section>
        <LinksHome/>
       
            </div>
          
            <div class="footer-mobile towatch-mobile" style={{    background: "#fff",
    border: "none",
    height: "52px",
    position: "fixed",
    zIndex: "999999999",
    bottom: "0px",display:"none",    width: "100%"}}>
        <div class="container" style={{paddingTop:"5px"}}>
            <ul style={{paddingLeft:"0 ",    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"}}>
                <li>
                <Link to={`${token ?`/user/${user_id}`:`/login`}`}>
            <img src={profilehome && token?profilehome.profile:User_icon} alt="" style={{ width: "40px", height: "40px", cursor: "pointer",borderRadius:"50%" }} />
</Link>                
                </li>
              
<Link style={{textDecoration:"none"}} to={"/products"}>
              <li>
                        <span><i style={{fontSize:"1.9rem",cursor:"pointer",color:"#bcbcbc"}} class="ri-shopping-bag-line"></i></span>
                </li></Link>
                <Link to={"/live"}>
          <li style={{    background: "#ddd",
    padding: "5px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center"}}>
              <img src="https://creen-program.com/new/images/m1.png" style={{ width: "1.9rem" }} alt="" />
            </li> 
          </Link>
                
                <li style={{display:"flex",position:"relative"}}>
               <Link  onClick={addvideo}  style={{textDecoration:"none",color:"#fff"}} > 
               <i  style={{    fontSize: "1.7rem",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "10px",
    width: "40px",
    height: "40px",
    textAlign: "center",
    padding: "2px 8px 2px 8px",
    background: "#7d7d7d",}} class="ri-add-line"></i> 
                    
    </Link>
   

                </li>
                <li>
                    
<Link to={"/home"}  style={{textDecoration:"none",color:"#7d7d7d"}} >                
<i  style={{fontSize:"1.9rem",cursor:"pointer",color:"#7d7d7d"}} class="ri-home-4-line"></i>
</Link>        
                </li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Towatch
