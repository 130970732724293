import React, { useEffect, useState } from 'react'
import background from "../assets/images/background.jpg"
import usericon from "../assets/images/user-icon.png"
import ReactStars from 'react-stars'
import right from "../assets/images/right-removebg-preview.png"
import parcode from "../assets/images/WhatsApp Image 2024-01-28 at 21.39.49_e9437ea8.jpg"
import "../styles/Homeuser.css"
import play from "../assets/images/play-button_icon-icons.com_53922.png"
import documents from "../assets/images/Theme256_25022.png"
import services from "../assets/images/clinic_heh_hand_hospital_cross_services_medical_icon_259713.png"
import ads from "../assets/images/istockphoto-1297171607-1024x1024-removebg-preview.png"
import products from "../assets/images/shopping-bag-icon-png-0.jpg"
import basket from "../assets/images/shopping-cart-line.png"
import money from "../assets/images/grow_portfolio_icon_147031.png"
import chats from "../assets/images/R.png"
import exist from "../assets/images/share-2-line.png"
import deleteaccount from "../assets/images/delete-bin-7-fill.png"
import privacy from "../assets/images/privacy.jpg"
import { Link, useParams } from 'react-router-dom'
import Headermob from '../components/headermobile/Headermob'
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import Footer from '../components/footer/Footer'
const Trainerprofile = () => {
  const {id}=useParams()
  const token=Cookiesservices.get('jwt')
  const [data, setData] = useState([]);
  const [user,setuser]=useState([])

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://creen-program.com/api/services/trainer/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setData(response.data.data.trainer);
        setuser(response.data.data.trainer.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
        // يمكنك هنا التعامل مع الخطأ بشكل مناسب، مثلاً عرض رسالة خطأ للمستخدم
      }
    };
  
    fetchUserData();
  }, [id, token]);
  
useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  
    return (
   <>
   <Headermob/>
   <div className="home-user" style={{    width: "100%",
        background: "#fff",
        height: "100%"}}>

    
<div className="background" style={{    display: "flex",
    flexDirection: "column",
    position: "relative"}}>
            {<img src={user.cover?user.cover:background} alt="" style={{height:"221px"}} />}
            <div className="user-profile" style={{display: "flex",justifyContent: "space-around"}}>
            <div style={{position: "absolute",
    left: "11px",
    bottom: "-96px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "14px"}}>
        <span style={{    border: "3px solid black",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    textAlign: "center"}}><i style={{fontSize:"1.7rem"}} class="ri-run-line"></i></span>
        <Link to={"https://creen-program.com/services/allusers"} style={{    textDecoration:"none",color: "#7d7d7d",
    border: "2px solid #848484c7",
    padding: "3px",
    fontSize: "13px",borderRadius:"5px"}}>المشتركين</Link>
    </div>
            <div className="info-user-icon" style={{    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "-120px",
    left: "50%",
    transform: "translateX(-50%)"}}>
            <img src={user.profile?user.profile:usericon} alt="" style={{width:"100px",height:"100px",borderRadius:"50%"}} />
            <span>
                {user.name}
            </span>
            <span>{user.email}</span>
            <ReactStars
  count={5} 
    value={user.num_of_rating}
  size={24}
  color={'#ffd700'} />
  <span>{data.joptitle}</span>
            </div>
<div className="mode" style={{position: "absolute",
    right: "16px",
    bottom: "-50px",
}}>            <span><i style={{fontSize:"1.7rem"}} class="ri-sun-line"></i></span>
</div>
            </div>
        </div>
        <div className="follwers-followingright" style={{display: "flex",
    marginTop: "151px",
    alignItems: "center",
    justifyContent: "space-around"}}>
            <div className="img"><img src={right} alt="" style={{width:"30px",height:"30px"}} /></div>
            <div style={{    display: "flex",
    gap: "5px"}}>
                <span style={{display: "flex",
    flexDirection: "column",
    alignItems: "center",fontSize:"14px"}}><span>المتابعين</span><span>0</span></span><hr  />
                <span style={{display: "flex",
    flexDirection: "column",
    alignItems: "center",fontSize:"14px"}}><span>يتابع</span><span>0</span></span><hr  /> 
                <span style={{display: "flex",
    flexDirection: "column",
    alignItems: "center",fontSize:"14px"}}><span>الأعجابات</span><span>0</span></span>
            </div>
            <div className="parcode"><img src={parcode} alt="" style={{width:"30px",height:"30px"}} /></div>
        </div>
        <div className="action-user" style={{padding: "10px",
    display: "grid",
    gridTemplateColumns:"repeat(3,1fr)",
   columnGap:"10px" ,   
    rowGap: "14px"}}>
        
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",    border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={play} alt="" />
            <span style={{    fontSize: "14px"}}>فيديو</span>
        </div>
        <Link to={"/live"} className="link-user" style={{   color:"#000",textDecoration:"none", display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <span style={{    background: "red",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    textAlign: "center",
    color: "#fff"}}>live</span>
            <span style={{    fontSize: "14px"}}>بث مباشر</span>
        </Link>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={documents} alt="" />
            <span style={{    fontSize: "14px"}}>المواضيع</span>
        </div>
        <Link className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px",textDecoration:"none",color:"#000"}} to={"/services"}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={services} alt="" />
            <span style={{    fontSize: "14px"}}>الخدمات</span>
        </Link>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={ads} alt="" />
            <span style={{    fontSize: "14px"}}>إعلاناتي</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px"}} src={products} alt="" />
            <span style={{    fontSize: "14px"}}>منتجاتي</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={basket} alt="" />
            <span style={{    fontSize: "14px"}}>السلة</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={money} alt="" />
            <span style={{    fontSize: "14px"}}>المحفظة</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={chats} alt="" />
            <span style={{    fontSize: "14px"}}>المحادثات</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%",transform: "rotate(89deg)"}} src={exist} alt="" />
            <span style={{    fontSize: "14px"}}>تسجيل الخروج</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={deleteaccount} alt="" />
            <span style={{    fontSize: "14px"}}>حذف الحساب</span>
        </div>
        <div className="link-user" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "center",border: "1px solid #ddd",
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px"}}>
            <img style={{    width: "30px",
    height: "30px",
    borderRadius: "50%"}} src={privacy} alt="" />
            <span style={{    fontSize: "14px"}}>الخصوصية</span>
        </div>

        </div>
       

    </div>
    <Footer/>
   </>
  )
}

export default Trainerprofile