import React, { useEffect, useState} from 'react'
//import background from "../assets/images/background.jpg"
import usericon from "../assets/images/user-icon.png"
import ReactStars from 'react-stars'
// import Header from '../components/Header/Header'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import "../styles/service.css"
import { useNavigate } from 'react-router-dom'
import background from "../assets/images/background.jpg"
import Headermob from "../components/headermobile/Headermob"
import Footer from '../components/footer/Footer'

const Service = () => {
        const { id } = useParams();
        const token = Cookiesservices.get('jwt');
        const [data, setdata] = useState([]);
      
        useEffect(() => {
          const fetchTrainers = async () => {
            try {
              const response = await axios.get(`https://creen-program.com/api/services/show/${id}`, {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              setdata(response.data.data.trainers);
            } catch (error) {
              // console.error('Error fetching trainers:', error);
              // Handle error gracefully
            }
          };
      
          if (token || !token) {
            fetchTrainers();
          }
        }, [id, token]);
      
        // Clear local storage item when component unmounts
        useEffect(() => {
          localStorage.setItem("id_category", id);
      
          return () => {
            localStorage.removeItem("id_category");
          };
        }, [id]);

const navigate=useNavigate();

// const user_id=localStorage.getItem("user_id")

  
  useEffect(() => {
        window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
      }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  return (
   <>
<Headermob/>
   < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%",height: `${data.length===0?"100vh":"100%"}`, display: "flex", justifyContent: "space-between", gap: "19px" }}>

        <div className="cards cards_service" style={{display: "grid",position:"relative",
        gridTemplateColumns: "repeat(2,45%)",
        rowGap: "8px",
        columnGap: "5px",
        height: "100%",minHeight:"100vh",
        width: "100%",justifyItems:"center",justifyContent:"center",marginTop:"15px",direction:"rtl"}}>
        {
data.length===0?<p className='p_service' style={{  
        color: "#000",
        height: "max-content",
        fontSize: "20px",
        fontWeight: "bold",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",display:"flex",flexDirection:"column",alignItems:"center"}}>مرحبا عزيزي العميل   <p style={{color: "#000",
        height: "max-content",
        fontSize: "20px",
        fontWeight: "bold"}}>أختر مدربك المناسب</p></p>:
data.map((item , index)=>(
        <div className="card card_service"  key={index} style={{    width: "155px",
borderRadius: "10px",
height: "215px",
position: "relative",
alignItems: "center"}}>
        <img src={item.user.cover?item.user.cover:background} className='background_service' alt="" style={{height:"69px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}} />
        <div className="info-user info-user-service" style={{display: "flex",
flexDirection: "column",
alignItems: "center",
position: "absolute",
bottom: "3px",width:"100%"}}>
        <img src={item.user.profile?item.user.profile:usericon} className='img_profile' alt="" style={{width:"53px",height:"50px",borderRadius:"50%"}} />
        <span className='name_service'>{item.name}</span>
        <span className='title_service'>{item.joptitle}</span>
        <ReactStars
count={5} 
value={item.user.num_of_rating}
size={24}
color={'#ffd700'}
className='star' />
        <div   style={{textDecoration:"none",width:"100%",textAlign:"center"}}><button className='button_service' style={{border: "1px solid green",
background: "transparent",
width: "85%",
borderRadius: "16px",marginBottom:"5px"}} onClick={()=>item.category_id===5?navigate(`/Appointment/${item.id}`):navigate(`/services/trainer/show/${item.id}`)}  >شاهد</button ></div>
        </div>
    </div>
))
        }
       

    </div>
          
        </section>
        <LinksHome />

      </section>
   
      <Footer/>
   </>
  )
}

export default Service