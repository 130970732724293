import React, { useEffect, useState, useRef } from 'react'
import Header from '../components/Header/Header'
import { Link, useParams, /*useNavigate*/ } from 'react-router-dom'
import "../styles/Home.css"
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
import User_icon from "../assets/images/user-icon.png"
import { toast } from 'react-toastify'
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import barcode from "../assets/images/WhatsApp Image 2024-01-28 at 21.39.49_e9437ea8.jpg"
import "../styles/darkmood.css"
import gift from "../assets/images/gift.jpg"
import doctors from "../assets/images/doctors-removebg-preview.png"
import trainers from "../assets/images/trainers.jpg"
import bono from "../assets/images/محترفين.jpg"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom'
import foods from "../assets/images/foods.jpg"
import vita from "../assets/images/vitaminate.jpg"
import sportts from "../assets/images/sports.jpg"
import sportcloth from "../assets/images/sportcloth.jpg"

const Postview = () => {
    const {id}=useParams()
  const [sun, setsun] = useState("sun")
  const [data, setData] = useState([])
  const [page, setPage] = useState(0);
  const token = Cookiesservices.get("jwt")
  const user_id = (localStorage.getItem('user_id'));
  const user_name = (localStorage.getItem('user_name'));
  const email = (localStorage.getItem('email'));
  const [category_id, setcategoryid] = useState("")
  const [categories, setcatigories] = useState([])
  const [comment, setcomment] = useState("")
  const [followerss, setfellowerss] = useState("")
  const [followingg, setfellowingg] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  useEffect(() => {
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const response = await axios.get("https://creen-program.com/api/categories", {
  //         headers: {
  //           'Content-type': 'application/json; charset=UTF-8',
  //           'accept': 'application/json',
  //           Authorization: `Bearer ${token}`
  //         }
  //       });
  
  //       // تحديث حالة الفئات فقط إذا كانت الاستجابة ناجحة
  //       if (response.status === 200) {
  //         setcatigories(response.data.data);
  //       } else {
  //         console.error("Failed to fetch categories. Status:", response.status);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };
  
  //   fetchCategories();
  // }, [token]);
  const fetchcomment = async (post_id_comment) => {
    try {
      if (post_id_comment) {
        const response = await axios.post(
          `https://creen-program.com/api/comments/create?blog_id=${post_id_comment}&comment=${comment}&user_id=${user_id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
if (response.status === 200) {
toast.success("created comment successfully")
          setcomment((prevComments) => [...prevComments, response.data.data]);
          setcomment("")
          fetchcomments(post_id_comment)}

      }
    } catch (error) {
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        console.error('No response received from the server');
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }


  }
  // useEffect(() => {
  //   const followers = async () => {
  //     try {
  //       const response = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         })
  //       if (response.status === 200) {
  //         // setfellowerss((prev)=>[...prev , ...response.data.data])
  //         //  console.log(followerss)
  //         setfellowerss(response.data.data)

  //       }
  //     } catch (error) { }
  //   }
  //   const following = async () => {
  //     try {
  //       const response = await axios.post(`https://creen-program.com/api/followers/followingcount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         })
  //       if (response.status === 200) {
  //         // setfellowerss((prev)=>[...prev , ...response.data.data])
  //         //  console.log(followerss)
  //         setfellowingg(response.data.data)

  //       }
  //     } catch (error) { }
  //   }
  //   followers()
  //   following()
  // }, [token, user_id])

  const navigate = useNavigate()
  const handlelikepost = (postId) => {
    if (token) {
      setData((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
              ...post,
              likes_count: post.is_like ? post.likes_count - 1 : post.likes_count + 1,
              is_like: !post.is_like,
            }
            : post
        )
      );
      localStorage.setItem("post_id", postId)
    } else {
      navigate("/login")
    }

  };
  const [files, setFiles] = useState([]);
  const [title, settitle] = useState("");
  const [documentt, setdocument] = useState("");
  const [youtube, setyoutube] = useState("");
  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handletitle = (e) => {
    settitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setdocument(e.target.value);
  };

  const handleyoutube = (e) => {
    setyoutube(e.target.value);
  };
  const handleUpload = async () => {
    try {
      const apiUrl = "https://creen-program.com/api/blogs/create";


      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });
      formData.append("category_id", category_id);
      formData.append("title", title);
      formData.append("content", documentt);
      formData.append("youtube", youtube);
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        toast.success("File uploaded successfully");
        setshow(!show)
        window.location.reload();
      }
    } catch (error) {
      console.error('Error creating post:', error);
      if (error.response && error.response.status === 400) {
        console.error('Server response data:', error.response.data);
      }
      toast.error('An error occurred while creating the post');
    }

  }




  const follow = async (postId) => {
    setData((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            is_follow: post.is_follow ? false : true,
            is_following: !post.is_follow,
          }
          : post
      )
    );

  }



  const handelchangecomment = (e) => {
    if (e.target.value) {
      setcomment(e.target.value)
    } else {
      setcomment("")
    }
  }
  const handlechangecategoryid = (e) => {
    if (e.target.value) {
      setcategoryid(e.target.value)

    } else {
      
    }
  }
  const [stories, setstories] = useState([])
 
  // useEffect(() => {

  //  if(token){
  //   const handlestory = async () => {
  //     try {
  //       const response = await axios.post(`https://creen-program.com/api/stories`, {}, {
  //         headers: {
  //           'Content-type': 'application/json; charset=UTF-8',
  //           'accept': 'application/json',
  //           Authorization: `Bearer ${token}`
  //         },

  //       })


  //       setstories(response.data.data.stories.data)






  //     } catch (error) { }


  //   }
  //   handlestory()

  //  }
  // }, [token])
  const [loading, setLoading] = useState(true); // حالة للتحقق من إرسال طلب جديد أم لا
  const [datapost,setdatapost]=useState([])
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`https://creen-program.com/api/blogs/view/${id}`, {
//           method: 'POST',
//           headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//             'accept': 'application/json'
//           },
//         });
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const newData = await response.json();
//         setdatapost([newData.data])
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
// fetchData()
    
//   }, [id]); // أعد تشغيل الأثر إذا تغيرت الصفحة أو الحالة loading فقط

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`https://creen-program.com/api/blogs?page=${page}`, {
//           method: 'POST',
//           headers: {
//             'Content-type': 'application/json; charset=UTF-8',
//             'accept': 'application/json'
//           },
//         });
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const newData = await response.json();
//         setData(prevData => [...prevData, ...newData.data.data]);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
// fetchData()
    
//   }, [page]); // أعد تشغيل الأثر إذا تغيرت الصفحة أو الحالة loading فقط

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
    ) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // إعداد وإزالة مُستمع للتمرير فقط عند تحميل المكون

  
  const model = useRef(null);
  const menuToggle = () => {
    model.current.classList.toggle('model')
  }
  const [retweetState, setRetweetState] = useState({});

  const handleretweet = (postId) => {
    if (token) {
      setData((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? {
              ...post,
              retweets_count: retweetState[postId] ? post.retweets_count - 1 : post.retweets_count + 1,
            }
            : post
        )
      );
      setRetweetState((prevRetweetState) => ({
        ...prevRetweetState,
        [postId]: !prevRetweetState[postId],
      }));
    } else {
      navigate("/login")
    }


  };

  const [story, setstory] = useState([])
 
  const handlemodalstory = (story_id) => {
    const selectedStory = [stories.filter((story) => story.id === story_id)[0]];
    setstory(selectedStory);
  };

  // Add a state to keep track of the current story index
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  // Function to handle reaching the end of the current story
  const handleStoryEnd = () => {
    // Check if there's a next story
    if (currentStoryIndex < stories.length - 1) {
      // Increment the current story index
      setCurrentStoryIndex(currentStoryIndex + 1);
      // Get the ID of the next story
      const nextStoryId = stories[currentStoryIndex + 1].id;
      // Navigate to the next story
      handlemodalstory(nextStoryId);
    }
  };
  const [over, setover] = useState(false);
  const overref = useRef(null);
  const openmodel = () => {
    setover(true)
    document.body.style.overflow = "hidden"
  }
  const close = (e) => {
    if (overref.current && !overref.current.contains(e.target)) {
      setover(false)
      setunder(false)
      document.body.style.overflow = "auto"


    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", close)
    return () => {
      document.removeEventListener("mousedown", close)
    }
  }, [])
  const [under, setunder] = useState(false);

  const openmodelunder = (post_id) => {
    if (token) {
      setunder((prevunder) => ({
        ...prevunder,
        [post_id]: !prevunder[post_id],
      }));
    } else {
      navigate("/login")
    }

  }



  const [expand, setexpand] = useState(false)

  const Togglercomment = (post_id) => {
    if (token) {
      setexpand((prevExpand) => ({
        ...prevExpand,
        [post_id]: !prevExpand[post_id],
      }));
    } else {
      navigate("/login")
    }

  };
  const [show, setshow] = useState(false)
  const [comments, setcomments] = useState([])

  const fetchcomments = async (post_id) => {
    try {
      if (post_id) {
        const response = await axios.post(`https://creen-program.com/api/comments/posts?post_id=${post_id}`, {}, {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'accept': 'application/json',
            Authorization: `Bearer ${token}`
          },

        })


        setcomments(response.data.data);

      }





    } catch (error) { }

  }

  const [showstory, setshowstory] = useState(false)
  const [text, settext] = useState("")
  const [image, setimage] = useState()
  const handleaddstory = async () => {
    try {
      const apiUrl = "https://creen-program.com/api/stories/store";


      const formData = new FormData();

      formData.append('video', image)
      formData.append("image", image)
      formData.append("text", text)



      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      })

      if (response.status === 200) {
        toast.success("store uploaded successfully");
        setshowstory(false)

      }
    } catch (error) {
      console.error('Error creating post:', error);
      if (error.response && error.response.status === 400) {
        console.error('Server response data:', error.response.data);
      }
      toast.error('An error occurred while creating the post');
    }
  }
  const [more, setmore] = useState(false)
  const moree = (post_id) => {
   

    setmore(prevMore => ({
      ...prevMore,
      [post_id]: !prevMore[post_id],
    }));
  };



  const [profilehome, setProfileHome] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(true);
  
  const fetchDataUser = async (user_id, token) => {
    try {
      const response = await axios.get(`https://creen-program.com/api/users/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Too many requests error:', error);
        return null;
      } else {
        throw error;
      }
    }
  };
  
  // useEffect(() => {
  //   const fetchUserProfile = async () => {
  //     try {
  //       if (loadingProfile) {
  //         const userProfile = await fetchDataUser(user_id, token);
  //         if (userProfile !== null) {
  //           setProfileHome(userProfile);
  //         }
  //         setLoadingProfile(false);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user profile:', error);
  //       setLoadingProfile(false);
  //     }
  //   };
  
  //   fetchUserProfile();
  // }, [user_id, token, loadingProfile]);
  
  // // إعادة تعيين حالة loadingProfile عندما يتغير user_id أو token
  // useEffect(() => {
  //   setLoadingProfile(true);
  // }, [user_id, token]); 
  
  const showstoryhome = () => {
   if(token){
    setshowstory(!showstory)
    document.body.style.overflow = "hidden"
   }else{
    navigate("/login")
   }
  }
  const closestory = () => {
    setshowstory(false)
    document.body.style.overflow = 'auto';
  }
  const closeover = () => {
    setover(!over)
    document.body.style.overflow = 'auto';

  }
  const [swiper, setSwiper] = useState(null); // حالة لتخزين Swiper instance
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // حالة لتخزين الشريحة الحالية

  // Callback function to handle slide change
  const handleSlideChange = () => {
    // تحديث حالة الشريحة الحالية عند تغيير الشريحة
    setCurrentSlideIndex(swiper.activeIndex);

    // إيقاف تشغيل الفيديو عند تغيير الشريحة
    const videoElement = document.getElementById(`video_${swiper.activeIndex}`);
    if (videoElement) {
      videoElement.pause();
    }
  }
  const [modaledit, setmodaledit] = useState(false);
  const [modal, setmodal] = useState(false);
  const modedit = (post_id, User_id) => {
    // eslint-disable-next-line eqeqeq
    if (User_id == user_id) {
      setmodaledit((prevunder) => ({
        ...prevunder,
        [post_id]: !prevunder[post_id],
      }));
      // eslint-disable-next-line eqeqeq
    } else if (User_id != user_id) {
      setmodal((prevunder) => ({
        ...prevunder,
        [post_id]: !prevunder[post_id],
      }));
    } else {
      setmodal(false)
      setmodaledit(false)
    }


  }
  const deletepost = async (blog_id) => {
    try {
      const response = await axios.post(`https://creen-program.com/api/blogs/delete?blog_id=${blog_id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === true) {
        toast.success("تم حذف البوست");
        setmodal(false);
        setmodaledit(false);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      // يمكنك إضافة إخطار للمستخدم هنا في حالة فشل الحذف
    }
  };
  const [show2, setshow2] = useState(false)
  const updatepost = async (blog_id) => {


    try {
      const apiUrl = "https://creen-program.com/api/blogs/update";


      const formData = new FormData();
      formData.append("blog_id", blog_id)
      files.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });
      formData.append("category_id", category_id);
      // formData.append("images", files[0]);
      formData.append("title", title);
      formData.append("content", documentt);
      formData.append("youtube", youtube);



      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,

        },
        body: formData,
      });

      if (response.status === 200) {
        console.log(response)
        toast.success("File update successfully");
        setshow2(!show2)
        //  window.location.reload();
      }
    } catch (error) {
      console.error('Error creating post:', error);
      if (error.response && error.response.status === 400) {
        console.error('Server response data:', error.response.data);
      }
      toast.error('An error occurred while creating the post');
    }

  }
  useEffect(() => {
    const fetchData = async () => {
        try {
            // جلب الفئات
            const categoriesResponse = await axios.get("https://creen-program.com/api/categories", {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            // تحديث حالة الفئات فقط إذا كانت الاستجابة ناجحة
            if (categoriesResponse.status === 200) {
                setcatigories(categoriesResponse.data.data);
            } else {
                console.error("Failed to fetch categories. Status:", categoriesResponse.status);
            }

            // جلب عدد المتابعين
            const followersResponse = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${user_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (followersResponse.status === 200) {
                setfellowerss(followersResponse.data.data);
            }

            // جلب عدد المتابعات
            const followingResponse = await axios.post(`https://creen-program.com/api/followers/followingcount?user_id=${user_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (followingResponse.status === 200) {
                setfellowingg(followingResponse.data.data);
            }

            // جلب القصص
            if (token) {
                const storiesResponse = await axios.post(`https://creen-program.com/api/stories`, {}, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'accept': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });
                setstories(storiesResponse.data.data.stories.data);
            }

            // جلب بيانات المدونة
            const blogResponse = await fetch(`https://creen-program.com/api/blogs/view/${id}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json'
                },
            });
            if (!blogResponse.ok) {
                throw new Error(`HTTP error! Status: ${blogResponse.status}`);
            }
            const blogData = await blogResponse.json();
            setdatapost([blogData.data]);

            // جلب بيانات المدونات بحسب الصفحة
            const blogsResponse = await fetch(`https://creen-program.com/api/blogs?page=${page}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json'
                },
            });
            if (!blogsResponse.ok) {
                throw new Error(`HTTP error! Status: ${blogsResponse.status}`);
            }
            const blogsData = await blogsResponse.json();
            setData(prevData => [...prevData, ...blogsData.data.data]);

            // جلب ملف تعريف المستخدم
            if (loadingProfile) {
                const userProfile = await fetchDataUser(user_id, token);
                if (userProfile !== null) {
                    setProfileHome(userProfile);
                }
                setLoadingProfile(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // يمكنك هنا التعامل مع الخطأ بشكل مناسب، مثلاً عرض رسالة خطأ للمستخدم
        }
    };

    // استدعاء دالة جلب البيانات عند تغيير أحد الاعتماديات
    fetchData();

}, [token, user_id, loadingProfile, page, id]);
const closeshare=(post_id)=>{
  setunder((prevunder) => ({
    ...prevunder,
    [post_id]: !prevunder[post_id],
  }));
  document.body.style.overflow="auto"


}
  return (
    <>
      <Header />
      < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>


          <div className="left" style={{ width: "35%", display: "flex", flexDirection: "column", gap: ".5rem" }}>
            <div className="card" style={{ backgroundImage: "#fff", boxShadow: " 0 1px 2px rgba(0, 0, 0, 0.2)", marginTop: "24px" }}>
              <div className="image" style={{ height: '120px', background: " linear-gradient(180deg, #102b1d, #57ed94)" }}>

              </div>
              <div className="log_info">
                <img src={profilehome && token ? profilehome.profile : User_icon} alt="" />
                <Link>{token?user_name:"مستخدم جديد"}</Link>
                <span style={{ color: " #bababa", fontSize: "14px" }}>{token?email:""}</span>
              </div>
              <div className="log_followers">
                <Link><span><b>الاعجابات</b></span>
                  <span style={{ color: "#bababa" }}>0</span></Link>
                <Link><span><b>متابَعون</b></span>
                  <span style={{ color: "#bababa" }}>{token?followerss:0}</span></Link>
                <Link>
                  <span><b>متابِعون</b></span>
                  <span style={{ color: "#bababa" }}>{token?followingg:0}</span></Link>
              </div>
            </div>
            <div className="barcode-darkmode" style={{ background: "#fff", borderRadius: "5px", padding: '10px' }}>
              <div className="header" style={{ textAlign: "end", fontSize: "14.5px", display: "flex", justifyContent: "space-around" }} >

                <img style={{ width: "40px", height: "40px" }} src={barcode} alt="" />
                <div className="dark_mood">
                  {
                    sun === "sun" ?
                      <span onClick={() => setsun('dark')}><i style={{ fontSize: "1.8rem" }} class="ri-sun-line"></i></span> : <span onClick={() => setsun("sun")}><i style={{ fontSize: "1.8rem" }} class="ri-moon-fill"></i></span>
                  }
                </div>


              </div>
            </div>
            <div className="group" style={{ background: "#fff", borderRadius: "5px", padding: '10px' }}>
              <div className="header" style={{ textAlign: "end", fontSize: "14.5px" }} ><span><b>!الهاشتاغات النشطة</b></span></div>
            </div>
            <div className="featured-users" style={{ position: "sticky", top: "76px" }}>
              <div className="header">
                <span style={{ fontSize: "14px" }}>الأعضاء المحترفون</span>
                <span style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <i class="ri-vip-crown-line"></i>
                  <span style={{ fontWeight: "bold", fontSize: "13px", color: "#666" }}>اشتركاتي</span>
                </span>
              </div>
            </div>
            <div className="users_know" style={{ position: "sticky", top: "127px" }}>
              <div className="header">
                <span><i class="ri-restart-line"></i></span>
                <span>أشخاص قد تعرفهم</span>
              </div>
            </div>
            <div className="online" style={{ position: "sticky", top: "176px" }}>
              <span><i class="ri-group-fill"></i></span>
              <span><span>2</span> من المستخدمين متصلين</span>
            </div>
            <div className="email" style={{ position: "sticky", top: "264px" }}>
              <span><i class="ri-mail-open-fill" style={{ color: "orange", fontSize: "40px" }}></i></span>
              <span style={{ color: "#986600" }}>دعوة اصدقائك</span>
              <form>

                <div class="buttons" style={{ display: "flex", marginTop: "8px" }}>
                  <button style={{
                    color: "#c0d2bc",
                    backgroundColor: "#062b34",
                  }}>
                    <i class="ri-send-plane-2-fill"></i>
                  </button>
                  <input type="text" placeholder="البريد الألكتروني" />
                </div>

              </form>
            </div>
            <div className="activities" style={{ position: "sticky", top: "429px" }}>
              <div className="header" style={{ textAlign: "end" }}>
                <span>أحدث المنتجات</span>
              </div>
            </div>
            <div className="activities" style={{ position: "sticky", top: "479px" }}>
              <div className="header" style={{ textAlign: "end" }}>
                <span>أخر الانشطة</span>
              </div>
            </div>
            <div className="activities" style={{ position: "sticky", top: "529px" }}>
              <div className="header" style={{ textAlign: "end" }}>
                <span>أخر الاخبار</span>
              </div>
            </div>
          </div>
          <div className="right" style={{ width: "65%", display: "flex", flexDirection: "column", gap: "10px" }}>


            {
              over && (
                <div style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "8000000000000000",
                  width: "550px",
                  height: "550px", backgroundColor: "rgb(6, 43, 52)", top: "212px", borderRadius: "4px"
                }} className='story_mobile' >
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between", paddingRight: "21px", paddingTop: "5px"
                  }}>
                    <span style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontSize: "1.7rem", color: " #fff", marginLeft: "8px", padding: "1px 2px",
                      width: "max-content",
                      borderRadius: "50%"
                    }} onClick={() => { closeover() }}><i class="ri-close-line"></i></span>
                    <div style={{
                      marginRight: "20p",
                      display: "flex",
                      gap: "8px",
                      alignItems: "center"
                    }}>
                      <span style={{
                        color: "rgb(255, 255, 255)",
                        padding: "0px 2px",
                        borderRadius: "4px",
                        width: "max-content",
                        display: "flex",
                        alignItems: "center"
                      }}>
                        {story.map((st) => st.name)}</span>
                      <img style={{ width: "40px", height: "40px", borderRadius: "50%" }} src={story.map((st) => st.profile)} alt="" />
                    </div>
                  </div>
                  <div className="modal-story" ref={overref} style={{
                    position: "relative",
                    zIndex: "1000",
                    width: "100%",
                    maxWidth: "100%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    height: "100%",
                  }}>
                    <Swiper
                      style={{
                        width: "100%",
                        height: "100%",
                        marginLeft: "0px"
                      }}
                      // install Swiper modules
                      modules={[Navigation, Pagination]}
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation
                      pagination={{ clickable: true }}
                      // scrollbar={{ draggable: true }}
                      // Add event listener to detect when the user reaches the end of the story
                      onReachEnd={handleStoryEnd}     // Call handleSlideChange function when the slide changes
                      onSlideChange={handleSlideChange}
                      // Assign the Swiper instance to the state
                      onSwiper={setSwiper}
                    >
                      {story.length === 0 ? (
                        <></>
                      ) : (
                        story.map((story, index) => (
                          story.story.map((st) => (
                            st.image !== null ? (
                              <SwiperSlide style={{ paddingTop: "15px" }}>
                                <img src={st.image} alt="" style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "4px"
                                }} className='img_mobile_home' />
                              </SwiperSlide>
                            ) : st.video !== null ? (
                              <SwiperSlide style={{ paddingTop: "15px" }}>
                                <video id={`video_${index}`} className='video_mobile_mobile' width={"100%"} height="100%" autoPlay >
                                  <source src={st.video} type="video/mp4" />
                                </video>
                              </SwiperSlide>
                            ) : (
                              <></>
                            )
                          ))
                        ))
                      )}
                    </Swiper>

                  </div></div>
              )
            }
            {showstory &&
              (
                <div className='showstoryadd' style={{
                  position: "fixed",
                  width: "40%",
                  height: "85vh",
                  backgroundColor: "rgb(6, 43, 52)",
                  zIndex: "2000000"
                }}>
                  <div className="he" style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center", padding: "15px 15px 0px 15px"
                  }}>
                    <div className="left" style={{
                      display: "flex",
                      gap: "15px",
                      fontSize: "1.7rem", color: " rgb(192, 210, 188)"
                    }}>
                      <span><i class="ri-palette-fill"></i></span>
                      <span><i class="ri-font-size-2"></i></span>
                      <span><i class="ri-emotion-fill"></i></span></div>
                    <span><i style={{ fontSize: "1.9rem", color: " rgb(192, 210, 188)" }} onClick={() => { closestory() }} class="ri-close-line"></i></span>
                  </div>
                  <div className="content"><textarea onChange={(e) => { settext(e.currentTarget.value) }} placeholder='اكتب الحاله' style={{
                    width: "100%",
                    minHeight: "70vh",
                    height: "100%",
                    display: "flex",
                    padding: "30% 0px 0 0",
                    direction: "rtl",
                    border: "none",
                    background: "transparent", outline: "none", textAlign: "center"
                  }} name="" id="" ></textarea></div>
                  <div className="fo" style={{
                    fontSize: "1.7rem",
                    display: "flex",
                    gap: "15px", color: "rgb(192, 210, 188)", paddingLeft: "15px"
                  }}>
                    <span><i class="ri-mic-fill"></i></span>
                    <span style={{ display: "flex", position: "relative" }}><i class="ri-image-fill"></i>
                      <input onChange={(e) => { setimage(e.currentTarget.files[0]) }} style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        minWidth: "100%",
                        minHeight: "100%",
                        textAlign: "right",
                        opacity: "0",
                        outline: "0",
                        background: "#fff",
                        cursor: "inherit",
                        display: "block"
                      }} type="file" /></span>
                    <span onClick={handleaddstory}><i class="ri-send-plane-2-fill"></i></span>
                  </div>

                </div>
              )
            }

            <div className="story" style={{ justifyContent: "end",background: "#fff",paddingRight: "10px" ,borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px", }}>
              <div className="Cases">
                <ul>
                  {
                    stories.map((story, index) => (
                      <li key={index} style={{    background: "#ddd",
                        height: "91px",
                        borderRadius: "50%",
                        width: "96px",
                        padding: "6px",    }} onClick={() => { handlemodalstory(story.id); openmodel() }}>
                        <img src={stories[index].story[stories[index].story.length - 1].image ? stories[index].story[stories[index].story.length - 1].image : story.profile} alt="" className='case' />

                        <span>{story.name.slice(0, 10)}</span>
                      </li>
                    ))
                  }


                </ul>
              </div>
              <div className="add_story" style={{marginTop:"18px"}} onClick={() => { showstoryhome() }}>
                <span><i class="ri-add-line"></i></span>
                <span>أضافة</span>
              </div>
            </div>
            <div className="story_Proposed" style={{ marginTop: "-12px",background: "#fff",borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px", }}>
              <ul style={{marginBottom:"0",paddingRight:"10px"}}>
                {
                  stories.map((story, index) => (
                    <li style={{    background: "rgb(221 221 221 / 53%)",
                      height: "170px",
                      borderRadius: "4px",
                      width: "132px",
                      padding: "6px"}} key={index} onClick={() => { handlemodalstory(story.id); openmodel() }}>
                      <img src={stories[index].story[stories[index].story.length - 1].image ? stories[index].story[stories[index].story.length - 1].image : story.profile} alt="" />
                      <img src={story.profile ? story.profile : User_icon} alt="" />

                    </li>
                  ))
                }



              </ul>
            </div>
            <div className="" style={{
              /* overflow: scroll; */
              marginTop: "-16px"
            }}>
              <ul style={{
                display: "flex",
                direction: "rtl",
                gap: "5px",
                /* width: 100%; */
                overflow: "scroll"
              }}>
                <Link style={{ textDecoration: "none", color: "#000" }} to={`${token ? "/services/5" : "/login"}`}>
                  <li style={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                  }} >

                    <span style={{
                      fontSize: "22px",
                      fontWeight: "bold"
                    }}>الاطباء</span>
                    <img style={{ width: "190px", height: "229px", borderRadius: "10px" }} src={doctors} alt="" />
                  </li>
                </Link>


                <Link style={{ textDecoration: "none", color: "#000" }} to={`${token ? "/services/3" : "/login"}`}>
                  <li style={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                  }}>
                    <span style={{
                      fontSize: "22px",
                      fontWeight: "bold"
                    }}>المدربين</span>
                    <img src={trainers} alt="" style={{ width: "190px", height: "229px", borderRadius: "10px" }} />
                  </li>
                </Link>
                <li style={{
                  textAlign: "center",
                  border: "1px solid #ddd",
                  borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                }}>
                  <span style={{
                    fontSize: "22px",
                    fontWeight: "bold"
                  }}>التجميل</span>
                  <img src={bono} alt="" style={{ width: "190px", height: "229px", borderRadius: "10px" }} />
                </li>


              </ul>
            </div>

            {
              <div className="add_post" onClick={() => { token?setshow(true):navigate("/login") }}>
                <div className="header" style={{ marginTop: "-5px", paddingRight: "8px" }}>
                  <span><i class="ri-emotion-happy-line" style={{ fontSize: "1.2rem" }}></i></span>
                  <div className="info_user" style={{ gap: "10px" }}>
                    <span style={{ marginTop: "-4px" }}>{ token ? user_name : "مستخدم جديد"}</span>
                    <img src={profilehome && token ? profilehome.profile : User_icon ? User_icon : ""} alt="" />
                  </div>
                </div>
                <div className="textarea">
                  <textarea name="" id="" rows="5" placeholder=" ما الذي يحصل الآن ؟ #هاشتاغ .. @إشارة أضافه موضوع جديد"></textarea>
                </div>
                <div class="footer">
                  <div class="buttons">
                    <div class="img">
                      <span style={{ fontSize: "14px" }}>تحميل الصورة</span>
                      <span><i class="ri-image-fill" style={{ fontSize: "1.2rem", color: "#0dcaf0" }}></i></span>
                      <input type="file" name="" id="" multiple onChange={handleFileChange} />
                    </div>

                  </div>

                </div>
              </div>
            }
            {
              show === true && <div className="add_post_click "   >
                <div className="con"  >
                  <div className="header">
                    <span><i class="ri-emotion-happy-line" style={{ fontSize: "1.2rem" }}></i></span>
                    <div className="info_user">
                      <span>{user_name}</span>
                      <img src={profilehome && token ? profilehome.profile : User_icon ? User_icon : ""} alt="" />
                    </div>

                  </div>
                  <span style={{ display: "flex", alignItems: "center", gap: "2px", marginTop: "10px" }}>
                    <select required name="" id="" value={category_id} style={{ width: "100%" }} onChange={handlechangecategoryid}>
                      <option value="أختر"> أختر الاقسام</option>
                      {
                        categories.map((item) => (

                          <option value={item.id}>{item.name}</option>
                        ))
                      }
                    </select>
                  </span>
                  <input type="text" placeholder='العنوان' value={title} onChange={handletitle} style={{ marginTop: "10px", width: "100%" }} />
                  <input type="text" placeholder='رابط اليوتيوب' value={youtube} onChange={handleyoutube} style={{ marginTop: "10px", width: "100%" }} />
                  <textarea style={{ marginTop: "10px", height: "110px", width: "100%",    backgroundColor: "initial",
    border: "0",
    borderRadius: "12px",
    boxShadow:" 0 0 0 1px #3c425729, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 1px 1px 0 #0000001f, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000",
    color: "#393d4a",
    direction: "rtl",
    fontFamily: "inherit",
    fontSize: "14px",
    fontWeight: "400",    
    lineHeight: "28px",
    padding: "13px",
    textAlign: "right",
    transition: "background-color .24s, box-shadow .24s", }} value={documentt} onChange={handleContentChange} type="text" placeholder='الموضوع' />
                  <div class="footer">
                    <div class="buttons" style={{ textAlign: "center" }}>
                      <div class="img">
                        <span style={{ fontSize: "14px" }}>تحميل الصورة</span>
                        <span><i class="ri-image-fill" style={{ fontSize: "1.2rem", color: "#0dcaf0" }}></i></span>
                        <input type="file" id="" multiple onChange={handleFileChange} />
                      </div>
                    </div>
                    <div className="button_share" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                      <button className='btn_add_post' onClick={() => { handleUpload() }} >شارك</button>
                      <button style={{
                        border: "none",
                        background: "none",
                        fontSize: "1.4rem",
                        paddingRight: "5px"
                      }} onClick={() => { setshow(!show) }}><i class="ri-close-line"></i></button>



                    </div>

                  </div>
                </div>
              </div>
            }

<div className="posts" style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
              {

                datapost.map((item, index) => (
                  <>
                    <div className="post" key={index} style={{ position: "relative",background:`${under[item.id]?"#ddd":"#fff"}` }}>
                      {
                        modaledit[item.id] && <div className="modal-edit" style={{
                          width: "max-content",
                          padding: "10px 10px 5px 40px",
                          position: "absolute",
                          background: "#fff",
                          top: "52px",
                          left: "7px",
                          borderRadius: "4px"
                        }}>
                          <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "end",
                            marginBottom: "0px", paddingLeft: "0px"
                          }}>
                            <li style={{ cursor: "pointer" }} onClick={() => { deletepost(item.id) }}>حذف</li>
                            <li style={{ cursor: "pointer" }} onClick={() => { setshow2(!show2) }} >تعديل</li>
                          </ul>
                        </div>
                      }
                      {
                        show2 === true && <div className="add_post_click "   >
                          <div className="con"  >
                            <div className="header">
                              <span><i class="ri-emotion-happy-line" style={{ fontSize: "1.2rem" }}></i></span>
                              <div className="info_user">
                                <span>{user_name}</span>
                                <img src={profilehome && token ? profilehome.profile : User_icon ? User_icon : ""} alt="" />
                              </div>

                            </div>
                            <span style={{ display: "flex", alignItems: "center", gap: "2px", marginTop: "10px" }}>
                              <select required name="" id="" value={category_id} style={{ width: "100%" }} onChange={handlechangecategoryid}>
                                <option value="أختر"> أختر الاقسام</option>
                                {
                                  categories.map((item) => (

                                    <option value={item.id}>{item.name}</option>
                                  ))
                                }
                              </select>
                            </span>
                            <input type="text" placeholder='العنوان' value={title} onChange={handletitle} style={{ marginTop: "10px", width: "100%" }} />
                            <input type="text" placeholder='رابط اليوتيوب' value={youtube} onChange={handleyoutube} style={{ marginTop: "10px", width: "100%" }} />
                            <textarea style={{ marginTop: "10px", height: "110px", width: "100%",    backgroundColor: "initial",
    border: "0",
    borderRadius: "12px",
    boxShadow:" 0 0 0 1px #3c425729, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 1px 1px 0 #0000001f, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000",
    color: "#393d4a",
    direction: "rtl",
    fontFamily: "inherit",
    fontSize: "14px",
    fontWeight: "400",    
    lineHeight: "28px",
    padding: "13px",
    textAlign: "right",
    transition: "background-color .24s, box-shadow .24s", }} value={documentt} onChange={handleContentChange} type="text" placeholder='الموضوع' />
                            <div class="footer">
                              <div class="buttons" style={{ textAlign: "center" }}>
                                <div class="img">
                                  <span style={{ fontSize: "14px" }}>تحميل الصورة</span>
                                  <span><i class="ri-image-fill" style={{ fontSize: "1.2rem", color: "#0dcaf0" }}></i></span>
                                  <input type="file" id="" multiple onChange={handleFileChange} />
                                </div>
                              </div>
                              <div className="button_share" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                                <button className='btn_add_post' onClick={() => { updatepost(item.id) }} >شارك</button>
                                <button style={{
                                  border: "none",
                                  background: "none",
                                  fontSize: "1.4rem",
                                  paddingRight: "5px"
                                }} onClick={() => { setshow2(!show2) }}><i class="ri-close-line"></i></button>



                              </div>

                            </div>
                          </div>
                        </div>
                      }
                      {
                        modal[item.id] && <div className="modal-edit" style={{
                          width: "max-content",
                          padding: "10px 10px 5px 40px",
                          position: "absolute",
                          background: "#fff",
                          top: "52px",
                          left: "7px",
                          borderRadius: "4px",zIndex:"9"
                        }}>
                          <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "end",
                            marginBottom: "0px", paddingLeft: "0px"
                          }}>
                            <li style={{ cursor: "pointer" }}>ابلاغ</li>
                            <li style={{ cursor: "pointer" }}>حظر</li>
                          </ul>
                        </div>
                      }
                      <div className="post_header" style={{ paddingRight: "9px" }}>
                        <div className="left" style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px", marginLeft: "4px"
                        }}><span onClick={() => { modedit(item.id, item.user_id) }}><i class="ri-more-2-line" style={{ fontSize: "1.2rem", cursor: "pointer" }}></i></span>
                          <span
                            // eslint-disable-next-line eqeqeq
                            style={{ display: `${item.user_id == user_id ? 'none' : 'block'}` }}
                          ><span onClick={() => { follow(item.id); }} style={{
                            cursor: "pointer",
                            display: `${token ? 'block' : 'none'}`,
                            // eslint-disable-next-line no-const-assign
                            //token&&item.?"green":red 
                            borderRadius: "5px", background: `${item.is_follow ? "#fff" : "red"}`, color: `${item.is_follow ? "green" : "#fff"}`, padding: "0px 10px"
                          }}  >
                              {
                                item.is_follow ? "متابع" : "متابعه"
                              }
                            </span></span>
                        </div>
                        <Link to={`/user/${item.user_id}`} style={{ textDecoration: "none", color: "#000" }}>
                          <div className="right" style={{ gap: "15px" }}>
                            <div className="left">
                              <div className="user" style={{ textAlign: "end" }}>{item.user.name}</div>
                              <div className="type_category" style={{ justifyContent: "end" }}>
                                <span style={{ textAlign: "end", color: "red" }}>{item.category.name}</span>
                                <span style={{ direction: "rtl", display: "none" }}>{item.created_at}</span>
                              </div>
                            </div>
                            <div className="right">
                              {
                                item.user.profile == null ? <img loading='lazy' src={User_icon} alt="" style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%"
                                }} /> : <img loading='lazy' src={item.user.profile} alt="" style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%"
                                }} />
                              }
                            </div>
                          </div>

                        </Link>                    </div>
                      <div className="post_content" style={{ textAlign: "end", marginTop: "5px", display: "flex", flexDirection: "column", gap: "10px" }}>
                        {
                          item.content !== "" && item.image === "" && item.youtube === "" ? <p style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end", padding: "10px 13px 0 10px"
                          }}>
                            <h2 style={{    overflow: "hidden",
    textAlign: "end",
    width: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color:"#000"}}>{item.title}</h2>
                            <p style={{
                            height: `${more[item.id] ? "100%" : "50px"}`,
                            overflow: "hidden",
                            textAlign: "end", width: "100%", fontSize: "18px",
                            fontWeight: "bold",
                            color: "#07070796",
                          }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p> :
                            item.content === "" && item.image !== "" && item.youtube === "" ? <div><img loading='lazy' onClick={menuToggle} src={item.image} style={{ width: "100%", height: "350px" }} alt=''></img>  </div> :
                              item.content === "" && item.images_count === 1 && item.youtube !== "" ? <iframe id="ytplayer" title='youtube' width="100%" height="340"
                                src={item.youtube} frameborder="0"></iframe> :
                                item.content !== "" && item.images_count === 1 && item.youtube === "" ? <div ><img loading='lazy' style={{ width: "100%", height: "350px" }} src={item.image} alt=''></img> <p style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "end",
                                  paddingTop: "4px",
                                  paddingRight: "11px"
                                }}>
                                   <h2 style={{    overflow: "hidden",
    textAlign: "end",
    width: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color:"#000"}}>{item.title}</h2>
                                  <p style={{
                                  height: `${more[item.id] ? "100%" : "50px"}`,
                                  overflow: "hidden",
                                  textAlign: "end", width: "100%", fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#07070796"
                                }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p></div> :
                                  item.content !== "" && item.image === "" && item.youtube !== "" ? <><iframe id="ytplayer" title='youtube' width="100%" height="340"
                                    src={item.youtube} frameborder="0"></iframe>
                                    <p style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "end", width: "100%"
                                    }}><h2 style={{    overflow: "hidden",
                                    textAlign: "end",
                                    width: "100%",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color:"#000"}}>{item.title}</h2>
                                                                  <p style={{
                                                                  height: `${more[item.id] ? "100%" : "50px"}`,
                                                                  overflow: "hidden",
                                                                  textAlign: "end", width: "100%", fontSize: "18px",
                                                                  fontWeight: "bold",
                                                                  color: "#07070796"
                                                                }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p></> :
                                    item.images_count > 1 ?
                                      <Swiper
                                        style={{
                                          width: "100%",
                                          height: "370px", marginLeft: "0px"
                                        }}
                                        // install Swiper modules
                                        modules={[Navigation, Pagination, Autoplay]}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        navigation


                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 3000 }}
                                        loop={true} // تكرار العرض


                                      // scrollbar={{ draggable: true }}

                                      >
                                        {
                                          item.images.map((item, index) => (

                                            <SwiperSlide style={{ width: "100%", marginLeft: "0px" }} key={index}><img loading='lazy' src={item.url} style={{
                                              width: "100%",
                                              height: "350px"
                                            }} alt='' /></SwiperSlide>
                                          ))
                                        }

                                      </Swiper>
                                      : <>""</>

                        }
                      </div>
                      {
                        item.image !== "" ? <div className="model" ref={model} onClick={menuToggle} style={{
                          height: "380px",
                          position: "absolute",
                          width: "100%",
                          top: "0",
                          border: "1px solid #ddd",
                          background: "#fff",
                          padding: "10px",
                          textAlign: "center",
                          borderRadius: "10px",
                          display: "none"
                        }}><img src={item.image} style={{ width: "80%", height: "350px", borderRadius: "15px" }} alt=''></img></div> : <></>
                      }
                      <div className="seen-addfollow" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px" }}>
                        <span style={{ marginLeft: "4px" }}><img src={gift} style={{
                          width: "40px",
                          height: "40px"
                        }} alt="" /></span>
                        <span style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px", marginRight: "4px"
                        }}><span style={{ color: "green" }}>{item.seen}</span><span><i class="ri-eye-line" style={{ fontSize: "1.4rem", color: "c0c0c0" }}></i></span></span>
                      </div>
                      <div className="buttons_action" style={{
                        display: "flex", justifyContent: "space-around", borderTop: "1px solid #c0c0c0",
                        marginTop: "5px"
                      }}>
                        <Link to={`https://creen-program.com/chats/share/${item.id}`} className='internal_share' style={{ transform: "scaleX(-1)",textDecoration:"none" }}><i class="ri-share-forward-line" style={{
                          fontSize: "1.9rem", cursor: "pointer", color: "#c0c0c0", transform: "scaleX(-1)"


                        }}></i></Link>
                        <span className='retweet' onClick={() => { handleretweet(item.id); }} style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "#c0c0c0" }}>
                          {
                            retweetState[item.id] ? <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "green" }}></i> : <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer" }}></i>
                          }<span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.retweets_count}</span></span>

                        <span className='external_share' onClick={() => { openmodelunder(item.id) }}><i class="ri-share-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "#c0c0c0" }}></i></span>

                        <span className='comment' onClick={() => { Togglercomment(item.id); fetchcomments(item.id) }} style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "#c0c0c0" }}><i class="ri-message-3-line" style={{ fontSize: "1.9rem", cursor: "pointer" }}></i><span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.comments_count}</span></span>

                        <span
                          className='heart'
                          onClick={() => handlelikepost(item.id)}
                          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                        >
                          {item.is_like ? (
                            <>      <i className="ri-heart-fill" style={{ color: "red", fontSize: "1.9rem", cursor: "pointer" }}></i>

                            </>
                          ) : (
                            <>      <i className="ri-heart-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "#c0c0c0" }}></i>

                            </>
                          )}
                          <span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.likes_count}</span>

                        </span>



                      </div>

                      {
                        under[item.id] &&
                        <div>

<div className="modal-share" style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "10px", position: "absolute",
                          top: `${item.youtube !== ""?"70%":"50%"}`,
                          left: "50%", zIndex: "9999999",transform:"translate(-50%,-50%)"
                        }}>
                          <button style={{
                            padding: "10px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#000",
                            backgroundColor: "transparent",border:"none",fontSize:"1.4rem"
                          }} onClick={()=>{closeshare(item.id)}}><i class="ri-close-line"></i></button>
                          <Link to={`https://wa.me/?text=https://creen.creen-program.com/postview/${item.id}`} style={{ textDecoration: "none", color: "green" }}><i style={{ fontSize: "2.4rem" }} class="ri-whatsapp-fill"></i></Link>
                          <Link style={{ textDecoration: "none", color: "#1DA1F2" }} to={`https://twitter.com/intent/tweet?text=Default+share+text&amp;url=https://creen.creen-program.com/postview/${item.id}`}><i style={{ fontSize: "2.4rem" }} class="ri-twitter-fill"></i></Link>
                          <Link style={{ textDecoration: "none", color: "#3b5998" }} to={`https://www.facebook.com/sharer/sharer.php?u=https://creen.creen-program.com/postview/${item.id}`}><i style={{ fontSize: "2.4rem" }} class="ri-facebook-box-fill"></i></Link>
                        </div>
                        </div>
                      }
                      {
                        <> {
                          comments.length !== 0 && expand[item.id] &&

                          comments.map((item, index) => (

                            <div className="comment" key={index} style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              marginBottom: "15px",
                              padding: "10px"
                            }}>
                              <div className="left" style={{ width: "100%" }}>
                                <div className="top" style={{ display: "flex", justifyContent: "space-between " }}>
                                  <div className="action"><i class="ri-more-2-line"></i></div>
                                  <div className="user_name">{item.user_name}</div>
                                </div>
                                <div className="info" style={{
                                  background: "rgb(221 221 221 / 30%)",
                                  padding: "15px",
                                  textAlign: "end",
                                  marginTop: "5px", marginLeft: "15px"
                                }}>

                                  <div className="content_comment" style={{ lineBreak: "anywhere" }}>{item.comment}</div>
                                </div>
                              </div>
                              <div className="righ"><img style={{ width: "30px", height: "30px", borderRadius: "50px" }} src={item.user !== "" ? item.logo : User_icon} alt=''></img></div>
                            </div>

                          ))


                        }

                        </>
                      }
                      {
                        expand[item.id] &&
                        <div className="add_comment" style={{ marginTop: "10px" }}>
                          <div className="form" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <div className="send" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <span onClick={() => { fetchcomment(item.id); }}><i class="ri-send-plane-2-fill" style={{ fontSize: "1.5rem", cursor: "pointer", paddingLeft: "11px" }}></i></span>

                            </div>
                            < input type="text" placeholder="اكتب تعليق وأضغط أنتر .." style={{ background: "#fff", border: "1px solid #7d7d7d" }} onChange={handelchangecomment} />

                            <img src={User_icon} style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="" />
                          </div>

                        </div>
                      }

                    </div>
                    {
                      index % 6 === 1 ?
                        <div className="" style={{
                          /* overflow: scroll; */
                          width: "100%"
                        }}>
                          <ul style={{
                            display: "flex",
                            direction: "rtl",
                            gap: "5px",
                            /* width: 100%; */
                            overflow: "scroll",
                            marginBottom: "-12px"

                          }}>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={`${token ? "/services/5" : "/login"}`}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                              }} >

                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الاطباء</span>
                                <img style={{ width: "190px", height: "229px", borderRadius: "10px" }} src={doctors} alt="" />
                              </li>
                            </Link>


                            <Link style={{ textDecoration: "none", color: "#000" }} to={`${token ? "/services/3" : "/login"}`}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>المدربين</span>
                                <img src={trainers} alt="" style={{ width: "190px", height: "229px", borderRadius: "10px" }} />
                              </li>
                            </Link>
                            <li style={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                              borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                            }}>
                              <span style={{
                                fontSize: "22px",
                                fontWeight: "bold"
                              }}>التجميل</span>
                              <img src={bono} alt="" style={{ width: "190px", height: "229px", borderRadius: "10px" }} />
                            </li>


                          </ul>
                        </div> : ""
                    }
                    {
                      index % 8 === 0 ?
                        <div className="" style={{
                          /* overflow: scroll; */
                          width: "100%"
                        }}>
                          <ul style={{
                            display: "flex",
                            direction: "rtl",
                            gap: "5px",
                            width: "100%",
                            overflow: "scroll"
                          }}>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/108"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }} >

                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الاغذية</span>
                                <img style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} src={foods} alt="" />
                              </li>
                            </Link>


                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/123"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>المكملات</span>
                                <img src={vita} alt="" style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} />
                              </li>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/146"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الملابس الرياضية</span>
                                <img src={sportcloth} alt="" style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} />
                              </li>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/148"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الادوات الرياضية</span>
                                <img src={sportts} alt="" style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} />
                              </li>
                            </Link>




                          </ul>
                        </div> : ""
                    }
                  </>
                ))


              }
            </div>

            <div className="posts" style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
              {

                data.map((item, index) => (
                  <>
                    <div className="post" key={index} style={{ position: "relative" }}>
                      {
                        modaledit[item.id] && <div className="modal-edit" style={{
                          width: "max-content",
                          padding: "10px 10px 5px 40px",
                          position: "absolute",
                          background: "#fff",
                          top: "52px",
                          left: "7px",
                          borderRadius: "4px"
                        }}>
                          <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "end",
                            marginBottom: "0px", paddingLeft: "0px"
                          }}>
                            <li style={{ cursor: "pointer" }} onClick={() => { deletepost(item.id) }}>حذف</li>
                            <li style={{ cursor: "pointer" }} onClick={() => { setshow2(!show2) }} >تعديل</li>
                          </ul>
                        </div>
                      }
                      {
                        show2 === true && <div className="add_post_click "   >
                          <div className="con"  >
                            <div className="header">
                              <span><i class="ri-emotion-happy-line" style={{ fontSize: "1.2rem" }}></i></span>
                              <div className="info_user">
                                <span>{user_name}</span>
                                <img src={profilehome && token ? profilehome.profile : User_icon ? User_icon : ""} alt="" />
                              </div>

                            </div>
                            <span style={{ display: "flex", alignItems: "center", gap: "2px", marginTop: "10px" }}>
                              <select required name="" id="" value={category_id} style={{ width: "100%" }} onChange={handlechangecategoryid}>
                                <option value="أختر"> أختر الاقسام</option>
                                {
                                  categories.map((item) => (

                                    <option value={item.id}>{item.name}</option>
                                  ))
                                }
                              </select>
                            </span>
                            <input type="text" placeholder='العنوان' value={title} onChange={handletitle} style={{ marginTop: "10px", width: "100%" }} />
                            <input type="text" placeholder='رابط اليوتيوب' value={youtube} onChange={handleyoutube} style={{ marginTop: "10px", width: "100%" }} />
                            <textarea style={{ marginTop: "10px", height: "110px", width: "100%",    backgroundColor: "initial",
    border: "0",
    borderRadius: "12px",
    boxShadow:" 0 0 0 1px #3c425729, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 1px 1px 0 #0000001f, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000, 0 0 0 0 #0000",
    color: "#393d4a",
    direction: "rtl",
    fontFamily: "inherit",
    fontSize: "14px",
    fontWeight: "400",    
    lineHeight: "28px",
    padding: "13px",
    textAlign: "right",
    transition: "background-color .24s, box-shadow .24s", }} value={documentt} onChange={handleContentChange} type="text" placeholder='الموضوع' />
                            <div class="footer">
                              <div class="buttons" style={{ textAlign: "center" }}>
                                <div class="img">
                                  <span style={{ fontSize: "14px" }}>تحميل الصورة</span>
                                  <span><i class="ri-image-fill" style={{ fontSize: "1.2rem", color: "#0dcaf0" }}></i></span>
                                  <input type="file" id="" multiple onChange={handleFileChange} />
                                </div>
                              </div>
                              <div className="button_share" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                                <button className='btn_add_post' onClick={() => { updatepost(item.id) }} >شارك</button>
                                <button style={{
                                  border: "none",
                                  background: "none",
                                  fontSize: "1.4rem",
                                  paddingRight: "5px"
                                }} onClick={() => { setshow2(!show2) }}><i class="ri-close-line"></i></button>



                              </div>

                            </div>
                          </div>
                        </div>
                      }
                      {
                        modal[item.id] && <div className="modal-edit" style={{
                          width: "max-content",
                          padding: "10px 10px 5px 40px",
                          position: "absolute",
                          background: "#fff",
                          top: "52px",
                          left: "7px",
                          borderRadius: "4px",zIndex:"9"
                        }}>
                          <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            alignItems: "end",
                            marginBottom: "0px", paddingLeft: "0px"
                          }}>
                            <li style={{ cursor: "pointer" }}>ابلاغ</li>
                            <li style={{ cursor: "pointer" }}>حظر</li>
                          </ul>
                        </div>
                      }
                      <div className="post_header" style={{ paddingRight: "9px" }}>
                        <div className="left" style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px", marginLeft: "4px"
                        }}><span onClick={() => { modedit(item.id, item.user_id) }}><i class="ri-more-2-line" style={{ fontSize: "1.2rem", cursor: "pointer" }}></i></span>
                          <span
                            // eslint-disable-next-line eqeqeq
                            style={{ display: `${item.user_id == user_id ? 'none' : 'block'}` }}
                          ><span onClick={() => { follow(item.id); }} style={{
                            cursor: "pointer",
                            display: `${token ? 'block' : 'none'}`,
                            // eslint-disable-next-line no-const-assign
                            //token&&item.?"green":red 
                            borderRadius: "5px", background: `${item.is_follow ? "#fff" : "red"}`, color: `${item.is_follow ? "green" : "#fff"}`, padding: "0px 10px"
                          }}  >
                              {
                                item.is_follow ? "متابع" : "متابعه"
                              }
                            </span></span>
                        </div>
                        <Link to={`/user/${item.user_id}`} style={{ textDecoration: "none", color: "#000" }}>
                          <div className="right" style={{ gap: "15px" }}>
                            <div className="left">
                              <div className="user" style={{ textAlign: "end" }}>{item.user.name}</div>
                              <div className="type_category" style={{ justifyContent: "end" }}>
                                <span style={{ textAlign: "end", color: "red" }}>{item.category.name}</span>
                                <span style={{ direction: "rtl", display: "none" }}>{item.created_at}</span>
                              </div>
                            </div>
                            <div className="right">
                              {
                                item.user.profile == null ? <img loading='lazy' src={User_icon} alt="" style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%"
                                }} /> : <img loading='lazy' src={item.user.profile} alt="" style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%"
                                }} />
                              }
                            </div>
                          </div>

                        </Link>                    </div>
                      <div className="post_content" style={{ textAlign: "end", marginTop: "5px", display: "flex", flexDirection: "column", gap: "10px" }}>
                        {
                          item.content !== "" && item.image === "" && item.youtube === "" ? <p style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end", padding: "10px 13px 0 10px"
                          }}>
                            <h2 style={{    overflow: "hidden",
    textAlign: "end",
    width: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color:"#000"}}>{item.title}</h2>
                            <p style={{
                            height: `${more[item.id] ? "100%" : "30px"}`,
                            overflow: "hidden",
                            textAlign: "end", width: "100%", fontSize: "18px",
                            fontWeight: "bold",
                            color: "#07070796",
                          }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p> :
                            item.content === "" && item.image !== "" && item.youtube === "" ? <div><img loading='lazy' onClick={menuToggle} src={item.image} style={{ width: "100%", height: "350px" }} alt=''></img>  </div> :
                              item.content === "" && item.images_count === 1 && item.youtube !== "" ? <iframe id="ytplayer" title='youtube' width="100%" height="340"
                                src={item.youtube} frameborder="0"></iframe> :
                                item.content !== "" && item.images_count === 1 && item.youtube === "" ? <div ><img loading='lazy' style={{ width: "100%", height: "350px" }} src={item.image} alt=''></img> <p style={{
                                  display: `${item.content ===null?"none":"flex"}`,
                                  flexDirection: "column",
                                  alignItems: "end",
                                  paddingTop: "4px",
                                  paddingRight: "11px"
                                }}>
                                   <h2 style={{    overflow: "hidden",
    textAlign: "end",
    width: "100%",
    fontSize: "20px",
    fontWeight: "bold",
    color:"#000"}}>{item.title}</h2>
                                  <p style={{
                                  height: `${more[item.id] ? "100%" : "30px"}`,
                                  overflow: "hidden",
                                  textAlign: "end", width: "100%", fontSize: "18px",
                                  fontWeight: "bold",
                                  color: "#07070796"
                                }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p></div> :
                                  item.content !== "" && item.image === "" && item.youtube !== "" ? <><iframe id="ytplayer" title='youtube' width="100%" height="340"
                                    src={item.youtube} frameborder="0"></iframe>
                                    <p style={{
                                      display: `${item.content ===null?"none":"flex"}`,
                                      flexDirection: "column",
                                      alignItems: "end", width: "100%"
                                    }}><h2 style={{    overflow: "hidden",
                                    textAlign: "end",
                                    width: "100%",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color:"#000"}}>{item.title}</h2>
                                                                  <p style={{
                                                                  height: `${more[item.id] ? "100%" : "30px"}`,
                                                                  overflow: "hidden",
                                                                  textAlign: "end", width: "100%", fontSize: "18px",
                                                                  fontWeight: "bold",
                                                                  color: "#07070796"
                                                                }} >{item.content}</p><span style={{ marginTop: "-17px", color: "green", cursor: "pointer", display: `${item.content !== null ? "block" : "none"}` }}  >{more[item.id] ? <span onClick={() => { moree(item.id) }}>أقل</span> : <span onClick={() => { moree(item.id); }}>...المزيد</span>}</span></p></> :
                                    item.images_count > 1 ?
                                      <Swiper
                                        style={{
                                          width: "100%",
                                          height: "370px", marginLeft: "0px"
                                        }}
                                        // install Swiper modules
                                        modules={[Navigation, Pagination, Autoplay]}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        navigation


                                        pagination={{ clickable: true }}
                                        autoplay={{ delay: 3000 }}
                                        loop={true} // تكرار العرض


                                      // scrollbar={{ draggable: true }}

                                      >
                                        {
                                          item.images.map((item, index) => (

                                            <SwiperSlide style={{ width: "100%", marginLeft: "0px" }} key={index}><img loading='lazy' src={item.url} style={{
                                              width: "100%",
                                              height: "350px"
                                            }} alt='' /></SwiperSlide>
                                          ))
                                        }

                                      </Swiper>
                                      : <>""</>

                        }
                      </div>
                      {
                        item.image !== "" ? <div className="model" ref={model} onClick={menuToggle} style={{
                          height: "380px",
                          position: "absolute",
                          width: "100%",
                          top: "0",
                          border: "1px solid #ddd",
                          background: "#fff",
                          padding: "10px",
                          textAlign: "center",
                          borderRadius: "10px",
                          display: "none"
                        }}><img src={item.image} style={{ width: "80%", height: "350px", borderRadius: "15px" }} alt=''></img></div> : <></>
                      }
                      <div className="seen-addfollow" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px" }}>
                        <span style={{ marginLeft: "4px" }}><img src={gift} style={{
                          width: "40px",
                          height: "40px"
                        }} alt="" /></span>
                        <span style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px", marginRight: "4px"
                        }}><span style={{ color: "green" }}>{item.seen}</span><span><i class="ri-eye-line" style={{ fontSize: "1.4rem", color: "c0c0c0" }}></i></span></span>
                      </div>
                      <div className="buttons_action" style={{
                        display: "flex", justifyContent: "space-around", borderTop: "1px solid #c0c0c0",
                        marginTop: "5px"
                      }}>
                        <span className='internal_share' style={{ transform: "scaleX(-1)" }}><i class="ri-share-forward-line" style={{
                          fontSize: "1.9rem", cursor: "pointer", color: "#c0c0c0", transform: "scaleX(-1)"


                        }}></i></span>
                        <span className='retweet' onClick={() => { handleretweet(item.id); }} style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "#c0c0c0" }}>
                          {
                            retweetState[item.id] ? <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "green" }}></i> : <i class="ri-repeat-2-line" style={{ fontSize: "1.9rem", cursor: "pointer" }}></i>
                          }<span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.retweets_count}</span></span>

                        <span className='external_share' onClick={() => { openmodelunder(item.id) }}><i class="ri-share-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "#c0c0c0" }}></i></span>

                        <span className='comment' onClick={() => { Togglercomment(item.id); fetchcomments(item.id) }} style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "#c0c0c0" }}><i class="ri-message-3-line" style={{ fontSize: "1.9rem", cursor: "pointer" }}></i><span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.comments_count}</span></span>

                        <span
                          className='heart'
                          onClick={() => handlelikepost(item.id)}
                          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                        >
                          {item.is_like ? (
                            <>      <i className="ri-heart-fill" style={{ color: "red", fontSize: "1.9rem", cursor: "pointer" }}></i>

                            </>
                          ) : (
                            <>      <i className="ri-heart-line" style={{ fontSize: "1.9rem", cursor: "pointer", color: "#c0c0c0" }}></i>

                            </>
                          )}
                          <span style={{ fontSize: "14px", color: "green", marginTop: "-8px" }}>{item.likes_count}</span>

                        </span>



                      </div>

                      {
                        under[item.id] &&
                        <div className="modal-share" style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "10px", position: "absolute",
                          top: "14%",
                          left: "20%", zIndex: "9999999"
                        }}>
                          <button style={{
                            padding: "10px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            color: "#fff",
                            backgroundColor: "#6c757d"
                          }}>الغاء</button>
                          <Link to={"https://wa.me/?text=https://creen.creen-program.com/home"} style={{ textDecoration: "none", color: "green" }}><i style={{ fontSize: "2.4rem" }} class="ri-whatsapp-fill"></i></Link>
                          <Link style={{ textDecoration: "none", color: "#1DA1F2" }} to={"https://twitter.com/intent/tweet?text=Default+share+text&amp;url=https://creen.creen-program.com/home"}><i style={{ fontSize: "2.4rem" }} class="ri-twitter-fill"></i></Link>
                          <Link style={{ textDecoration: "none", color: "#3b5998" }} to={"https://www.facebook.com/sharer/sharer.php?u=https://creen.creen-program.com/home"}><i style={{ fontSize: "2.4rem" }} class="ri-facebook-box-fill"></i></Link>
                        </div>
                      }
                      {
                        <> {
                          comments.length !== 0 && expand[item.id] &&

                          comments.map((item, index) => (

                            <div className="comment" key={index} style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              marginBottom: "15px",
                              padding: "10px"
                            }}>
                              <div className="left" style={{ width: "100%" }}>
                                <div className="top" style={{ display: "flex", justifyContent: "space-between " }}>
                                  <div className="action"><i class="ri-more-2-line"></i></div>
                                  <div className="user_name">{item.user_name}</div>
                                </div>
                                <div className="info" style={{
                                  background: "rgb(221 221 221 / 30%)",
                                  padding: "15px",
                                  textAlign: "end",
                                  marginTop: "5px", marginLeft: "15px"
                                }}>

                                  <div className="content_comment" style={{ lineBreak: "anywhere" }}>{item.comment}</div>
                                </div>
                              </div>
                              <div className="righ"><img style={{ width: "30px", height: "30px", borderRadius: "50px" }} src={item.user !== "" ? item.logo : User_icon} alt=''></img></div>
                            </div>

                          ))


                        }

                        </>
                      }
                      {
                        expand[item.id] &&
                        <div className="add_comment" style={{ marginTop: "10px" }}>
                          <div className="form" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <div className="send" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <span onClick={() => { fetchcomment(item.id); }}><i class="ri-send-plane-2-fill" style={{ fontSize: "1.5rem", cursor: "pointer", paddingLeft: "11px" }}></i></span>

                            </div>
                            < input type="text" placeholder="اكتب تعليق وأضغط أنتر .." style={{ background: "#fff", border: "1px solid #7d7d7d" }} onChange={handelchangecomment} />

                            <img src={User_icon} style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="" />
                          </div>

                        </div>
                      }

                    </div>
                    {
                      index % 6 === 1 ?
                        <div className="" style={{
                          /* overflow: scroll; */
                          width: "100%"
                        }}>
                          <ul style={{
                            display: "flex",
                            direction: "rtl",
                            gap: "5px",
                            /* width: 100%; */
                            overflow: "scroll",
                            marginBottom: "-12px"

                          }}>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={`${token ? "/services/5" : "/login"}`}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                              }} >

                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الاطباء</span>
                                <img style={{ width: "190px", height: "229px", borderRadius: "10px" }} src={doctors} alt="" />
                              </li>
                            </Link>


                            <Link style={{ textDecoration: "none", color: "#000" }} to={`${token ? "/services/3" : "/login"}`}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>المدربين</span>
                                <img src={trainers} alt="" style={{ width: "190px", height: "229px", borderRadius: "10px" }} />
                              </li>
                            </Link>
                            <li style={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                              borderRadius: "10px", background: "#fff", display: "flex", flexDirection: "column", alignItems: "center"
                            }}>
                              <span style={{
                                fontSize: "22px",
                                fontWeight: "bold"
                              }}>التجميل</span>
                              <img src={bono} alt="" style={{ width: "190px", height: "229px", borderRadius: "10px" }} />
                            </li>


                          </ul>
                        </div> : ""
                    }
                    {
                      index % 8 === 0 ?
                        <div className="" style={{
                          /* overflow: scroll; */
                          width: "100%"
                        }}>
                          <ul style={{
                            display: "flex",
                            direction: "rtl",
                            gap: "5px",
                            width: "100%",
                            overflow: "scroll"
                          }}>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/108"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }} >

                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الاغذية</span>
                                <img style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} src={foods} alt="" />
                              </li>
                            </Link>


                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/123"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>المكملات</span>
                                <img src={vita} alt="" style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} />
                              </li>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/146"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الملابس الرياضية</span>
                                <img src={sportcloth} alt="" style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} />
                              </li>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/categorychildren/148"}>
                              <li style={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                                borderRadius: "10px", background: "#fff"
                              }}>
                                <span style={{
                                  fontSize: "22px",
                                  fontWeight: "bold"
                                }}>الادوات الرياضية</span>
                                <img src={sportts} alt="" style={{ width: "190px", height: "229px", background: "#000", borderRadius: "10px" }} />
                              </li>
                            </Link>




                          </ul>
                        </div> : ""
                    }
                  </>
                ))


              }
            </div>




          </div>

        </section>
        <LinksHome />

      </section>
      <div class="footer-mobile" style={{
        background: "#fff",
        border: "none",
        height: "48px",
        position: "sticky",
        zIndex: "9999",
        bottom: "0", display: "none"
      }}>
        <div class="container">
          <ul style={{
            paddingLeft: "0 ", display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
            <li>

              <Link to={`${token ? `/user/${user_id}` : `/login`}`}>
                <img src={profilehome && token ? profilehome.profile : User_icon} alt="" style={{ width: "40px", height: "40px", cursor: "pointer", borderRadius: "50%" }} />
              </Link>
            </li>
            <li>
              <Link to={"/products"} style={{ textDecoration: "none" }}><span><i style={{ fontSize: "1.9rem", cursor: "pointer", color: "#bcbcbc" }} class="ri-shopping-bag-line"></i></span></Link>
            </li>
            <Link to={"/live"}>
              <li style={{
                background: "#ddd",
                padding: "5px",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                textAlign: "center"
              }}>
                <img src="https://creen-program.com/new/images/m1.png" style={{ width: "1.9rem" }} alt="" />
              </li>
            </Link>
            <li>
              <Link to={"/towatch"} style={{ textDecoration: "none", color: "#fff" }} > <i style={{
                fontSize: "1.7rem",
                color: "#fff",
                cursor: "pointer",
                borderRadius: "10px",
                width: "40px",
                height: "40px",
                textAlign: "center",
                padding: "2px 8px 2px 8px",
                background: "#7d7d7d",
              }} class="ri-play-line"></i></Link>
            </li>
            <li style={{
              color: "rgb(255, 255, 255)",
              cursor: "pointer",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              textAlign: "center",
              background: "rgb(125, 125, 125)"
            }} onClick={() => { setshow(true) }}>

              <i style={{ fontSize: "1.7rem" }} class="ri-add-line"></i>
            </li>
          </ul>
        </div>
      </div>

    </>
  )

}

export default Postview