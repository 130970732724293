import React ,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Cookiesservices from '../../Services/Cookiesservices';
import User_icon from "../../assets/images/user-icon.png"
const Footer = () => {
    const [profilehome, setProfileHome] = useState({});
const [loadingProfile, setLoadingProfile] = useState(true);
const user_id=localStorage.getItem('user_id')
const token=Cookiesservices.get('jwt')
useEffect(() => {
  const fetchDataUser = async (user_id, token) => {
    try {
      const response = await axios.get(`https://creen-program.com/api/users/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // console.error('خطأ في الطلبات المتكررة:', error);
        return null; // توقف عملية الاسترجاع في حالة حدوث خطأ 429
      } else {
        throw error; // رمي الخطأ في حالة حدوث أي خطأ آخر
      }
    }
  };

  const source = axios.CancelToken.source(); // إنشاء مصدر لإلغاء الطلب
  let retries = 0; // عدد مرات المحاولات
  const maxRetries = 3; // الحد الأقصى لعدد مرات المحاولة
  const retryDelay = 3000; // تأخير بين كل محاولة (بالميللي ثانية)

  const fetchUserProfile = async () => {
    try {
      const userProfile = await fetchDataUser(user_id, token);
      if (userProfile !== null) {
        setProfileHome(userProfile);
        setLoadingProfile(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 429 && retries < maxRetries) {
        console.error('تم تجاوز الحد الأقصى لعدد المحاولات. يتم إعادة المحاولة بعد التأخير.');
        setTimeout(fetchUserProfile, retryDelay);
        retries++;
      } else {
        console.error('حدث خطأ في جلب ملف المستخدم:', error);
        setLoadingProfile(false);
      }
    }
  };

  if (user_id && token) {
    fetchUserProfile();
  }

  return () => {
    source.cancel('تم إلغاء الطلب'); // إلغاء الطلب عند إلغاء المكون
  };
}, [user_id, token]);  
  return (
<div class="footer-mobile" style={{
        background: "#fff",
        border: "none",
        height: "52px",
        position: "sticky",
        zIndex: "9999999999999999999999999",
        bottom: "0", display: "none"
      }}>
        <div class="container" style={{paddingTop:"5px"}}>
          <ul style={{
            paddingLeft: "0", display: "flex",
            alignItems: "center",
            justifyContent: "space-between"          }}>
                 <Link to={`${token ?`/user/${user_id}`:`/login`}`}>
            <img src={profilehome.profile !==null && token ? profilehome.profile: User_icon} alt="" style={{ width: "40px", height: "40px", cursor: "pointer",borderRadius:"50%" }} />
</Link>    

<Link style={{textDecoration:"none"}} to={"/products"}>
              <li>
                        <span><i style={{fontSize:"1.9rem",cursor:"pointer",color:"#bcbcbc"}} class="ri-shopping-bag-line"></i></span>
                </li></Link>
            <Link to={"/live"}>
          <li style={{    background: "#ddd",
    padding: "5px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center"}}>
              <img src="https://creen-program.com/new/images/m1.png" style={{ width: "1.9rem" }} alt="" />
            </li> 
          </Link>            <li>
              <Link to={"/towatch"} style={{ textDecoration: "none", color: "#fff" }} > <i style={{
                fontSize: "1.7rem",
                color: "#fff",
                cursor: "pointer",
                borderRadius: "10px",
                width: "40px",
                height: "40px",
                textAlign: "center",
                padding: "2px 8px 2px 8px",
                background: "#7d7d7d",
              }} class="ri-play-line"></i></Link>
            </li>
            <Link to={"/home"}  style={{textDecoration:"none",color:"#7d7d7d"}} >                <i  style={{fontSize:"1.9rem",cursor:"pointer",color:"#7d7d7d"}} class="ri-home-4-line"></i>
</Link>          </ul>
        </div>
      </div>  )
}

export default Footer