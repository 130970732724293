import React , {useState,useEffect ,useRef} from 'react'
import logo from "../../assets/images/ic_launcher.png"
import iconchat from "../../assets/images/icon-removebg-preview.png"
import user_icon from "../../assets/images/user-icon.png"
import "../Header/Header"
import Cookiesservices from '../../Services/Cookiesservices'
import axios from 'axios'
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'



const Headermob = () => {
const navigate=useNavigate()
const user_id=localStorage.getItem('user_id')
    const [tab,settab]=useState('main')
    const  token=Cookiesservices.get("jwt")
const Navv=()=>{
  if(token){
    navigate(`/user/${user_id}`)
  }else{
    navigate("/login")
  }
  
} 
const [profilehome, setProfileHome] = useState([]);
const [loadingProfile, setLoadingProfile] = useState(true);
const [notifications, setNotifications] = useState([]);
const [shownoti, setshownoti] = useState(false);
const [dataLoaded, setDataLoaded] = useState(false);
const [retryCount, setRetryCount] = useState(0);

const maxRetries = 5;
const retryDelay = 1000;
useEffect(() => {
  const fetchData = async () => {
    try {
      if (loadingProfile) {
        const response = await axios.get(`https://creen-program.com/api/users/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setProfileHome(response.data.data);
        setLoadingProfile(false);
      }

      if (!dataLoaded) {
        const response = await axios.get(`https://creen-program.com/api/notifications`, {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'accept': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });
        setNotifications(response.data.data.data);
        setDataLoaded(true);
      }
    } 
    catch (error) {
      // console.error('Error fetching data:', error);
      setLoadingProfile(false);

      if (error.response && error.response.status === 429 && retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(retryCount + 1);
        }, retryDelay);
      } else {
        // Handle error appropriately
      }
    }
  };

  fetchData();
  // return { profileHome, loadingProfile, notifications, showNoti,  };

}, [user_id, token, loadingProfile, dataLoaded, retryCount]);

const deletenoti=async(notification_id)=>{
  try {
    const response = await axios.post(`https://creen-program.com/api/notifications/destroy?notification_id=${notification_id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data.status === true) {
      toast.success("تم حذف النوتيفيكشن");
      setshownoti(false)
      document.body.style.overflow="auto"

      
    }
  } catch (error) {
    // console.error("Error deleting post:", error);
    // يمكنك إضافة إخطار للمستخدم هنا في حالة فشل الحذف
  }}
  // const n=(item)=>{
  //   // to={`${item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"?`/postview/${item.model_id}`
  //   // :item.url.includes("lives")?`${item.url}`:
  //   // `https://www.creen-program.com/programs_timeline/private/${item.model_id}`}`}
  //   if(item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"){
  //     navigate(`/postview/${item.model_id}`)
  //   }
  //   else if(item.url.includes("lives")){
  //     navigate(`/${item.url}`)
  //   }else{
  //     navigate(`/${item.url}`)
  //   }
  // }
  const togglenoti=()=>{
    setshownoti(!shownoti)
  
    document.body.style.overflow = "hidden"
  }
  const overref = useRef(null);
  // const openmodel = () => {
  //   setover(true)
  //   document.body.style.overflow = "hidden"
  // }
  const close = (e) => {
    if (overref.current && !overref.current.contains(e.target)) {
      setshownoti(false)
      document.body.style.overflow = "auto"


    }
  }
  
  
  
  useEffect(() => {
    document.addEventListener("mousedown", close)
    return () => {
      document.removeEventListener("mousedown", close)
    }
  }, [])
  return (
<>
<header className='header_mobile   to-watch-mobile' style={{display:"none"}} >
      
      <div className="nav_wrapper">
        <div className="icons">
        <div className='profile' > <i class="ri-search-line" style={{fontSize:"1.7rem",color:"#fff"}}></i>  
        </div>
        <span><img src={iconchat} alt="" style={{width: "50px",
    height: "50px",
    borderRadius: "50%",
    color: "#fff"}} /></span>
        <Link to={"/home"}><div className="logo"><motion.img whileTap={{scale:1.2}} src={ /*currentUser? currentUser.photoURL :*/ logo}  alt="" /*onClick={toggleProfileActions}*/ /></div></Link>

        <Link><span ><i class="ri-notification-line"></i></span></Link>
        <Link  className='icon_mobile'><span><i class="ri-function-line"></i></span></Link>


        </div>
        
        

        
      </div>
      
  
</header>
<header className='header'>
  
      <div className="nav_wrapper">
        <div className="icons_left">
        <div className='profile'> <motion.img whileTap={{scale:1.2}}  onClick={Navv} src={ profilehome && token ? profilehome.profile : user_icon}  alt="" /*onClick={toggleProfileActions}*/ />

        
        </div>
        <Link style={{textDecoration:"none",color:"#fff",fontSize:"1.4rem"}} to={"/towatch"} ><i class="ri-live-line"></i></Link>
        <Link style={{textDecoration:"none",color:"#fff",fontSize:"1.4rem"}} to={"https://creen-program.com/chats"}><i class="ri-message-2-line"></i></Link>

        <Link style={{textDecoration:"none",color:"#fff",fontSize:"1.4rem"}} to={"/live"}><i class="ri-play-circle-line"></i></Link>
        </div>
        
        
        <div class="search_box">
          <input type="text" placeholder="إبحث عن أعضاء, #هاشتاغ" />
          <span><i class="ri-search-line"></i></span>
          </div>
        
        <div className="icons_right">
          <Link><span className={ `${tab==='add'?'active_tab': ''}  add`} onClick={()=>settab('add')}  ><span className='establishment'>إنشاء</span><i class="ri-add-circle-line"></i></span></Link>
          <Link to={"/home"}><span className={ `${tab==='main'?'active_tab': ''}  main`} onClick={()=>settab('main')}><span className='home_page'>الصفحه الرئيسية </span><i class="ri-mail-open-line"></i></span></Link>
          <Link onClick={togglenoti} style={{position:"relative",width:"max-content"}}><span style={{position: "absolute",
    top: "12px",
    left: "10px", background: "red",
    lineHeight:"1.6",width:"23px",height:"23px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50%"}}><span style={{   
      borderRadius: "50%",
      color: "#fff"}}>{notifications.length}</span></span><span ><i class="ri-notification-line"></i></span></Link>
                 <Link style={{display:'none'}} className='icon_mobile'><span><i class="ri-align-justify"></i></span></Link>
          <Link to={"/home"}><div className="logo"><motion.img whileTap={{scale:1.2}} src={ /*currentUser? currentUser.photoURL :*/ logo}  alt="" /*onClick={toggleProfileActions}*/ /></div></Link>
          
        </div>
      </div>
      {
        shownoti && <div ref={overref} className="notifications" style={{background: "#fff",
      width:"30%",
        textAlign: "end",
        padding: "10px",
        borderRadius: "10px",
        position: "absolute",
        zIndex: "100000000000000000000000000000000000",
         display: `${shownoti?'block':'none'}`,
        flexDirection: "column",gap: "6px",right:"0px",lineHeight:"normal",maxHeight:"100vh",overflow:"scroll"}}>
                {
                  notifications.map((item,index)=>(
                    <div style={{display:"flex",justifyContent:"space-between",borderBottom: "1px solid #ddd"}}>
                    <div onClick={()=>{deletenoti(item.id)}}>
                      <span><i style={{fontSize:"1.4rem"}} class="ri-close-line"></i></span>
                    </div>
                    <Link   to={`${item.type==="like"||(item.type==="comment" && !item.url.includes("lives"))||item.type==="retweet"?`/postview/${item.model_id}`
   :item.url.includes("lives")?`${item.url}`:item.type==="program_timeline"?
   `https://www.creen-program.com/programs_timeline/private/${item.model_id}`:`${item.url}`}`}  className="notification" key={index} style={{    display: "flex",
        flexDirection: "row-reverse",justifyContent:"space-between",
        textDecoration:"none",color:"#000"}}>
                    <div>
                    <p style={{color: "#000",
        direction: "rtl",
        textAlign: "right",
        fontSize: "16px",
        fontWeight: "bold",    display: "flex",
        flexDirection: "row-reverse",
        gap: "5px",justifyContent:"flex-end",marginBottom: "5px"}}>{item.content}<span>{item.type==="comment"?<i class="ri-chat-1-line"></i>:item.type==="like"?<i class="ri-thumb-up-line"></i>:""}</span></p>
                      <span style={ {fontSize: "16px",
        fontWeight: "bold",    marginBottom: "5px"      }}>{item.time_ago}منذ</span>
                    </div>
                    
                    </Link>
                    </div>
                  ))
                }
              </div>
          
        
      }


      
  
</header>
</>
    )
}

export default Headermob