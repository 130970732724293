import './App.css';
import Layout from './Layout/Layout';
function App() {
  const handleContextMenu = (e) => {
    e.preventDefault(); // منع السلوك الافتراضي للزر الأيمن
    // يمكنك تنفيذ أي دالة تريدها هنا، مثلاً إظهار رسالة تنبيه
    // alert('Right-click is disabled on this site!');
  };
  return (
    <div  onContextMenu={handleContextMenu}>
      <Layout  />

    </div>
  );
}

export default App;
