import React ,{useEffect , useState } from 'react'
import Cookiesservices from '../Services/Cookiesservices'
import { Link } from 'react-router-dom'
import "../styles/myproducts.css"
import { toast } from 'react-toastify'
import axios from 'axios'
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"

const Myproducts = () => {
    const user_id=localStorage.getItem('user_id')
     const token=Cookiesservices.get('jwt')
     const [data, setData] = useState([]);
const [page, setPage] = useState(1);
useEffect(() => {
  // إنشاء دالة لجلب البيانات من الخادم
  const fetchData = async () => {
      try {
          // إرسال الطلب إلى الخادم لجلب البيانات
          const response = await axios.post(
              `https://creen-program.com/api/products/my_products?user_id=${user_id}`, 
              {},
              {
                  // إضافة الرؤوس اللازمة للطلب
                  headers: {
                      'Content-Type': 'application/json; charset=UTF-8',
                      'Accept': 'application/json',
                      Authorization:`Bearer ${token}`
                  },
              }
          );

          // تحديث حالة البيانات باستخدام البيانات المستلمة من الطلب
          setData((prevData) => {
            const newData = response.data.data.products.data;
            // استخدم Set لتحديد القيم الفريدة فقط
            const uniqueData = Array.from(new Set([...prevData, ...newData]));
            return uniqueData;
          });      } catch (error) {
          // معالجة الأخطاء في حالة وجودها
          console.error('Error fetching data:', error);
          toast.error('Error fetching data:', error.message);
      }
  };

  // استدعاء دالة جلب البيانات عند تغيير أحد الاعتماديات
  fetchData();
}, [page, token, user_id]);

const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
        setPage((prevPage) => prevPage + 1);
    }
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
}, []);

useEffect(() => {
  window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
}, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  return (
    <>
    <Headermob/>
    <div className='myproducts'>
       {
        data.length==0?<span>لا توجد منتجات</span>:data.map((item,index)=>(
          <div className="myproduct" key={index} style={{    width: "200px",
          height: "300px",
          borderRadius: "10px",
          padding: "5px"}}>
                  <div className="img_myproducts">
                      <img  src={item.image.url} alt="" />
                  </div>
                  <div  className="description" style={{height:"max-content"}} ><p style={{marginBottom:"0px"}}>{item.description}</p></div>
                  
      <div class="price" style={{display: "flex", justifyContent: "end", alignItems: "center"}}><span>ريال سعودي</span><span style={{fontSize: "larger"}}>{item.price}</span></div>   
      <Link style={{textDecoration:"none"}}><button>شاهد المنتج</button></Link>         
      </div>
      ))
       }
        


    </div>
    <Footer/>
    </>
  )
}

export default Myproducts