import React, { useEffect, useState,useRef } from 'react'
// import Header from "../components/Header/Header"
import "../styles/services.css"
import Usericon from '../assets/images/user-icon.png'
import ReactStars from 'react-stars'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import { Link } from 'react-router-dom'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import { toast } from 'react-toastify'
import Headermob from "../components/headermobile/Headermob"
import Footer from '../components/footer/Footer';

const Services = () => { 
    const[tab , settab]=useState("الخدمات")
    const token=Cookiesservices.get('jwt');
    const [data , setdata]=useState([])
    const [subscriptions , setsubscriptions]=useState([])
    // const id_category=localStorage.getItem("id_category")
    // const [Number,setNumber]=useState()
    useEffect(() => {
      window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
    }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const [servicesResponse, subscriptionsResponse] = await Promise.all([
    //         axios.get(`https://creen-program.com/api/services`),
    //         axios.get(`https://creen-program.com/api/services/subscriptions`, {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         })
    //       ]);
    //       if(tab==="الخدمات"){
    //         setdata(servicesResponse.data.data);

    //       }
    //       if(tab==="الاشتراكات"){
    //         setsubscriptions(subscriptionsResponse.data.data);

    //       }
    
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //       // يمكنك إضافة معالجة للأخطاء هنا، مثلاً تعيين قيمة افتراضية للبيانات أو عرض رسالة خطأ للمستخدم
    //     }
    //   };
    
    //   fetchData();
    // }, [tab, token]);
    
    const [category_id, setcategoryid] = useState("")
    const [categories, setcatigories] = useState([])
    const [gender,setgender]=useState("")
    const [name,setname]=useState("")
    const [nationality,setnationality]=useState("")
    const [city,setcity]=useState("")
    const [education,seteducation]=useState("")
    const [nationalid,setnationalid]=useState("")
    const [phone,setphone]=useState("")
    const [email,setemail]=useState("")
    const [files,setFiles]=useState()
    const [description,setdescription]=useState([])
    const [joptitle,setjoptitle]=useState([])
    const[showservices , setshowservices]=useState(false)
   
    // useEffect(() => {
    
    //   const fetchCategories = async () => {
    //     try {
    //       if (!token && tab !== "الخدمات" ) return; // التحقق من توفر الرمز المميز وعدم إعادة إرسال الطلب
    
    
    //       if(tab==="الخدمات"){
    //         const response = await axios.get(`https://creen-program.com/api/categories`, {
    //         headers: {
    //           'Content-Type': 'application/json; charset=UTF-8',
    //           'Accept': 'application/json',
    //           'Authorization': `Bearer ${token}`
    //         }
    //       });
    //       setcatigories(response.data.data);

    //       }
    
          
    //     } catch (error) {
    //       if (error.response && error.response.status === 429) {
    //         console.log("تم استلام خطأ 429 - عدد الطلبات زائد");
    //         // يمكنك هنا تنفيذ إجراءات إعادة المحاولة بعد فترة زمنية محددة
    //       } else {
    //         console.error("حدث خطأ:", error);
    //       }
    //     }
    //   };
    
    //   fetchCategories(); // استدعاء الدالة لجلب الفئات عند تغيير الرمز المميز
    // }, [tab, token]);
    
    const handlechangecategoryid = (e) => {
      if (e.target.value) {
        setcategoryid(e.target.value)
  
      } else {
        // setcategoryid("")
        // setcatigories()
      }
    }
    const handleFileChange = (e) => {
      setFiles(e.target.value);
    };
    const handleUpload = async () => {
      try {
        const apiUrl = "https://creen-program.com/api/services/add";
        
  
        const formData = new FormData();
  
        // files.forEach((file, index) => {
        //   formData.append(`photos[${index}]`, file);
        // });
        formData.append("category_id", category_id);
         formData.append("photos", files);
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("nationalid", nationalid);
        formData.append("natoinality", nationality);
        formData.append("education", education);
        formData.append("city", city);
        formData.append("gender", gender);
        formData.append("description", description);
        formData.append("joptitle", joptitle);






        
  
  
        const response = await axios.post(apiUrl,formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          
          },
          body: formData,
        });
  
         if (response.status===200) {
           toast.success("File uploaded successfully");
           setshowservices(!showservices)
         }
      } catch (error) {
        // console.error('Error creating post:', error);
        if (error.response && error.response.status === 400) {
          // console.error('Server response data:', error.response.data);
        }
        toast.error('An error occurred while creating the post');
      }
    

      }
localStorage.setItem("Number_id",21)
const [num, setNum] = useState(0);

// useEffect(() => {
//   const fetchNumReservations = async () => {
//     try {
//       if (!token && tab ==="خدماتي") return; // التحقق من توفر الرمز المميز وعدم إعادة إرسال الطلب

    

//       const response = await axios.get(`https://creen-program.com/api/services/patients/21`, {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       });

//       setNum(response.data.data.count);
//     } catch (error) {
//       console.error("حدث خطأ:", error);
//     }
//   };

//   fetchNumReservations(); // استدعاء الدالة لجلب عدد الحجوزات عند تغيير الرمز المميز
// }, [token]); // يجب أيضاً تتبع حالة isFetching في الـ dependencies


const [service,setservice]=useState([]);
// useEffect(() => {
//   if (!token && !tab==="خدماتي ") {
//     return; // توقف إرسال الطلبات إذا لم يتم توفير token
//   }

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`https://creen-program.com/api/services/my`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       setservice(response.data.data.trainers);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   fetchData();
//  }, [token]);
// const [nump,setnump]=useState([]);
// const [mata, setMata] = useState([]);

// useEffect(() => {
//     if (!user_id) {
//         return; // تجنب إرسال الطلب إذا كانت قيمة user_id غير متوفرة
//     }

//     const fetchService = async () => {
//         try {
//             const response = await axios.get(`https://creen-program.com/api/services/trainer/show/${user_id}`);
//             setMata(response.data.data.trainer);
//         } catch (error) {
//             console.error('Error fetching service:', error);
//         }
//     };

//     fetchService();


// }, [user_id]);

// useEffect(() => {
//   if (!token) {
//       return; // تجنب إرسال الطلب إذا لم يتم توفير الـ token
//   }

//   const fetchData = async () => {
//       try {
//           const response = await axios.get(`https://creen-program.com/api/services/myusers/${user_id}`, {
//               headers: {
//                   Authorization: `Bearer ${token}`,
//               },
//           });
//           setnump(response.data.data.count);
//       } catch (error) {
//           console.error('Error fetching data:', error);
//       }
//   };

//   fetchData();
// }, [token, user_id]);
const [da,setda]=useState([])
const [dat,setdat]=useState([])

useEffect(() => {
  const fetchData = async () => {
    try {
      let servicesResponse, categoriesResponse;

      // Always fetch services and categories
      [servicesResponse, categoriesResponse] = await Promise.all([
        axios.get(`https://creen-program.com/api/services`),
        axios.get(`https://creen-program.com/api/categories`, {})
      ]);
      setdata(servicesResponse.data.data);
      setcatigories(categoriesResponse.data.data);

      if (!token) return;

      switch (tab) {
        case "الاشتراكات":
          const subscriptionsResponse = await axios.get(`https://creen-program.com/api/services/subscriptions`, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          });
          setsubscriptions(subscriptionsResponse.data.data);
          setda(subscriptionsResponse.data.data.usubscriptions);
          setdat(subscriptionsResponse.data.data.esubscriptions);
          break;

        case "خدماتي":
          if (!21) return; // Assuming you have a userId state
          const numReservationsResponse = await axios.get(`https://creen-program.com/api/services/patients/${21}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setNum(numReservationsResponse.data.data.count);

          const myServicesResponse = await axios.get(`https://creen-program.com/api/services/my`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setservice(myServicesResponse.data.data.trainers);
          break;

        default:
          break;
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [tab, token]);

const overref = useRef(null);
const close = (e) => {
  if (overref.current && !overref.current.contains(e.target)) {
    setshowservices(false)
    document.body.style.overflow = "auto"


  }
}
useEffect(() => {
  document.addEventListener("mousedown", close)
  return () => {
    document.removeEventListener("mousedown", close)
  }
}, [])  

  return (
    
    <>
<Headermob/>
   < section style={{ width: "100%",height:`100%`,minHeight:"100vh",display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>

        <div className="services" style={{    position:"relative",background: "#fff",padding:"10px 0px",height:"100%",minHeight:"100vh",width:"100%",paddingBottom:"30px"}}>
        <div className="navigation" style={{display:"flex",justifyContent:"space-around",background: "#fff",
    paddingTop: "10px",
    marginTop: "-9px"}}>
            <span className={ `${tab==='خدماتي'?'services_active': ''} `} onClick={()=>settab("خدماتي")}>خدماتي</span>
            <span className={ `${tab==='الاشتراكات'?'services_active': ''} `} onClick={()=>settab("الاشتراكات")}>الاشتراكات</span>
            <span className={ `${tab==='الخدمات'?'services_active': ''} `} onClick={()=>settab("الخدمات")}>الخدمات</span>
        </div>

        <div className="services-content" style={{marginTop:"10px",position:"relative",height:"100%",minHeight:"100vh"}}>
          {
            tab === "الخدمات" ? 
            <div className="services-services" style={{display:"grid",gridTemplateColumns:"repeat(3,1fr)",rowGap:"45px"}} >
              {
                data.map((item,index)=>(
                  <Link to={`/services/${item.id}`} key={index} style={{textDecoration:"none"}}>
                    <div  className="service-service" style={{display:"flex",flexDirection:"column",alignItems:"center",position:"relative"}}>
                <img src={item.icon} alt="" style={{    width: "95px",
    height: "95px",
    borderRadius: "50%",
    }}/>
                <span style={{    padding: "5px 15px",
    background: "#ddd",
    color: "#585555",
    borderRadius: "5px",
    position: "absolute",
    bottom: "-25px",
    fontSize:"12px"}}>{item.name}</span>
              </div>
                  </Link>

                ))

              }
              
              
            </div>
            :tab==="الاشتراكات"?
            token?
            <>
            {
            da.length===0 && dat.length===0?<p style={{    color: "#000",
                fontSize: "24px",
                height: "64vh",
                textAlign: "center"}}> لا يوجد اشتراكات</p>:<div className="Contributions" style={{display:"flex",gap:"5px",justifyContent:"space-around",alignItems:"flex-start"}}>
              <div className="Expiredsubscriptions" style={{
   }}>
              {
                subscriptions.esubscriptions.map((item,index)=>(
                  <div className="Expiredsubscription" style={{    width: "290px",
    textAlign: "center",
    border: "1px solid rgb(127 125 125)",
    padding: "7px",
    borderRadius: "5px",
    margin: "auto",height:"160px",background:"#fff"}}>
              <button style={{border: "1px solid #80808014",
    background: "rgb(214 206 206 / 20%)",
    width: "65%",
    borderRadius: "16px",
    marginBottom: "5px",
    color: "red"}} >اشتراك منتهي</button >
    <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"11px"}}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column"}}>
        <span style={{    fontSize: "14px",
    fontWeight: "bold",
        color: "rgb(102 99 99)"}}> {item.trainer.name}</span>
        <span style={{    fontSize: "14px",
    fontWeight: "bold",
        color: "rgb(102 99 99)"}}>{item.trainer.joptitle}<span>{item.trainer.category.name}</span></span>
        <ReactStars
  count={5} 
  value={5}
  size={17}
  color={'#ffd700'} />
      </div>
      <img src={item.trainer.user.profile?item.trainer.user.profile:Usericon} alt="" style={{width:"50px",height:"50px",borderRadius:"50%"}} />
    </div>
    <span style={{fontSize: "14px",
    fontWeight: "bold",
        color: "rgb(102 99 99)"}}>هل ترغب تجديد الإشتراك</span>
    <div className="buttons-subscription" style={{    display: "flex",
    justifyContent: "space-around",
    gap: "5px"}}>
      <button style={    {border: "none",
    width: "100%",
        background: "#c60a0a",
    borderRadius: "7px",
    color: "#fff"}}>No</button>
      <button style={    {border: "none",
    width: "100%",
        background: "rgb(4 118 4)",
    borderRadius: "7px",
    color: "#fff"}}>Ok</button>
    </div>

              </div>
                ))
              }
              
              </div>
              <div className="Validsubscriptions" style={{display:"flex",flexDirection:"column",gap:"10px"}}>
              {
                subscriptions.usubscriptions.map((item,index)=>(
                  <div className="Validsubscription" style={{    width: "290px",
    textAlign: "center",
    border: "1px solid rgb(127 125 125)",
    padding: "7px",
    borderRadius: "5px",
    margin: "auto",height:"205px",background:"#fff"}}>
              <button style={{border: "1px solid green",
    background: "#7d7d7d24",
    width: "65%",
    borderRadius: "16px",
    marginBottom: "5px",
    color: "rgb(10 149 10)"
    }} >اشتراك ساري</button >
    <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"11px"}}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column"}}>
        <span style={{    fontSize: "14px",
    fontWeight: "bold",
        color: "rgb(102 99 99)",textAlign:"end"}}> {item.trainer.name}</span>
        <span style={{    fontSize: "14px",
    fontWeight: "bold",
        color: "rgb(102 99 99)"}}>{item.trainer.joptitle} <span>{item.trainer.category.name}</span></span>
        <ReactStars
  count={5} 
  value={5}
  size={17}
  color={'#ffd700'} />
      </div>
      <img src={item.trainer.user.profile?item.trainer.user.profile:Usericon} alt="" style={{width:"50px",height:"50px",borderRadius:"50%"}} />
    </div>
    <div className="data" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "end",
    gap: "6px"}}>
      <span className='Dateofsubscription' style={{    display: "flex",
    gap: "6px",
    color: "rgb(88 86 86)",
    fontWeight: "bold",
    fontSize: "14px"}}><span style={{direction:"rtl"}}>{item.created_at}</span><span>تاريخ الإشتراك</span></span>
      <span className='ExpiryDate'  style={{    display: "flex",
    gap: "6px",
    color: "rgb(88 86 86)",
    fontWeight: "bold",
    fontSize: "14px"}} ><span>11.03.2024</span><span>ينتهي بتاريخ</span></span>
        <button style={{    border: "none",
    padding: "6px",
    background: "red",
    color: "#fff",
    borderRadius: "4px"}}>الغاء الاشتراك</button>

    </div>
              </div>
                ))
              }
              
              </div>
             
             
              
              
            </div>
            }
            </>:""
            
            
            :tab==="خدماتي"?
   token?
   service.length===0?
   <>
      {
       showservices&&
       
       <div style={{display: "flex",
       flexDirection: "column",
       justifyContent: "center",
       alignItems: "center",
       gap: "10px"}}>
               <select required name="" id="" value={category_id} style={{ width: "90%" }} onChange={handlechangecategoryid}>
                         <option value="أختر"> أختر الاقسام</option>
                         {
                           categories.map((item) => (
     
                             <option value={item.id}>{item.name}</option>
                           ))
                         }
                       </select>
                       <div className="gender form-check" style={{    display: "flex",
       marginTop: "15px",
       justifyContent: "center",
       gap: "16px"}}>
                         <div className="male" style={{display: "flex",
       alignItems: "center",
       gap: "5px"}}><label htmlFor="">ذكر</label>
                         <input type="radio" name='gender'  onClick={()=>{setgender("male")}} />
                         </div>
                         <div className="male" style={{display: "flex",
       alignItems: "center",
       gap: "5px"}}><label htmlFor="">انثي</label>
                         <input type="radio" name='gender'  onClick={()=>{setgender("female")}} />
                         </div>
                       </div>
                       <input type="text" placeholder='الاسم' onChange={(e)=>{setname(e.target.value)}}/>
                       <input type="text" placeholder='الجنسية' onChange={(e)=>{setnationality(e.target.value)}} />
     
                       <input type="text" placeholder='الدولة' onChange={(e)=>{setcity(e.target.value)}} />
                       <input type="text" placeholder='المؤهل التعليمي' onChange={(e)=>{seteducation(e.target.value)}} />
                       <input type="text" placeholder='المسمي الوظيفي' onChange={(e)=>{setjoptitle(e.target.value)}} />
                       <input type="text" placeholder='رقم الهوية' onChange={(e)=>{setnationalid(e.target.value)}} />
                       <input type="text" placeholder='رقم الجوال' onChange={(e)=>{setphone(e.target.value)}} />
                       <input type="text" placeholder='الأيميل' onChange={(e)=>{setemail(e.target.value)}} />
     
     <input type="text" placeholder='صور الشهادات والوثائق' onChange={handleFileChange} />
      
     <input type="text" placeholder='وصف الخدمة' onChange={(e)=>{setdescription(e.target.value)}} />
     <button style={{    border: "none",
       padding: "10px",
       width: "50%",
       background: "#0ce80c",
       color: "white",
       borderRadius: "9px"}} onClick={()=>{handleUpload()}}>تقديم الخدمة</button>
                   
               </div>
     
     }        
     <Link className='add-my' style={{ display:"flex" , textDecoration:"none", position: "sticky",
top: "465px",
 left: "25px",
 zIndex: "2000000",
 background: "#000",
 width: "50px",
 height: "50px",
 textAlign: "center",
 justifyContent: "center",
 alignItems: "center",
 borderRadius: "50%",
 color: "#fff"}} to={"https://creen-program.com/services/new"}><i class="ri-add-line"></i></Link></>:
   <>

<>
{
  service.map((service,index)=>(
    
   <div style={{    display: "flex",
   flexDirection: "column",
   alignItems: "end",
   gap: "5px",borderBottom:"1px solid #ddd",padding:"0px 7px"}}>
        <div className="heaa" style={{    display: "flex",
   justifyContent: "space-between",
   width: "100%",alignItems:"center"}}>
          <Link to={`https://creen-program.com/services/edit/${service.id}`} style={{background: "transparent",textDecoration:"none",
   border: "none",
   color: "green",textAlign:"justify"}}>تعديل</Link>
          <span>{service.joptitle}</span>
          </div>
          <span>{service.category.name}</span>
          <span style={{display:"flex"}}><span>منذ 3 أسابيع</span>:<span>تاريخ التسجيل</span></span>
          <span style={{display:"flex",alignItems:"center",gap:"5px",marginTop:"5px"}}><span><Link style={{marginRight: "5px",
   border: "1px solid green",
   background: "transparent",
   padding: "6px",
   borderRadius: "25px",width:"100%",textDecoration:"none",color:"#000"}} to={service.category.id===5?`/Participants`:`https://creen-program.com/services/allusers`} >شاهد</Link></span>{service.category.id===5?num:/*nump*/0}<span></span>:<span>{service.category.id===5?"عدد الحجوزات":"عدد المشتركين"}</span></span>
          <div style={{width:"100%",color:"green"}}><span>مفعل</span></div>
      </div>
  ))
}
  

   

</>

    <>
 {/* {
  showservices&&
  
  <div ref={overref} style={{display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px"}}>
          <select required name="" id="" value={category_id} style={{ width: "90%" }} onChange={handlechangecategoryid}>
                    <option value="أختر"> أختر الاقسام</option>
                    {
                      categories.map((item) => (

                        <option value={item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                  <div className="gender form-check" style={{    display: "flex",
  marginTop: "15px",
  justifyContent: "center",
  gap: "16px"}}>
                    <div className="male" style={{display: "flex",
  alignItems: "center",
  gap: "5px"}}><label htmlFor="">ذكر</label>
                    <input type="radio" name='gender'  onClick={()=>{setgender("male")}} />
                    </div>
                    <div className="male" style={{display: "flex",
  alignItems: "center",
  gap: "5px"}}><label htmlFor="">انثي</label>
                    <input type="radio" name='gender'  onClick={()=>{setgender("female")}} />
                    </div>
                  </div>
                  <input type="text" placeholder='الاسم' onChange={(e)=>{setname(e.target.value)}}/>
                  <input type="text" placeholder='الجنسية' onChange={(e)=>{setnationality(e.target.value)}} />

                  <input type="text" placeholder='الدولة' onChange={(e)=>{setcity(e.target.value)}} />
                  <input type="text" placeholder='المؤهل التعليمي' onChange={(e)=>{seteducation(e.target.value)}} />
                  <input type="text" placeholder='المسمي الوظيفي' onChange={(e)=>{setjoptitle(e.target.value)}} />
                  <input type="text" placeholder='رقم الهوية' onChange={(e)=>{setnationalid(e.target.value)}} />
                  <input type="text" placeholder='رقم الجوال' onChange={(e)=>{setphone(e.target.value)}} />
                  <input type="text" placeholder='الأيميل' onChange={(e)=>{setemail(e.target.value)}} />

<input type="text" placeholder='صور الشهادات والوثائق' onChange={handleFileChange} />
 
<input type="text" placeholder='وصف الخدمة' onChange={(e)=>{setdescription(e.target.value)}} />
<button style={{    border: "none",
  padding: "10px",
  width: "50%",
  background: "rgb(6 43 52)",
  color: "white",
  borderRadius: "9px"}} onClick={()=>{handleUpload()}}>تقديم الخدمة</button>
              
          </div>

}         */}
    <Link className='add-my' style={{ display:"flex" , textDecoration:"none", position: "sticky",
top: "465px",
left: "25px",
zIndex: "2000000",
background: "#000",
width: "50px",
height: "50px",
textAlign: "center",
justifyContent: "center",
alignItems: "center",
borderRadius: "50%",
color: "#fff"}} to={"https://creen-program.com/services/new"}><i class="ri-add-line"></i></Link></>
   </>
 

   :
  <>
   </>
     

   :<></>
          }

    
        </div>
    </div>
          

        </section>
        <LinksHome />

      </section>
    <Footer/>
    
    
    </>
  )
}

export default Services