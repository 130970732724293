import React,{useEffect,useState } from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
// import Header from '../components/Header/Header'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import ReactStars from 'react-stars'
import Usericon from "../assets/images/user-icon.png"
import { toast } from 'react-toastify'
import Headermob from "../components/headermobile/Headermob"
import Footer from '../components/footer/Footer'

const Appointment = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
const token=Cookiesservices.get('jwt')

const [type,settype]=useState()
const[data,setdata]=useState()
const{id}=useParams()
const [Data,setData]=useState([])
const navigate=useNavigate()
const [user , setuser]=useState([])


const [reservation , setreservation]=useState([]);

  useEffect(()=>{
    const fetchdetailsdoctor=async()=>{
        const response=await axios.get(`https://creen-program.com/api/services/trainer/show/${id}`,{headers: {
    Authorization: `Bearer ${token}`,
  
  },
})
setData(response.data.data.trainer)
setuser(response.data.data.trainer.user)

//response.status===200?toast.success("added")&&navigate("/doctordetails"):toast.error("error")
        
    }
    fetchdetailsdoctor()
},[token, id])
const[isRequesting,setIsRequesting]=useState(false)
const addreservation = async () => {
  try {
    // التحقق مما إذا كان هناك طلب آخر قيد التنفيذ
    if (!isRequesting) {
      setIsRequesting(true); // تعيين قيمة true لتشير إلى أن الطلب قيد التنفيذ الآن

      const response = await axios.post(
        `https://creen-program.com/api/doctors/reservations/add?type=${type}&date=${data}&doctor=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Added");
        navigate(`/doctordetails/${id}`);
      } else {
        toast.error("Data invalid");
      }
    } else {
      console.log("Another request is already in progress.");
    }
  } catch (error) {
    if (error.response && error.response.status === 429) {
      toast.error("Too many requests. Please try again later.");
    } else {
      console.error("Error adding reservation:", error);
      toast.error("An error occurred while adding reservation.");
    }
  } finally {
    setIsRequesting(false); // إعادة تعيين قيمة الطلب بعد الانتهاء سواء نجح الطلب أو فشل
  }
};

 
  return (
    <>
  <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>
<div className="appointment" style={{width:"100%",height:"100vh"}}>
<div style={{borderBottom:"1px solid #ddd"}}>
<Link style={{textDecoration:"none",color:"#000"}} /*to={`/trainer/${data.id}`}*/>
        <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"35px",paddingTop:"20px",paddingRight:"10px"}}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column",alignItems:"end"}}>
        <span style={{    fontSize: "23px",
        color: "rgb(102 99 99)"}}>{Data.name}</span>
        <span style={{    fontSize: "24px",
        color: "rgb(102 99 99)"}}>{Data.joptitle}</span>
        <ReactStars
  count={5} 
value={user.num_of_rating?user.num_of_rating:0}
  size={32}
  color={'#ffd700'} />
      </div>
      <img src={user.profile!==""?user.profile:Usericon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
    </div>
    <h3 style={{textAlign:"end",paddingRight:"13px"}}>{Data.hospital}</h3>
        </Link>
</div>
<div className="appoint" style={{padding:"0 10px"}}>
    <div className="one" style={{display:"flex",justifyContent:"end",gap:"5px",alignItems:"center"}}>
        <label htmlFor="">حجز موعد في العيادة</label>
        <input type="radio" name='موعد' onClick={()=>settype("ofline")} />
    </div>
    <div className="two" style={{display:"flex",justifyContent:"end",gap:"5px",alignItems:"center"}}>
        <label htmlFor="">حجز موعد اونلاين</label>
        <input type="radio" name='موعد' onClick={()=>settype("online")} />
    </div>
    <div className="three" style={{display:"flex",justifyContent:"end",gap:"5px",alignItems:"center"}}>
        <label htmlFor="">استشارة سريعه</label>
        <input type="radio"name='موعد' onClick={()=>settype("quick")} />
    </div>
    <div className="three">
       <input style={{    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    outline: "1px solid green",
    border: "1px solid green",marginTop:"10px"}} onChange={(e)=>setdata(e.currentTarget.value)} type="datetime-local" />
    </div>
</div>
<div className="bt" style={{    textAlign: "center",
    marginTop: "10px",
    marginRight: "5px"}}>
<button style={{    border: "none",
    background: "#08c808",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",width:"27%"}} onClick={addreservation}>موافق</button>

</div>
</div>

        

      </section>
      <LinksHome />

    </section>
   <Footer/>

  </>  
  )
}

export default Appointment