import React, {  useState ,useEffect}  from 'react'
import { Link } from 'react-router-dom'
import "../styles/products.css"
import 'swiper/css';
import 'swiper/css/navigation';
import ProductsCard from '../components/Productcard/ProductsCard';
import Headermob from '../components/headermobile/Headermob';
import Footer from "../components/footer/Footer"
const Products = () => {
  
   

      const [acc,setacc]=useState("")  ;
      localStorage.setItem('acc',acc)
      useEffect(() => {
        window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
      }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  return (

    <>
 <Headermob/>
    <section className='products_and_ads'>
        {/* <div className="products_left" style={{display: "none",
    flexDirection: "column",
    gap: "5px"}}>
        {
            promotions.map((promotion , index)=>(
                <div className="box-ads" key={index}>
                  {promotion.image != null ?
                  <img src={promotion.image.url} alt="" />:""}
                  {
                    promotion.video != null?<iframe id="ytplayer" title='youtube' width="100%" height="150"
                    src={promotion.video.url} frameborder="0"></iframe>:""
                  }
                  <div className="profile-ads">
                    <span style={{fontSize: "8px",fontWeight: "bold"}}>{promotion.user.name}</span>
                    <img src={promotion.user.profile} alt="" />
                  </div>
                  {
                    promotion.text!=null?<p style={{    textAlign: "end",
                        color: "#000",lineBreak: "anywhere",lineHeight: "1.6"}}>{promotion.text.content}</p>:""
                  }
                  <div className="city">
                  <Link to={promotion.link} style={{textDecoration:"none",color:" #c0d2bc"}  }> <button>تواصل</button></Link> 
                    <span className='allcity'><span>{promotion.user.city.name}</span>/<span>{promotion.user.city.country.name}</span></span>
                  </div>
                </div>
            ))
        }
    </div> */}
        <div className="products_center">
            {/* <div className="products_center_head">
                <Link>أحدث المنتجات</Link>
                <Link>أكثر المبيعات</Link>
                <Link>أخترنا لك</Link>
                <Link>العروض</Link>
                <Link>منتجات المزاد</Link>
            </div> */}
            <div className="ads" style={{    marginTop: "10px",
    display: "none",flexDirection:"column",gap:"10px"}}>
                <div style={{  display:"none",width: "100%"}}>
                    {
                        //data.map((item , index)=>(
                          //  <Link key={index}><img src={item.image} alt="" style={{height:"400px",borderRadius:"10px"}} /></Link>
                        //))
                    }
                </div>
                <div style={{    display: "none",justifyContent: "space-between",gap: "10px"}}>
                <div style={{    background: "red",height: "100px",width: "20%"}}></div>
                <div style={{    background: "red",height: "100px",width: "20%"}}></div>
                <div style={{    background: "red",height: "100px",width: "20%"}}></div>
                <div style={{    background: "red",height: "100px",width: "20%"}}></div>
                <div style={{    background: "red",height: "100px",width: "20%"}}></div>
                </div>
            </div>
            <div className="slider sli-pro" style={{marginTop:"15px",display:"flex",justifyContent:"space-around",
    position: "sticky",
    top: "70px",
    background: "#fff",
    padding: "13px 30px",
    zIndex: "999999999999999999",width:"390px",margin:"auto"}}>
              <Link to={"/categorychildren/146"} style={{textDecoration:"none",color:"#000"}}><span className={`${acc==="ملابس"?"services_active":""}`} style={{display:"flex",alignItems:"center",justifyContent:"center" ,width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}}onClick={()=>{setacc('ملابس')}}>ملابس</span></Link>
              <Link to={"/categorychildren/123"} style={{textDecoration:"none",color:"#000"}}>              
              <span  className={`${acc==="مكملات"?"services_active":""}`} style={{ display:"flex",alignItems:"center",justifyContent:"center",width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}} onClick={()=>{setacc('مكملات')}}>مكملات</span>
              </Link>
              <Link to={"/categorychildren/108"} style={{textDecoration:"none",color:"#000"}}>
              <span className={`${acc==="مطاعم"?"services_active":""}`} style={{ display:"flex",alignItems:"center",justifyContent:"center",width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}} onClick={()=>{setacc('مطاعم')}}>مطاعم</span>
              </Link>
              <Link to={"/categorychildren/148"} style={{textDecoration:"none",color:"#000"}}>
              <span className={`${acc==="أجهزه"?"services_active":""}`} style={{ display:"flex",alignItems:"center",justifyContent:"center",width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}} onClick={()=>{setacc('أجهزة')}}>أجهزة</span>

              </Link>
            </div>
            <ProductsCard/>
           
            
        </div>
        {/* <div className="products_right" style={{display: "none",
    flexDirection: "column",
    gap: "5px"}}>
            {
            promotions.map((promotion , index)=>(
                <div className="box-ads" key={index}>
                  {promotion.image != null ?
                  <img src={promotion.image.url} alt="" />:""}
                  {
                    promotion.video != null?<iframe id="ytplayer" title='youtube' width="100%" height="150"
                    src={promotion.video.url} frameborder="0"></iframe>:""
                  }
                  <div className="profile-ads">
                    <span style={{fontSize: "8px",fontWeight: "bold"}}>{promotion.user.name}</span>
                    <img src={promotion.user.profile} alt="" />
                  </div>
                  {
                    promotion.text!=null?<p style={{    textAlign: "end",
                        color: "#000",lineBreak: "anywhere",lineHeight: "1.6"}}>{promotion.text.content}</p>:""
                  }
                  <div className="city">
                  <Link to={promotion.link} style={{textDecoration:"none",color:" #c0d2bc"}  }> <button>تواصل</button></Link> 
                    <span className='allcity'><span>{promotion.user.city.name}</span>/<span>{promotion.user.city.country.name}</span></span>
                  </div>
                </div>
            ))
        }
        </div> */}
    </section>
    
    <Footer/>
    </>
  )
}

export default Products