import axios from 'axios';
import React, { useEffect , useState } from 'react'
import { useParams } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices';
import { Link } from 'react-router-dom';
import Headermob from "../components/headermobile/Headermob"
import User_icon from "../assets/images/user-icon.png"
import axiosRetry from 'axios-retry';



const Categorychildren = () => {
  const {id}=useParams();
  const token=Cookiesservices.get('jwt')
  const avv=localStorage.getItem('acc')
  const [data, setData] = useState([]);
  const [acc,setacc]=useState(avv)  ;
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون

  // useEffect(() => {
  //   const fetchData = async () => {
  //     axiosRetry(axios, {
  //       retries: 5, // عدد المحاولات
  //       retryDelay: axiosRetry.exponentialDelay, // تأخير بين المحاولات
  //       shouldResetTimeout: true, // إعادة تعيين الفترة الزمنية بين المحاولات
  //       retryCondition: (error) => {
  //         // يتم إعادة المحاولة فقط إذا كان الخطأ 429
  //         return error.response && error.response.status === 429;
  //       },
  //     });
  //     try {
  //       const response = await axios.post(
  //         `https://creen-program.com/api/products/category?category_id=${id}`,
  //         {}, // لا حاجة لتمرير البيانات هنا
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`, // تصحيح الاسم
  //           },
  //         }
  //       );
  //       setData(response.data.data.products.data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, [id, token]); // تمرير الاعتمادات كتابةً على الأرجح لمنع إعادة الاشتراك في التأثير
  const user_id=localStorage.getItem('user_id')
  const[profilehome,setprofilehome]=useState([])
    // useEffect(()=>{
    //   const fetchdatauser=async()=>{
    //       const respose=await axios.get(`https://creen-program.com/api/users/${user_id}`,{
    //           headers:{
    //               Authorization:`Bearer ${token}`
    //           }
    //       })
    //       setprofilehome(respose.data.data)
    //   }
    //   fetchdatauser()
    // },[user_id, token]
    // )
    useEffect(() => {
      axiosRetry(axios, {
        retries: 5,
        retryDelay: axiosRetry.exponentialDelay,
        shouldResetTimeout: true,
        retryCondition: (error) => {
          return error.response && error.response.status === 429;
        },
      });
    
      const fetchData = async () => {
        try {
          const response = await axios.post(
            `https://creen-program.com/api/products/category?category_id=${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setData(response.data.data.products.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      const fetchUserProfile = async () => {
        try {
          const response = await axios.get(`https://creen-program.com/api/users/${user_id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setprofilehome(response.data.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
    
      fetchData();
      fetchUserProfile();
    }, [id, token, user_id]); 
    
  return (
<>
<Headermob/>
<>
<div className="slider sli-pro" style={{marginTop:"15px",display:"flex",justifyContent:"space-around",
            position: "sticky",
            top: "70px",
            background: "#fff",
            padding: "13px 30px",
            zIndex: "999999999999999999",width:"390px",margin:"auto"}}>
                      <Link to={"/categorychildren/146"} style={{textDecoration:"none",color:"#000"}}><span className={`${acc==="ملابس"?"services_active":""}`} style={{display:"flex",alignItems:"center",justifyContent:"center" ,width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}}onClick={()=>{setacc('ملابس')}}>ملابس</span></Link>
                      <Link to={"/categorychildren/123"} style={{textDecoration:"none",color:"#000"}}>              
                      <span  className={`${acc==="مكملات"?"services_active":""}`} style={{ display:"flex",alignItems:"center",justifyContent:"center",width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}} onClick={()=>{setacc('مكملات')}}>مكملات</span>
                      </Link>
                      <Link to={"/categorychildren/108"} style={{textDecoration:"none",color:"#000"}}>
                      <span className={`${acc==="مطاعم"?"services_active":""}`} style={{ display:"flex",alignItems:"center",justifyContent:"center",width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}} onClick={()=>{setacc('مطاعم')}}>مطاعم</span>
                      </Link>
                      <Link to={"/categorychildren/148"} style={{textDecoration:"none",color:"#000"}}>
                      <span className={`${acc==="أجهزة"?"services_active":""}`} style={{ display:"flex",alignItems:"center",justifyContent:"center",width: "75px",height: "40px",background: "rgb(197 193 229 / 24%)",borderRadius:"4px"}} onClick={()=>{setacc('أجهزة')}}>أجهزة</span>
        
                      </Link>
                    </div>
                    <div className='myproducts' style={{position:"relative",height:"100%",minHeight:"100vh",marginTop:"20px"}}>

       {
        data.length == 0? <span style={{fontSize: "40px",
          fontWeight: "bold",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "50%"}}>لا يوجد منتجات</span>:data.map((item,index)=>(
            <>
            
          <div className="myproduct" key={index} style={{    width: "200px",
          height: "300px",
          borderRadius: "10px",
          padding: "5px"}}>
                  <div className="img_myproducts">
                      <img  src={item.image.url} alt="" />
                  </div>
                  <div  className="description" ><p>{item.description}</p></div>
                  
      <div class="price" style={{display: "flex", justifyContent: "end", alignItems: "center"}}><span>ريال سعودي</span><span style={{fontSize: "larger"}}> {item.price}</span></div>   
      <Link style={{textDecoration:"none"}}><button>شاهد المنتج</button></Link>         
      </div>
            </>
      ))
       }
        


    </div>
</>

    <div class="footer-mobile" style={{
        background: "#fff",
        border: "none",
        height: "48px",
        position: "sticky",
        zIndex: "9999",
        bottom: "0", display: "none"
      }}>
        <div class="container">
          <ul style={{
            paddingLeft: "0", display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          
          }}>
                 <Link to={`${token ?`/user/${user_id}`:`/login`}`}>
            <img src={profilehome.profile!==null?profilehome.profile:User_icon} alt="" style={{ width: "40px", height: "40px", cursor: "pointer",borderRadius:"50%" }} />
</Link>   

<Link style={{textDecoration:"none"}} to={"/products"}>
              <li>
                        <span><i style={{fontSize:"1.9rem",cursor:"pointer",color:"#bcbcbc"}} class="ri-shopping-bag-line"></i></span>
                </li></Link>            <Link to={"/live"}>
          <li style={{    background: "#ddd",
    padding: "5px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center"}}>
              <img src="https://creen-program.com/new/images/m1.png" style={{ width: "1.9rem" }} alt="" />
            </li> 
          </Link>
              <li>
              <Link to={"/towatch"} style={{ textDecoration: "none", color: "#fff" }} > <i style={{
                fontSize: "1.7rem",
                color: "#fff",
                cursor: "pointer",
                borderRadius: "10px",
                width: "40px",
                height: "40px",
                textAlign: "center",
                padding: "2px 8px 2px 8px",
                background: "#7d7d7d",
              }} class="ri-play-line"></i></Link>
            </li>
            <Link to={"/home"}  style={{textDecoration:"none",color:"#7d7d7d"}} >                <i  style={{fontSize:"1.9rem",cursor:"pointer",color:"#7d7d7d"}} class="ri-home-4-line"></i>
</Link>          </ul>
        </div>
      </div>
</>
    )
}

export default Categorychildren