import React, { useState , useEffect,useRef } from 'react'
import ReactStars from 'react-stars'
import Usericon from '../assets/images/user-icon.png'
import { /*useNavigate*/ useParams } from 'react-router-dom'
// import Header from '../components/Header/Header'
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import { Link } from 'react-router-dom'
//import { background } from '@chakra-ui/react'
import "../styles/trainer.css"
import Headermob from "../components/headermobile/Headermob"
import Footer from '../components/footer/Footer'
const Trainer = () => {
    const [tab , settab]=useState('')
    const [active , setactive]=useState('');
    localStorage.setItem('tab',tab)
    const {id}=useParams();
    const[data,setdata]=useState([])
    const[Data,setData]=useState([])
    const token=Cookiesservices.get('jwt')
    useEffect(() => {
      const fetchService = async () => {
          try {
              const response = await axios.get(`https://creen-program.com/api/services/trainer/show/${id}`);
              setdata(response.data.data.trainer);
          } catch (error) {
              console.error('Error fetching service:', error);
          }
      };
      const fetchServicee = async () => {
          try {
              const response = await axios.post(`https://creen-program.com/api/services/showservice?trainer_id=${id}`,{},{
                headers:{
                    Authorization:`Bearer ${token}`
                }
              });
              setData(response.data.data);
          } catch (error) {
              console.error('Error fetching service:', error);
          }
      };
  
      fetchService();
      fetchServicee()
  }, [id, token]);
  const [serviceid,setserviceid]=useState()
  useEffect(()=>{
    if(Data && tab){
      Data.filter((item)=>(tab === item.service ?setserviceid(item.id)  :""))

    }
  },[Data,tab])
  
  const [moree,setmoree]=useState(false)
  const more=()=>{
    setmoree(!moree)
  }
 
  // const navigate=useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  const targetRef = useRef(null);
  const taregetelement=document.getElementById("element")
console.log()
  const scrollToTarget = () => {
    if(taregetelement){
        const offsetTop = taregetelement.offsetTop;
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
          });
    }
    
  };

    return (
   <>
   <Headermob/>   
< section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>

        {
        data.length===0?"":
    
          <div className="trainer" style={{background:"#fff",width:"100%"}}>
          <Link style={{textDecoration:"none",color:"#000"}} to={`/trainer/${data.id}`}>
          <div className="info-user-subs" style={{    display: "flex",
      justifyContent: "flex-end",    gap: "24px",
      marginTop: "20px",
      paddingRight: "15px"}}>
        <div className="information" style={{    display: "flex",
      flexDirection: "column",alignItems:"end"}}>
          <span style={{    fontSize: "23px",
          color: "rgb(102 99 99)"}}>{data.name}</span>
          <span style={{    fontSize: "24px",
          color: "rgb(102 99 99)"}}>{data.joptitle}</span>
          <ReactStars
    count={5} 
  value={data.user.num_of_rating}
    size={32}
    color={'#ffd700'} />
        </div>
        <img src={data.user.profile?data.user.profile:Usericon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
      </div>
          </Link>
      <div className="content" style={{    display: "flex",
      flexDirection: "column",paddingRight: "13px",
      paddingLeft: "18px"}}>
          <p style={{    textAlign: "end",
      color: "#231f1f",
      height:  `${moree?"100%":"97px"}` ,
      overflow: "hidden"}}>{data.description}</p>
          <span style={{direction: "rtl",
      color: "green",
      marginTop: "-15px",
      cursor: "pointer"}} onClick={more}>{moree?"اقل":"المزيد..."}</span>
      </div>
      <div className="packges" style={{    textAlign: "center",
      marginRight: "9px",
      marginTop: "16px"}}>
          <p style={{display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      color: "#000",
      fontWeight: "bold",
      gap: "5px"}}>اشتري باقتك المفضلة<span style={{background: "red",
          padding: "10px",
          color: "white",
          borderRadius: "2px",
          marginTop: "-13px"}}>الان</span></p>
          
          <p style={{color: "#000",
      fontWeight: "bold",
      background: "yellow",
      borderRadius: "5px",
      width: "75%",
      display: "flex",
      justifyContent: "center",
      margin: "auto"}}>الباقات الشهرية المميزة</p>
      </div>
      <div className="packges-buttons"  style={{marginTop: "10px",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row-reverse",
      marginRight:"20px",gap:"20px",flexWrap:"wrap"}}>
        {
          Data.length !==0?  
          Data.map((item , index)=>(
        
               <div>
                <button key={index} onClick={()=>{settab(`${item.service}`);setactive(`${item.service}`);scrollToTarget()}} style={{display: "flex",
      flexDirection: "column",
      background: "transparent",
      border: "none",
      alignItems: "center",
      width: "100%"}}>
              <span>{item.service}</span>
              <span style={{ background: "orange",
      padding: "0px 20px",
      borderRadius: "5px",
      width: "60%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"}}>{item.price}</span>
          </button>
          <div key={index} onClick={()=>{settab(`${item.service}`);setactive(`${item.service}`);scrollToTarget()}} style={{display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2px"}}>
                      <span style={{    direction: "rtl"}}>{item.size}</span>
                      <img className='fivekilo' src={item.img} alt="" style={{    width:"90px",
          height: `${tab===`${item.service}`?"120px":"100px"}`,
          background: "#000",
          borderRadius: "5px",
          border:`${`${item.service}`?"2px solid":""}`}} />
                  </div>
               </div>
               
               

            )):""
        }
        
         
              
      </div>
      {
            Data.map((item,index)=>(
                <div key={index} className="packages-content" id="element"  style={{textAlign: "end",
               marginRight: "17px",
               marginTop: "7px",paddingBottom:"1px"}} >
               {
                   tab === `${item.service}`? <div className='excellence'>
                      
                   <div className="excellence-content"  ref={targetRef}>
                      <p style={{    color: "rgb(0, 0, 0)",lineBreak:"anywhere"}}>{item.description}</p>
                                              
                   </div>
               
               </div>:<></>
               }
                  
                   <div></div>
                   <div></div>
               </div>
            ))
        }
          
       
      
      <div className="subscriptionall" style={{display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      /*padding: "10px"*/
      background: "bisque",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      padding: "22px 0px 77px 0px"
  }}>
          <div className="buttons-all" style={{    display: "flex",
      flexDirection: "column",
      alignItems: "end",marginRight:"20px"}}>
          {
            Data.map((item,index)=>(
                <div key={index} className="one" onClick={()=>{setactive(`${item.service}`);settab(`${item.service}`)}} style={{    display: "flex",
      justifyContent: "center",
      flexDirection: "row-reverse",
      gap: "10px",
      background: `${active===`${item.service}`?"#fff":""}`,
      borderRadius: "10px",
      padding: "10px 10px"}}>
              <span style={{
                      border: "1px solid #000",
                      width: "25px",
                      height: "25px",
                      background: `${active===`${item.service}`?"red":""}`,
                      borderRadius: "50%",
                      textAlign: "center",margin:"auto"
              }}><span><i style={{color:`${active===`${item.service}`?"#fff":"transparent"}`}} class="ri-check-line"></i></span></span>
              <p style={{    display: "flex",
      gap: "5px",
      color: "#000",margin:"auto",    fontSize:"20px",ontWeight: `${active===`${item.service}`?"bold":""}`}}><span style={{    display: "flex",
          flexDirection: "row-reverse",
          gap: "5px"}}>{item.price}<span>ريال سعودي</span></span><span style={{direction:"rtl"}}>اشتراك {item.service} </span></p>
          </div>
            ))
          }
         
          </div>
          <button style={{    background: "red",
      border: "none",
      width: "80%",
      padding: "10px",
      borderRadius: "25px",
      color: "#fff",marginTop:"10px"}}>
        <Link to={token?`https://creen-program.com/payment/add/${serviceid}/${id}`:`/login`} style={{textDecoration:"none",color:"#fff"}}>
        استمرار
   
         </Link>
       </button>
          
             </div>
      </div>
        
          
        
      
    }
          

        </section>
        <LinksHome />

      </section>
     
   

      <Footer/>
    
   </>
  )
}

export default Trainer