import React , {useEffect , useState,useRef} from 'react'
// import Header from '../components/Header/Header'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
//import User_icon from "../assets/images/user-icon.png"
//import { toast } from 'react-toastify'
import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
import logo from "../assets/images/ic_launcher.png"
import iconchat from "../assets/images/icon-removebg-preview.png"
import user_icon from "../assets/images/user-icon.png"
import { Link } from 'react-router-dom'


const Mytopics = () => {
    const[data,setData]=useState([])
    const [page, setPage] = useState(1);
   const token=Cookiesservices.get("jwt")
   const user_id = (localStorage.getItem('user_id'));
   /*const[isclicked , setisclicked]=useState(false)
  const [like , setlike]=useState(null)
  const [post_id , setpost_id]=useState("")
  const [post_id_comment , setpost_id_comment]=useState("")
  const[comment , setcomment]=useState("")*/
  
  
/*const fetchcomment=async(post_id_comment)=>{
    setpost_id_comment(post_id_comment)
  
    try {
      if(post_id_comment ){
        const response = await axios.post(
          `https://creen-program.com/api/comments/create?blog_id=${post_id_comment}&comment=${comment}&user_id=${user_id}`,
          {},
          {
            headers: {
              Authorization:`Bearer ${token}`,
            },
          }
        );
      
        
    console.log(response)
    if( response.status === 200){
      setpost_id_comment("")
      toast.success("created comment successfully")
      window.location.reload()
    }
    
      }
        } catch (error) {
          if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            console.error('Status code:', error.response.status);
          } else if (error.request) {
            console.error('No response received from the server');
          } else {
            console.error('Error setting up the request:', error.message);
          }
        }
       
      
  }*/
/*const handlelikepost=async(post_id)=>{
    setpost_id(post_id)
    try {
  if(post_id && user_id ){
    const response = await axios.post(
      `https://creen-program.com/api/likes/create?like=${like}&blog_id=${post_id}&user_id=${user_id}`,
      {},
      {
        headers: {
          Authorization:` Bearer ${token}`,
        },
      }
    );
  
    
    
    
  
    // Update like count and clicked state based on the response
    setlike((prev)=>prev + like + (isclicked ? response.data.data  :  response.data.data ++ ));  setisclicked(!isclicked);
    console.log(response)
    
    
  
  
  }
    } catch (error) {
      if (error.response) {
        console.error('Server responded with an error:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        console.error('No response received from the server');
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }
  }
  const handelchangecomment=(e)=>{
    if(e.target.value){
      setcomment(e.target.value)
    }else{
      setcomment("")
    }
  }
  */
    useEffect(()=>{
        const handlemytopics=async()=>{
      try{
        const response=await axios.post(`https://creen-program.com/api/videos/user?user_id=${user_id}`,{},{

          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'accept': 'application/json',
        Authorization:`Bearer ${token}`
        },
      
        })
        if(response.status === 200){
          const response=await axios.post(`https://creen-program.com/api/videos/user?user_id=${user_id}`,{},{
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
              'accept': 'application/json',
              Authorization:`Bearer ${token}`,

          },
        
          })
          setData((prevData) => [...prevData, response.data.data.data]);
          
        }
        
      }catch(error){}
      
        }
        handlemytopics()
      },[page, token, user_id])
      const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
        ) {
            setPage((prevPage) => prevPage + 1);
        }
      };
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      const [tab,settab]=useState('main')
      const menuRef=useRef(null);
      const menuRef_mobile=useRef(null);
      const handle_logout=()=>{
        Cookiesservices.remove('jwt')
        localStorage.clear()
        toast.success("You have successfully logged out");
      }
      const menuToggle=()=>{
        menuRef.current.classList.toggle('active_menu')
      }
      const menuToggle_mobile=()=>{
        menuRef_mobile.current.classList.toggle('active_menu')
      }
  return (
    <>
<header className='header_mobile   to-watch-mobile' style={{display:"none"}} >
      
      <div className="nav_wrapper">
        <div className="icons">
        <div className='profile'> <i class="ri-search-line" style={{fontSize:"1.7rem",color:"#fff"}}></i>  
        </div>
        <span><img src={iconchat} alt="" style={{width: "50px",
    height: "50px",
    borderRadius: "50%",
    color: "#fff"}} /></span>
        <Link to={"/home"}><div className="logo"><motion.img whileTap={{scale:1.2}} src={ /*currentUser? currentUser.photoURL :*/ logo}  alt="" /*onClick={toggleProfileActions}*/ /></div></Link>

        <Link><span ><i class="ri-notification-line"></i></span></Link>
        <Link  className='icon_mobile'><span><i class="ri-function-line"></i></span></Link>


        </div>
        
        

        
      </div>
      <div className='user_nouser' style={{display:"none"}} ref={menuRef_mobile} onClick={menuToggle_mobile} >
       {
        token?<div className="current_user">
          <Link to={"/my_topics"}>موضوعاتي</Link>
          <Link to={"/my_videos"}>فيديوهاتي</Link>
          <Link to="/products">المتجر</Link> 
          <Link to={"/cart"}>السله</Link>
          <Link to={"/services"}>الخدمات</Link>
          
          <Link to='/wallet' style={{display:"flex",alignItems:"center",gap:"10px"}}>محفظتي</Link>
          <Link to="/login" onClick={handle_logout} style={{display:"flex",alignItems:"center",gap:"10px",flexDirection:"row-reverse"}}><span><i class="ri-shut-down-line"></i></span>تسجيل الخروج</Link>
               
        </div>: <div className="no_user">
          <Link to='/signup'>التسجيل</Link>
          <Link to='/login'>تسجيل الدخول</Link>
        </div>
       }
      </div>
  
</header>
<header className='header'>
  
      <div className="nav_wrapper">
        <div className="icons_left">
        <div className='profile'> <motion.img whileTap={{scale:1.2}}  onClick={menuToggle} src={ /*currentUser? currentUser.photoURL :*/ user_icon}  alt="" /*onClick={toggleProfileActions}*/ />

        
        </div>        <Link style={{textDecoration:"none",color:"#fff"}} to={"/towatch"} ><i class="ri-live-line"></i></Link>
        <span><i class="ri-message-2-line"></i></span>
       <Link style={{textDecoration:"none",color:"#fff"}} to={"/live"}><i class="ri-play-circle-line"></i></Link>
        </div>
        
        
        <div class="search_box">
          <input type="text" placeholder="إبحث عن أعضاء, #هاشتاغ" />
          <span><i class="ri-search-line"></i></span>
          </div>
        
        <div className="icons_right">
          <Link><span className={ `${tab==='add'?'active_tab': ''}  add`} onClick={()=>settab('add')}  ><span className='establishment'>إنشاء</span><i class="ri-add-circle-line"></i></span></Link>
          <Link><span className={ `${tab==='main'?'active_tab': ''}  main`} onClick={()=>settab('main')}><span className='home_page'>الصفحه الرئيسية </span><i class="ri-mail-open-line"></i></span></Link>
          <Link><span ><i class="ri-notification-line"></i></span></Link>
          <Link style={{display:'none'}} className='icon_mobile'><span><i class="ri-align-justify"></i></span></Link>
          <Link to={"/home"}><div className="logo"><motion.img whileTap={{scale:1.2}} src={ /*currentUser? currentUser.photoURL :*/ logo}  alt="" /*onClick={toggleProfileActions}*/ /></div></Link>
          
        </div>
      </div>
      <div className='user_nouser' style={{display:"none"}} ref={menuRef} onClick={menuToggle} >
       {
        token?<div className="current_user">
          <Link to={"/my_topics"}>موضوعاتي</Link>
          <Link to={"/my_videos"}>فيديوهاتي</Link>

          <Link to="/products">المتجر</Link>
          <Link to={"/cart"}>السله</Link>
          <Link to={"/services"}>الخدمات</Link>

          <Link to='/wallet' style={{display:"flex",alignItems:"center",gap:"10px"}}>محفظتي</Link>
          <Link to="/login" onClick={handle_logout} style={{display:"flex",alignItems:"center",gap:"10px",flexDirection:"row-reverse"}}><span><i class="ri-shut-down-line"></i></span>تسجيل الخروج</Link>
        </div>: <div className="no_user">
          <Link to='/signup'>التسجيل</Link>
          <Link to='/login'>تسجيل الدخول</Link>
        </div>
       }
      </div>
  
</header>    < section style={{width:"100%",display:'flex',justifyContent:"space-between"}}>
    <ChatHome/>
      <section className='center' style={{width:"59%",display:"flex",justifyContent:"space-between",gap:"5px"}}>
      
     
      <div className="posts" style={{display:"flex",flexDirection:"column",gap:"10px"}}>
      <h2 style={{textAlign: "end",
    background: "#fff",
    padding: "10px",
    borderRadius: "10px",
    marginTop: "7px",width:"100%"}}>فيديوهاتي</h2>
                          {
                
                            data.length === 0 ?<h2 style={{textAlign:"center",fontWeight:"bolder"}}>No Videos!</h2>:data.length > 0 ?
      <h2>No Videos!</h2>                      :<></>
                          }
                        </div>
                       
      </section>
      <LinksHome/>
     
    </section>
    </>
  )
}

export default Mytopics