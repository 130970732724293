import React,{useEffect,useState,useCallback} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
import { Link, useParams } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import ReactStars from 'react-stars'
import Usericon from "../assets/images/user-icon.png"
import dataa from "../assets/images/schedule.png"
import { toast } from 'react-toastify'
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"

const Doctordetails = () => {
const token=Cookiesservices.get('jwt')
const {id}=useParams();
  
  const [show , setshow]=useState(false)
  const showmodal=()=>{
    setshow(!show)
  }
  const [Data,setData]=useState([])
const [user , setuser]=useState([])
const [reservation , setreservation]=useState([]);

  const [dayy , setday]=useState()
  const d = new Date(reservation.date);
const time=d.toLocaleTimeString('en-us',{hours:false})
  let day=d.getDay();
//   const formattime=time.toLocaleTimeString('en-us',{hours:false})
  const timee=(time.includes("AM")?"صباحا":"مساء")


 
 const data=localStorage.getItem("day");
//  console.log(reservation.date)
// console.log(timee,reservation.date)
const [editreservation,seteditreservation]=useState()
const ediereservation=async()=>{
  const response=await axios.post(`https://creen-program.com/api/doctors/reservations/edit?type=${reservation.type}&date=${editreservation}&reservation_id=${reservation.id}`,{},{headers: {
    Authorization: `Bearer ${token}`,
  
  },
})

response.status===200?toast.success("تم تغيير الموعد")&&setshow(!show):response.status===400? toast.error("data invalid"):<></>



}
useEffect(() => {
  window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
}, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
const fetchData = useCallback(async () => {
  try {
    const [doctorDetailsResponse, reservationResponse] = await Promise.all([
      axios.get(`https://creen-program.com/api/services/trainer/show/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      }),
      axios.get(`https://creen-program.com/api/doctors/details/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
    ]);
    setData(doctorDetailsResponse.data.data.trainer);
    setuser(doctorDetailsResponse.data.data.trainer.user);
    setreservation(reservationResponse.data.data.reservition);
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle errors here
  }
}, [id, token]);
useEffect(() => {
  

  fetchData();

}, [ fetchData]);

useEffect(()=>{
  
  setday(day === 0 ? "يوم الاحد" : day === 1 ? "يوم الاثنين" : day === 2 ? "يوم الثلاثاء" : day === 3 ? "يوم الاربعاء" : day === 4 ? "يوم الخميس" : day === 5 ? "يوم الجمعه" : day === 6 ? "السبت" : "");
  localStorage.setItem("day", dayy);
},[day,dayy])
  return (
    <>
   <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>
<div className="appointment" style={{width:"100%",height:"100vh",position:"relative"}}>
<div style={{borderBottom:"1px solid #ddd"}}>
<Link style={{textDecoration:"none",color:"#000"}} to={`/trainer/${id}`}>
        <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"35px",    marginTop: "20px",paddingRight:"13px"  }}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column",alignItems:"end"}}>
        <span style={{    fontSize: "23px",
        color: "rgb(102 99 99)"}}>{Data.name}</span>
        <span style={{    fontSize: "24px",
        color: "rgb(102 99 99)"}}>{Data.joptitle}</span>
        <ReactStars
  count={5} 
 value={user.num_of_rating}
  size={32}
  color={'#ffd700'} />
      </div>
      <img src={user.profile?user.profile:Usericon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
    </div>
    <h3 style={{textAlign:"end" , paddingRight:"13px"}}>{Data.hospital}</h3>
        </Link>
</div>
<div style={{marginTop:"10px",display:"flex",flexDirection:"row-reverse",justifyContent:"space-around",    alignItems: "end"}}>
<div className="time" style={{    textAlign: "end",    background: "#02da02",
    borderRadius: "5px",
    width: "70%",
    padding: "5px"}}>
    <h4 style={{fontSize:"20px" , color:"#fff",textAlign:"end"}}><span >تم حجز موعد</span>/<span style={{color:"#000"}}>{reservation.type==="ofline"?" في العيادة":reservation.type==="online"?" اونلاين":"استشارة سريعة"}</span></h4>
    <h4 style={{fontSize:"20px",color:"#fff",textAlign:"end"}}>{data}</h4>
    <h4 style={{    display: "flex",
    justifyContent: "end",
    fontSize: "17px",color:"#fff",gap:"4px"}}> <span style={{display:"flex",gap:"5px"}}><span style={{display:"flex",flexDirection:"row-reverse"}}><span>{timee}</span></span><span style={{    direction: "rtl"    }}>{reservation.date}</span><span></span></span>تاريخ</h4>
</div>
<div className="data" style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
    <img src={dataa} alt="" style={{width:"50px"}}/>
    <span style={{    border: "none",
    padding: "10px",
    borderRadius: "5px",
    background: "#05cc05",
    color: "#fff",fontSize:"12px"}} onClick={showmodal}>تغيير الموعد</span>
</div>
{
    show&&<div className="choose" style={{position: "absolute",
    background: "#fff",
    padding: "10px",
    borderRadius: "5px",
    width: "80%"}}>
    <h3 style={{textAlign:"center"}}>اختر الموعد المناسب</h3>
    <input type="datetime-local" onChange={(e)=>{seteditreservation(e.currentTarget.value)}} style={{    width: "100%",
    padding: "10px",
    border: "1px solid green",
    outline: "1px solid green",
    marginTop: "5px",
    marginBottom: "10px"}} />
    <div className="btnss" style={{display: "flex",
    justifyContent: "center",
    paddingBottom: "5px",
    gap: "7px"}}>
        <button style={{    border: "none",
    padding: "10px",
    borderRadius: "5px",
    color: "#fff",
    background: "#06b006"}} onClick={ediereservation}>تغيير</button>
        <button style={{    border: "none",
    padding: "10px",
    borderRadius: "5px",
    color: "#fff",
    background: "#6c757d"}} onClick={()=>{setshow(false)}}>الغاء</button>
    </div>
</div>
}
</div>
</div>

        

      </section>
      <LinksHome />

    </section>
    <Footer/>

  </>  
  )
}

export default Doctordetails