import React ,{useEffect , useState} from 'react'
// import Header from '../components/Header/Header'
import { Link } from 'react-router-dom'
import { useDispatch ,  useSelector } from 'react-redux'
import { cartActions } from '../redux/slices/cartSlice'
import { motion } from 'framer-motion'
import Headermob from '../components/headermobile/Headermob'
import User_icon from "../assets/images/user-icon.png"
import axios from 'axios'
import Cookiesservices from '../Services/Cookiesservices'
import "../styles/Cart.css"
const Cart = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  const cartItems=useSelector(state=>state.cart.cartItems)
  const totalAmount=useSelector(state=>state.cart.totalAmount) 
  const user_id=localStorage.getItem('user_id')
  const[profilehome,setprofilehome]=useState([])
  const token=Cookiesservices.get('jwt')
 
  
  const fetchDataUser = (user_id, token) => {
    return new Promise((resolve, reject) => {
      axios.get(`https://creen-program.com/api/users/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 429) {
          console.error('خطأ في الطلبات المتكررة:', error);
          resolve(null);
        } else {
          reject(error);
        }
      });
    });
  };

  useEffect(() => {
    const fetchUserProfile = () => {
      fetchDataUser(user_id, token)
        .then(userProfile => {
          if (userProfile !== null) {
            setprofilehome(userProfile);
          }
        })
        .catch(error => {
          console.error('حدث خطأ في جلب ملف المستخدم:', error);
        });
    };

    if (user_id && token) {
      fetchUserProfile();
    }
  }, [user_id, token]);


   return (
<>
<Headermob/>

<div className='Cart-mobile' style={{display:"flex",background:"#fff",height:"100%",minHeight:"100vh",padding:"0px 20px"}}>{
  cartItems.length === 0?   <p style={{    color: "#000",
  fontSize: "x-large",width:"80%",textAlign:"center",marginTop:"10px"}}>لم يتم إضافة أي عنصر إلى العربة</p>:
  
  
    <div className="cart" style={{marginTop:"10px",background:"#fff",height:"100%",width:"100%"}}>
  <div className="container" style={{display: "flex",
    justifyContent: "center",
    alignItems: "center"}}>
    <div className="productss" style={{    width: "80%",
    textAlign: "center"}}>
      <table className='table bordered'>
       <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                 {
                  cartItems.map((item , index)=>(
                    <Tr item={item} key={index} />
                  ))
                 }
                                 </tbody>
              </table>

    
    </div>
   
  </div>
</div>


}
<div className="price" style={{ marginTop:"10px",   width: "20%"}}>
    <div>
                <h6  style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                  الاجمالي 
                <span  style={{    fontWeight: "bold",
    fontSize: "calc(1.275rem + 0.3vw) !important"}}>sar{totalAmount}</span>
                </h6>
              </div>
              <p style={{marginTop:"5px"}}>سيتم احتساب الضرائب وتكاليف الشحن في عملية الدفع</p>
              <div>
              <button style={{width: "100%",
    background: "#1e2321",
    border: "none",
    outline: "none",
    padding: "8px 20px",
    borderRadius: "5px",
    cursor:"pointer",
    fontSize: "1rem",
    marginTop: "40px"}}><Link style={{color:"#fff",textDecoration:"none"}} to={''}>اتمام الشراء</Link></button>
                <button style={{width: "100%",
    background: "#1e2321",
    border: "none",
    outline: "none",
    padding: "8px 20px",
    borderRadius: "5px",
    cursor:"pointer",
    fontSize: "1rem",
    marginTop: "40px"}}><Link style={{color:"#fff",textDecoration:"none"}} to={'/products'}>متابعة التسوق</Link></button>
              </div>
    </div>
</div>
<div class="footer-mobile" style={{
        background: "#fff",
        border: "none",
        height: "48px",
        position: "sticky",
        zIndex: "9999",
        bottom: "0", display: "none"
      }}>
        <div class="container">
          <ul style={{
            paddingLeft: "0", display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          
          }}>
                 <Link to={`${token ?`/user/${user_id}`:`/login`}`}>
            <img src={profilehome.profile!==null?profilehome.profile:User_icon} alt="" style={{ width: "40px", height: "40px", cursor: "pointer",borderRadius:"50%" }} />
</Link>   

<Link style={{textDecoration:"none"}} to={"/products"}>
              <li>
                        <span><i style={{fontSize:"1.9rem",cursor:"pointer",color:"#bcbcbc"}} class="ri-shopping-bag-line"></i></span>
                </li></Link>
            <Link to={"/live"}>
          <li style={{    background: "#ddd",
    padding: "5px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center"}}>
              <img src="https://creen-program.com/new/images/m1.png" style={{ width: "1.9rem" }} alt="" />
            </li> 
          </Link>
              <li>
              <Link to={"/towatch"} style={{ textDecoration: "none", color: "#fff" }} > <i style={{
                fontSize: "1.7rem",
                color: "#fff",
                cursor: "pointer",
                borderRadius: "10px",
                width: "40px",
                height: "40px",
                textAlign: "center",
                padding: "2px 8px 2px 8px",
                background: "#7d7d7d",
              }} class="ri-play-line"></i></Link>
            </li>
            <Link to={"/home"}  style={{textDecoration:"none",color:"#7d7d7d"}} >                <i  style={{fontSize:"1.9rem",cursor:"pointer",color:"#7d7d7d"}} class="ri-home-4-line"></i>
</Link>          </ul>
        </div>
      </div>
</>
  )
}
const Tr=({item})=>{
  const dispatch = useDispatch()
  const deleteProduct=()=>{
    dispatch(cartActions.deleteItem(item.id))
  }
 return <tr>
                    <td><img src={item.imgUrl} style={{    width: "50px",
    height: "50px",
    borderRadius: "5px"}} alt="" /></td>
                    <td>{item.productName}</td>
                    <td>sar{item.price}</td>
                    <td>{item.quantity}px</td>
                    <td><motion.i style={{cursor:"pointer"}} whileTap={{scale:1.2}} onClick={deleteProduct} class="ri-delete-bin-line"></motion.i></td>
                  </tr>


}
export default Cart