import React, { useEffect, useState } from 'react'
import body from "../assets/images/body-removebg-preview.png"
import Headermob from "../components/headermobile/Headermob"
import { toast } from 'react-toastify'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Footer from "../components/footer/Footer"

const Poll = () => {
    const user_name=localStorage.getItem("user_name")
   const token=Cookiesservices.get('jwt')
        const tab=localStorage.getItem('tab')
        const [time , settime]=useState(new Date())
        const navigate=useNavigate()
        useEffect(()=>{
            const intervel=setInterval(()=>{
                settime(new Date() )

            },1000)
            return ()=>clearInterval(intervel)
        })
        const formattime=time.toLocaleTimeString('en-us',{hours:false})
  
 const [age , setage]=useState("");
 const [weight , setweight]=useState("");
 const [height , setheight]=useState("");
 const [gender , setgender]=useState("")
 const [fitness , setfitness]=useState("")
 const [numberoftime,setnumberoftime]=useState("")
 const [why,setwhy]=useState("")
 const [plan , setplan]=useState("")
 const [via , setvia]=useState("")
 const [food , setfood]=useState("")
 const [sporttype , setsporttype]=useState("")
 const [sportenhance,setsportenhance]=useState("")
 const [cigarettes,setcigarettes]=useState("")
 const [medicalrestrictions,setmedicalrestrictions]=useState("");
 const [diet,setdiet]=useState("")
 const [supplements,setsupplements]=useState("")
 const [favorite , setfavorite]=useState("")
 const [bullying , setbullying]=useState("")
 const [fat , setfat]=useState("")
 const [family , setfamily]=useState("")
 const [annoy , setannoy]=useState("")
 const [expectlose,setexpectlose]=useState("")
 const [wightorsize,setwightorsize]=useState("");
 const [trainername,settrainername]=useState("");
 const [happiness,sethappiness]=useState("");
 const [subscription,setsubscription]=useState("");
 const [recommendation,setrecommendation]=useState("");
 const [shoulders,setshoulders]=useState("");
 const [chest , setchest]=useState("");
 const [belly,setbelly]=useState("");
 const [waist , setwaist]=useState("");
 const [arm , setarm]=useState("");
 const [buttocks , setbuttocks]=useState("");
 const [thighs , setthighs]=useState("");
 const [clup , setclup]=useState("");
 const [losing , setlosing]=useState("");
 const [result , setresult]=useState("");
 const {trainer_id}=useParams();

const [selectedValues, setSelectedValues] = useState([]);

  const handlechangecheck = (checkboxName) => {
    // تحقق مما إذا كانت القيمة مختارة بالفعل
    const isValueSelected = selectedValues.includes(checkboxName);

    // إذا كانت مختارة، قم بإزالتها، وإلا، قم بإضافتها
    const updatedValues = isValueSelected
      ? selectedValues.filter((value) => value !== checkboxName)
      : [...selectedValues, checkboxName];

    // حفظ القيم المحدثة في الـ state
    setSelectedValues(updatedValues);

    // الآن يمكنك إرسال selectedValues إلى الـ API أو استخدامها بأي شكل آخر
    // console.log('Selected values:', updatedValues);
  };
  const upload = async () => {
    const formatdata = new FormData();
    
    formatdata.append('weight', weight);
    formatdata.append('height', height);
    formatdata.append('why', why);
    formatdata.append('plan', plan);
    formatdata.append('via', via);
    formatdata.append('specialfood', food);
    formatdata.append('age', age);
    formatdata.append('gender', gender);
    formatdata.append('fitness', fitness);
    formatdata.append('numberoftime', numberoftime);
    formatdata.append('sport_enhanse', sportenhance);
    formatdata.append('sports_type', sporttype);
    formatdata.append('cigarettes', cigarettes);
    formatdata.append('medical_restrictions', medicalrestrictions);
    formatdata.append('work_with_diet_trainer', diet);
    formatdata.append('food_supplements', supplements);
    formatdata.append('favourite_sport', favorite);
    formatdata.append('bullying', bullying);
    formatdata.append('buttocks', buttocks);
    formatdata.append('fat', fat);
    formatdata.append('family', family);
    formatdata.append('annoy', annoy);
    formatdata.append('expect_to_lose', expectlose);
    formatdata.append('weight_or_size', wightorsize);
    formatdata.append('trainer_name', trainername);
    formatdata.append('happiness', happiness);
    formatdata.append('subscription', subscription);
    formatdata.append('recommendation', recommendation);
    formatdata.append('shoulders', shoulders);
    formatdata.append('chest', chest);
    formatdata.append('waist', waist);
    formatdata.append('belly', belly);
    formatdata.append('arm', arm);
    formatdata.append('thighs', thighs);
    formatdata.append("diseases", selectedValues);
    formatdata.append("tried_losing_weight", losing);
    formatdata.append("club_or_personal_effort", clup);
    formatdata.append("result", result);
    formatdata.append("trainer_id",trainer_id)
    const response = await axios.post(`https://creen-program.com/api/form`, formatdata, {
        headers: { Authorization: `Bearer ${token}` }
    });
if(response.status===200){
    // toast.success("تم تسجيل الاستبيان بنجاح")
    window.location.href = response.data.data;
}
}
  
//   useEffect(() => {
//     window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
//   }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
//   console.log(document.querySelector(".كتف").textContent=`${59*"."}`)
//   document.querySelector(".كتف").textContent=`${ "." * "59"}`
    const  ktf=document.getElementsByClassName(".كتف")
    const  sd=document.getElementsByClassName(".صدر")
    const  bt=document.getElementsByClassName(".بطن")
    const  kh=document.getElementsByClassName(".خصر")
    const  zr=document.getElementsByClassName(".ذراع")
    const  ar=document.getElementsByClassName(".اردف")
    const  fkh=document.getElementsByClassName(".فخذين")
        ktf.textContent=".".repeat(shoulders/4)
 sd.textContent=".".repeat(chest/4)
    bt.textContent=".".repeat(waist/4)
    kh.textContent=".".repeat(belly/4)
    zr.textContent=".".repeat(arm/4)
    ar.textContent=".".repeat(buttocks/4)
    fkh.textContent=".".repeat(thighs/4)
  return (
   <>
   <Headermob/>
<div className='poll' style={{background:"#fff",paddingTop:"16px"}}>
        <div className="information-user" style={{    textAlign: "center"}}>
        <div className="title">
            <h1 style={{    fontSize: "24px",direction: "rtl"}}>مرحبا {user_name}</h1>
        </div>
        {/* <div className="sub-title" style={{    width: "max-content",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    background: "red",
    padding: "5px",
    borderRadius: "5px",
    textAlign: "center",
    color: "#fff"}}><h2 style={{    fontSize: "22px"    }}>Creen Clinic</h2></div> */}
        <div className="hours"><h3 style={{ marginTop: "5px",fontSize: "30px"}}>{formattime}</h3></div>

        </div>
        <div className="alert" style={{textAlign:"center"}}>
            <p style={{color:"#fff",fontWeight:"bold"}}>نموذج - إستبيان حول اللياقة البدنية</p>
            <p style={{color:"red",fontWeight:"bold"}}>عزيزي العميل يرجى تعبئة النموذج علما ان هذا النموذج سيحدد احتياجك وطريقة الوصول إلى النتيجة </p>
        </div>
        <form action="post" onSubmit={(e)=>{e.preventDefault()}} style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"}}>
            <input type="text" placeholder='العمر' onChange={(e)=>{setage(e.currentTarget.value)}} style={{background:"#cbcbcb4f",width:"335px"}} />
                        <div style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center"
                        }}>
                        <input type="text" onChange={(e)=>{setheight(e.currentTarget.value)}} placeholder='الطول' style={{background:"#cbcbcb4f",width:"43%"}} />
            <input type="text" onChange={(e)=>{setweight(e.currentTarget.value)}} placeholder='الوزن' style={{background:"#cbcbcb4f",width:"43%"}} />

                        </div>
                        <div className="gender" style={{    display: "flex",
    flexDirection: "row-reverse",gap: "32px"}}>
                            <p style={{    color: "#000",
    fontSize: "20px",
    position: "relative",
    marginBottom:"0px"}}>الجنس</p>
                           <div style={{display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "9px"}}>
                           <div className="male" style={{display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row-reverse",
    gap: "15px"
}}><input type="radio" onClick={()=>{setgender("male")}}  name='gender' style={{width:"20px",height:"20px"}} />
                           <label htmlFor="" style={{    fontSize: "20px"}}>ذكر</label></div>
                            
                            <div className="female" style={{display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row-reverse",
    gap: "15px"
}}>
                                <input  onClick={()=>{setgender("female")}} value={"female"}  type="radio" name='gender' style={{width:"20px",height:"20px"}} />
                                <label htmlFor="" style={{fontSize:"20px"}}>أنثي</label>
                            </div>
   
                           </div>
                                                </div>
          {
            tab==='التميز'? 
             <div className="questions" style={{    display: "flex",
            flexDirection: "column",
            gap: "13px",paddingRight:"20px"}}>
                    <div className="one-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س1. ما هو مستوى لياقتك البدنية الحالي؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input type="radio" style={{width: "20px",height: "20px"}}   name='fit'  onClick={()=>{setfitness("ممتاز")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>ممتاز</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"   name='fit'  onClick={()=>{setfitness("جيد جدا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جيد جدا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='fit'  onClick={()=>{setfitness("جيد ")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جيد</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='fit'  onClick={()=>{setfitness("مقبول")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مقبول</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='fit'  onClick={()=>{setfitness("غير ملائم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>غير ملائم</label>
           </div>
                                   
                        
                        
                    </div>
                    <div className="two-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س2. كم عدد المرات التي تمارس بها الألعاب الرياضية؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number' onClick={()=>{setnumberoftime("كل يوم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>كل يوم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number'  onClick={()=>{setnumberoftime("يوم بعد يوم")}} />
                        <label htmlFor="" style={{    fontSize: "20px"}}>يوم بعد يوم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number' onClick={()=>{setnumberoftime("مرتين في الاسبوع")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مرتين في الاسبوع</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number' onClick={()=>{setnumberoftime("يوم في الاسبوع")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>يوم في الاسبوع</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setnumberoftime("بضع مرات فالشهر")}}  name='number' />
                        <label htmlFor="" style={{    fontSize: "20px"}}>بضع مرات فالشهر</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setnumberoftime("غير محدد")}}  name='number' />
                        <label htmlFor="" style={{    fontSize: "20px"}}>غير محدد</label>
           </div>
                                   
                        
                        
                    </div>
                    <div className="three-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س3. لماذا تمارس الرياضة؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='why' onClick={()=>{setwhy("لانه جزء من عملي")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لانه جزء من عملي</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}}type="radio"  name='why' onClick={()=>{setwhy("انا استمتع به")}} />
                        <label htmlFor="" style={{    fontSize: "20px"}}>انا استمتع بها</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='why' onClick={()=>{setwhy("اريد فقد الوزن")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>اريد فقدان بعد الوزن</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",width:"100%"}}> 
            <p style={{color:"red",fontWeight:"bold"}}> غير ذلك ... يرجي التحديد</p>
            <input type="text" placeholder='لماذا تمارس الرياضة'onChange={(e)=>{setwhy(e.currentTarget.value)}}  />
           </div>
                 
                                   
                        
                        
                    </div>
                    <div className="four-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س4. هل قمت بعمل خطة للتمارين الرياضية؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setplan(1)}} name='plan'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setplan(0)}} name='plan'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
        <p style={{  display:`${plan===1?"block":"none"}`,  color: "red",
            fontWeight: "bold",
            position: "relative",
            right: "50%",
            transform: "translateX(50%)"}}>عن طريق</p>
            <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setvia("اندية")}} name='via'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>أنديه</label>
           </div>
                        <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setvia("اجتهاد شخصي")}} name='via'/>
                        <label htmlFor="" style={{    fontSize: "20px"}}>اجتهاد شخصي</label>
           </div>
                        <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setvia("برامج موجوده في النت")}} name='via'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>برامج موجودة في النت</label>
           </div>
           <p style={{  display:`${plan===1?"block":"none"}` , color: "red",
            fontWeight: "bold",
            position: "relative",
            right: "50%",
            transform: "translateX(50%)"}}>هل استخدمت أطعمة خاصة</p>
            <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}}  type="radio" onClick={()=>{setfood("نعم")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("لا")}} name='food' />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من النادي")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من النادي</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من مطعم مختص")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من مطعم مختص</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من وصفات المدربين")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من وصفات المدربين</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من النت")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من النت</label>
           </div>
                    </div>
                    <div className="five-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س5. هل حضرت أي من الألعاب الرياضية المعروضة أدناه؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>غير منتظم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جري</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>سباحه</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>ركوب دراجة</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>تزلج</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>رياضات عنيفة</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>فنون قتالية</label>
           </div>
                                   
                        
                        
                    </div>
                    <div className="six-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س6. كيف تقوم بمعظم تمارينك الرياضية المعتادة؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("وحدي")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>وحدي</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("مع مدرب")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مع مدرب</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("مع مجموعة")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مع مجموعة</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("في النادي")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>في النادي</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("علي النت")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>علي النت</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("موقع")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>موقع</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("جروب")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جروب</label>
           </div>
                       
                                   
                        
                        
                    </div>
                    <div className="seven-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س7. هل تحاول تحسين آدائك الرياضي؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='sportenhance' onClick={()=>{setsportenhance(1)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='sportenhance' onClick={()=>{setsportenhance(0)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="eight-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س8. هل تدخن السجائر؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='cigarettes' onClick={()=>{setcigarettes(1)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='cigarettes' onClick={()=>{setcigarettes(0)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="nine-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س9. هل لديك أي قيود طبية تمنعك من ممارسة الرياضة؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='medicalsport' onClick={()=>{setmedicalrestrictions("نعم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='medicalsport' onClick={()=>{setmedicalrestrictions("لا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
           <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",width:"100%"}}> 
            <p style={{color:"red",fontWeight:"bold"}}> غير ذلك ... يرجي التحديد</p>
            <input type="text" placeholder='هل لديك أي قيود طبية تمنعك من ممارسة الرياضة؟' onChange={(e)=>{setmedicalrestrictions(e.currentTarget.value)}}  />
           </div>
           
                      
                                   
                        
                        
                    </div>
                    <div className="ten-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س10. هل تعمل مع المدربين لتناول نظام غذائي متوازن لتعزيز حالتك؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='workwithdiettrainer' onClick={()=>{setdiet(1)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='workwithdiettrainer' onClick={()=>{setdiet(0)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="eleven-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س11. هل تستهلك المكملات الغذائية للألعاب الرياضية؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='supplements' onClick={()=>{setsupplements("نعم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='supplements' onClick={()=>{setsupplements("لا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='supplements' onClick={()=>{setsupplements("احيانا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>احيانا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="twinty-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س12. هل تشارك بنشاط وبشكل منتظم في الرياضة المفضلة لديك؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='favoritesport' onClick={()=>{setfavorite("نعم")}} />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='favoritesport' onClick={()=>{setfavorite("لا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='favoritesport' onClick={()=>{setfavorite("احيانا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>احيانا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    
        
        </div>:
        <div className="questions" style={{    display: "flex",
        flexDirection: "column",
        gap: "13px",paddingRight:"20px"}}>
         <div className="one-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س1. ما هو مستوى لياقتك البدنية الحالي؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input type="radio" style={{width: "20px",height: "20px"}}   name='fit'  onClick={()=>{setfitness("ممتاز")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>ممتاز</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"   name='fit'  onClick={()=>{setfitness("جيد جدا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جيد جدا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='fit'  onClick={()=>{setfitness("جيد ")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جيد</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='fit'  onClick={()=>{setfitness("مقبول")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مقبول</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='fit'  onClick={()=>{setfitness("غير ملائم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>غير ملائم</label>
           </div>
                                   
                        
                        
                    </div>
                    <div className="two-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س2. كم عدد المرات التي تمارس بها الألعاب الرياضية؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number' onClick={()=>{setnumberoftime("كل يوم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>كل يوم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number'  onClick={()=>{setnumberoftime("يوم بعد يوم")}} />
                        <label htmlFor="" style={{    fontSize: "20px"}}>يوم بعد يوم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number' onClick={()=>{setnumberoftime("مرتين في الاسبوع")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مرتين في الاسبوع</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='number' onClick={()=>{setnumberoftime("يوم في الاسبوع")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>يوم في الاسبوع</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setnumberoftime("بضع مرات فالشهر")}}  name='number' />
                        <label htmlFor="" style={{    fontSize: "20px"}}>بضع مرات فالشهر</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setnumberoftime("غير محدد")}}  name='number' />
                        <label htmlFor="" style={{    fontSize: "20px"}}>غير محدد</label>
           </div>
                                   
                        
                        
                    </div>
                    <div className="three-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س3. لماذا تمارس الرياضة؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='why' onClick={()=>{setwhy("لانه جزء من عملي")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لانه جزء من عملي</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}}type="radio"  name='why' onClick={()=>{setwhy("انا استمتع به")}} />
                        <label htmlFor="" style={{    fontSize: "20px"}}>انا استمتع بها</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='why' onClick={()=>{setwhy("اريد فقد الوزن")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>اريد فقدان بعد الوزن</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",width:"100%"}}> 
            <p style={{color:"red",fontWeight:"bold"}}> غير ذلك ... يرجي التحديد</p>
            <input type="text" placeholder='لماذا تمارس الرياضة'onChange={(e)=>{setwhy(e.currentTarget.value)}}  />
           </div>
                 
                                   
                        
                        
                    </div>
                    <div className="four-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س4. هل قمت بعمل خطة للتمارين الرياضية؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setplan(1)}} name='plan'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setplan(0)}} name='plan'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
        <p style={{  display:`${plan===1?"block":"none"}`,  color: "red",
            fontWeight: "bold",
            position: "relative",
            right: "50%",
            transform: "translateX(50%)"}}>عن طريق</p>
            <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setvia("اندية")}} name='via'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>أنديه</label>
           </div>
                        <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setvia("اجتهاد شخصي")}} name='via'/>
                        <label htmlFor="" style={{    fontSize: "20px"}}>اجتهاد شخصي</label>
           </div>
                        <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setvia("برامج موجوده في النت")}} name='via'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>برامج موجودة في النت</label>
           </div>
           <p style={{  display:`${plan===1?"block":"none"}` , color: "red",
            fontWeight: "bold",
            position: "relative",
            right: "50%",
            transform: "translateX(50%)"}}>هل استخدمت أطعمة خاصة</p>
            <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}}  type="radio" onClick={()=>{setfood("نعم")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("لا")}} name='food' />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من النادي")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من النادي</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من مطعم مختص")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من مطعم مختص</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من وصفات المدربين")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من وصفات المدربين</label>
           </div>
           <div style={{display:`${plan===1?"flex":"none"}`,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" onClick={()=>{setfood("من النت")}} name='food'  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>من النت</label>
           </div>
                    </div>
                    <div className="five-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س5. هل حضرت أي من الألعاب الرياضية المعروضة أدناه؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>غير منتظم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جري</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>سباحه</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>ركوب دراجة</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>تزلج</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>رياضات عنيفة</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>فنون قتالية</label>
           </div>
                                   
                        
                        
                    </div>
                    <div className="six-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س6. كيف تقوم بمعظم تمارينك الرياضية المعتادة؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("وحدي")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>وحدي</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("مع مدرب")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مع مدرب</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("مع مجموعة")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>مع مجموعة</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("في النادي")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>في النادي</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("علي النت")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>علي النت</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("موقع")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>موقع</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='typesp' onClick={()=>{setsporttype("جروب")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>جروب</label>
           </div>
                       
                                   
                        
                        
                    </div>
                    <div className="seven-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س7. هل تحاول تحسين آدائك الرياضي؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='sportenhance' onClick={()=>{setsportenhance(1)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='sportenhance' onClick={()=>{setsportenhance(0)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="eight-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س8. هل تدخن السجائر؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='cigarettes' onClick={()=>{setcigarettes(1)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='cigarettes' onClick={()=>{setcigarettes(0)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="nine-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س9. هل لديك أي قيود طبية تمنعك من ممارسة الرياضة؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='medicalsport' onClick={()=>{setmedicalrestrictions("نعم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='medicalsport' onClick={()=>{setmedicalrestrictions("لا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
           <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",width:"100%"}}> 
            <p style={{color:"red",fontWeight:"bold"}}> غير ذلك ... يرجي التحديد</p>
            <input type="text" placeholder='هل لديك أي قيود طبية تمنعك من ممارسة الرياضة؟' onChange={(e)=>{setmedicalrestrictions(e.currentTarget.value)}}  />
           </div>
           
                      
                                   
                        
                        
                    </div>
                    <div className="ten-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س10. هل تعمل مع المدربين لتناول نظام غذائي متوازن لتعزيز حالتك؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='workwithdiettrainer' onClick={()=>{setdiet(1)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='workwithdiettrainer' onClick={()=>{setdiet(0)}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="eleven-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س11. هل تستهلك المكملات الغذائية للألعاب الرياضية؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='supplements' onClick={()=>{setsupplements("نعم")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='supplements' onClick={()=>{setsupplements("لا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio" name='supplements' onClick={()=>{setsupplements("احيانا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>احيانا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                    <div className="twinty-question" style={{    display: "flex",
            flexDirection: "column",
            alignItems: "end",
            justifycontent: "center"}}>
                        <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>س12. هل تشارك بنشاط وبشكل منتظم في الرياضة المفضلة لديك؟</p></div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='favoritesport' onClick={()=>{setfavorite("نعم")}} />
                        <label htmlFor="" style={{    fontSize: "20px"}}>نعم</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='favoritesport' onClick={()=>{setfavorite("لا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>لا</label>
           </div>
                        <div style={{display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row-reverse",
            gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="radio"  name='favoritesport' onClick={()=>{setfavorite("احيانا")}}  />
                        <label htmlFor="" style={{    fontSize: "20px"}}>احيانا</label>
           </div>
                      
                                   
                        
                        
                    </div>
                <div className="thirty-question" style={{    display: "flex",
        flexDirection: "column",
        alignItems: "end",
        justifycontent: "center"}}>
                    <div className="question"><p style={{    color: "#000",fontSize:"14px",textAlign:"end",fontWeight:"bold"}}>هل تعاني من أمراض؟</p></div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("ضغط")} onChange={()=>handlechangecheck("ضغط")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>ضغط</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("سكر")} onChange={()=>handlechangecheck("سكر")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>سكر</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>هشاشة عظام</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("خشونة عظام")} onChange={()=>handlechangecheck("خشونة عظام")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>خشونة عظام </label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("كسل الغدة الدرقية")} onChange={()=>handlechangecheck("كسل الغدة الدرقية")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>كسل الغدة الدرقية</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("فقرات الظهرات")} onChange={()=>handlechangecheck("فقرات الظهر")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>فقرات الظهر</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("فقرات العنق")} onChange={()=>handlechangecheck("فقرات العنق")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>فقرات العنق</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("تكيسات المبايض")} onChange={()=>handlechangecheck("تكيسات المبايض")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>تكيسات المبايض</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("دوالي")} onChange={()=>handlechangecheck("دوالي")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>دوالي</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("نشاط هرمونات")} onChange={()=>handlechangecheck("نشاط هرمونات")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>نشاط هرمونات</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("كسل  هرمونات")} onChange={()=>handlechangecheck("كسل هرمونات")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>كسل هرمونات</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("عسر هضم")} onChange={()=>handlechangecheck("عسر هضم")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>عسر هضم</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("امساك مزمن")} onChange={()=>handlechangecheck("امساك مزمن")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>امساك مزمن</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("بواسير")} onChange={()=>handlechangecheck("بواسير")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>بواسير</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("التهاب أعصاب")} onChange={()=>handlechangecheck(" التهاب أعصاب")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>التهاب أعصاب</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("أمراض الاعصاب")} onChange={()=>handlechangecheck("أمراض الاعصاب")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>أمراض الاعصاب</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  checked={selectedValues.includes("مرض نفسي")} onChange={()=>handlechangecheck("مرض نفسي")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>مرض نفسي</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox"  checked={selectedValues.includes("مرض وراثي")} onChange={()=>handlechangecheck("مرض وراثي")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>مرض وراثي</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("أمراض الدم")} onChange={()=>handlechangecheck("أمراض الدم")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>أمراض الدم</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("حساسية الغذاء")} onChange={()=>handlechangecheck("حساسية الغذاء")}  />
                    <label htmlFor="" style={{    fontSize: "20px"}}>حساسية الغذاء</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("تقرحات المعدة")} onChange={()=>handlechangecheck("تقرحات المعدة")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>تقرحات المعدة</label>
       </div>
        <div style={{display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row-reverse",
        gap: "15px"}}> <input style={{width: "20px",height: "20px"}} type="checkbox" checked={selectedValues.includes("تقرحات الامعاء")} onChange={()=>handlechangecheck("تقرحات الامعاء")}   />
                    <label htmlFor="" style={{    fontSize: "20px"}}>تقرحات الامعاء</label>
       </div>
                  
                               
                    
                    
                </div>

                
    
    </div>
          }
<div className="questions-healthy">
    <p style={{ color: "red",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "bold"}}>اذا كنت تعاني من أمراض عبر عنها في شرح ذلك</p>
    <div className="one" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p>هل ترى نفسك سمينا </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setfat(e.currentTarget.value)}} />
    </div>
    <div className="two" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>هل ذلك يزعجك </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setannoy(e.currentTarget.value)}} />
    </div>
    <div className="three" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>هل يوجد أحد أفراد العائلة يعاني من السمنة </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setfamily(e.currentTarget.value)}} />
    </div>
    <div className="four" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>هل تجد تامر او سخرية بسبب السمنة </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setbullying(e.currentTarget.value)}} />
    </div>
    <div className="five" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>كم تتوقع تفقد وزن </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setexpectlose(e.currentTarget.value)}} />
    </div>
    <div className="six" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{color:"#000",direction:"rtl",paddingTop:"10px"}}>أيهما ذو أهمية بالنسبة لك الوزن ام الحجم </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setwightorsize(e.currentTarget.value)}} />
    </div>
    <div className="seven" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>هل جربت نزول الوزن قبل هذه المرة</p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setlosing(e.target.value)}} />
    </div>
    <div className="eight" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>هل كان مع نادي ام اجتهاد شخصي  </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setclup(e.target.value)}} />
    </div>
    <div className="nine" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>ماذا كانت النتيجة</p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setresult(e.target.value)}} />
    </div>
    <div className="ten" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{direction:"rtl",color:"#000",paddingTop:"10px"}}>ما اسم مدربك في creen</p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{settrainername(e.currentTarget.value)}} />
    </div>
    <div className="eleven" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{color:"#000",direction:"rtl",paddingTop:"10px"}}>ما مدى رضاك عن creen </p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{sethappiness(e.currentTarget.value)}} />
    </div>
    <div className="tweleve" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{color:"#000",direction:"rtl",paddingTop:"10px"}}>كيف اشتركت مع creen</p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setsubscription(e.currentTarget.value)}} />
    </div>
    <div className="thirty" style={{display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3px"}}>
        <p style={{color:"#000",direction:"rtl",paddingTop:"10px"}}>هل تنصح احد بذلك</p>
        <input type="text" style={{background: "rgba(203, 203, 203, 0.31)"}} onChange={(e)=>{setrecommendation(e.currentTarget.value)}} />
    </div>
    
</div>

                    <div className="infobody" style={{display:"flex"}}>
           <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
           <img src={body} alt="" style={{    height: "255px",
    width: "43%",
    position: "relative",
    top: "86px",
    left: "59%",
    transform: "translateX(-90%)"}} />
    <div className="point" style={{    position: "relative",
    top: "80px",
    left: "22%",
    textAlign: "center"}}>
        <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}}className='كتف'>{ktf.textContent}</div>
        <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}} className='صدر' >{sd.textContent}</div>
        <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}}className='بطن'>{ kh.textContent}</div>
        <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}}className='خصر'>{bt.textContent}</div>
        <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}}className='ذراع'>{zr.textContent}</div>
    <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}}className='اردف'>{ar.textContent}</div>
    <div style={{    fontSize: "10px",
    color: "#000057",
    fontWeight: "bold"}}className='فخذين'>{fkh.textContent}</div>
    </div>
           </div>
            <div className="bd" style={{    display: "flex",
    justifyContent: "center",
    alignItems: "center"}}>
            <div className="informationbody" style={{display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",gap:"5px",
    left: "-14px",
    width: "20%",
}}>
             <div className="he" style={{    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    left: "-50px",
    gap: "14px",
    width: "100%"}}>
    <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px",    marginLeft: "-27px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>الطول</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setheight(e.currentTarget.value)}} />
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>الوزن</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setweight(e.currentTarget.value)}} />
             </div></div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط الكتفين</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}}  type="text" onChange={(e)=>{setshoulders(e.currentTarget.value)}} />
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط الصدر</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setchest(e.currentTarget.value)}} />
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط البطن</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setbelly(e.currentTarget.value)}} />
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط الخصر</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setwaist(e.currentTarget.value)}} />
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط الذراعية</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text"  onChange={(e)=>{setarm(e.currentTarget.value)}}/>
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط الارداف</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setbuttocks(e.currentTarget.value)}} />
             </div>
             <div className="height" style={{    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "5px"}}>
                <p style={{color:"#000",marginBottom:"0px"}}>محيط الفخذين</p>
                <input style={{    border: "none",
    borderBottom: "2px solid rgba(203, 203, 203, 0.31)",
    width: "50px",
    outline: "none",
    textAlign: "center",background:"rgba(203, 203, 203, 0.31)"}} type="text" onChange={(e)=>{setthighs(e.currentTarget.value)}} />
             </div>
            
            </div>
 </div>         </div>
 <div className="fotter">
    <p style={{color: "red",
    fontWeight: "bold",
    textAlign: "center",
    direction: "rtl"}}>
شكرا لكم سوف نقوم بعمل برنامج خاص بكم 
في برنامج creen </p>
 </div>
 <button style={{width: "335px",
    border: "none",
    background: "#0ab5f6",
    color: "white",
    padding: "10px",
    borderRadius: "10px",marginBottom:"10px"}} onClick={()=>{upload()}} >تسجيل </button>

            
            
        </form>
            </div>
            <Footer/>
   </>
  )
}

export default Poll