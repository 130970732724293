import React,{useEffect,useState} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
// import Header from '../components/Header/Header'
import { Link } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import "../styles/livenow.css"
import { toast } from 'react-toastify'
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"
const Livenow = () => {
    const user_id=localStorage.getItem('user_id')
const token=Cookiesservices.get('jwt')

  const [followerss, setfellowerss] = useState("")
  
  // useEffect(() => {
  //   const followers = async () => {
  //     try {
  //       const response = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${user_id}`, {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`
  //           }
  //         })
  //       if (response.status === 200) {
  //         // setfellowerss((prev)=>[...prev , ...response.data.data])
  //         //  console.log(followerss)
  //         setfellowerss(response.data.data)
  
  //       }
  //     } catch (error) { }
  //   }
    
  //   followers()
    
  // }, [token, user_id])

  const[showone,setshowone]=useState(false)
  // const showmodalone=()=>{
  //   setshowone(!showone)
  //   document.body.style.overflow = 'hidden';
  // }
  const[showtwo,setshowtwo]=useState(false)
  const[showfour,setshowfour]=useState(false)
const check=localStorage.getItem('check')

  const showmodaltwo=()=>{
    if( check==="true" || followerss>1000){

      setshowtwo(!showtwo)
      document.body.style.overflow = 'hidden';

    }else{
      setshowfour(!showfour)
      document.body.style.overflow = 'hidden';
  
    }
 

  }
  const[showthree,setshowthree]=useState(false)
  // const showmodalthree=()=>{
  //   setshowthree(!showthree)
  //   document.body.style.overflow = 'hidden';
  // }
  const close=()=>{
    setshowone(false)
    document.body.style.overflow = 'auto';
  }
  const closetwo=()=>{
    setshowtwo(false)
    document.body.style.overflow = 'auto';
  }
  const closethree=()=>{
    setshowthree(false)
    document.body.style.overflow = 'auto';
  }
  const closefour=()=>{
    setshowfour(false)
    document.body.style.overflow = 'auto';
  }

const [live,setlive]=useState([])
// useEffect(()=>{
//   const fetchlive=async()=>{
//       const respose=await axios.get(`https://creen-program.com/api/video/live`,{
//           headers:{
//               Authorization:`Bearer ${token}`
//           }
//       })
//       setlive(respose.data.data)
//   }
//   fetchlive()
// },[ token])
const [title,settitle]=useState('')
const [url,seturl]=useState('')
const [img,setimg]=useState([])
const addlive = async () => {
  const formData = new FormData();
  formData.append(`title`, title);
  formData.append("url", url);
  formData.append("image", img);

  try {
    const response = await axios.post(
      'https://creen-program.com/api/zoom3',
      formData,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );


    // Handle response if needed
    if(response.data.status===true){
      document.body.style.overflo="auto"

      toast.success('تم اضافة بث ')
      setshowtwo(false)
      window.location.reload()
    }
  } catch (error) {
    // Handle error
    toast.error('Error adding live:', error);
  }
};
const requestt = async () => {
  try {
    const response = await axios.post(
      "https://creen-program.com/api/zoom/join",
      null, // No data to send in the request body
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    // Handle response if needed
    if(response.data.status===true){
      document.body.style.overflow = 'hidden';
      setshowfour(false)
      setshowtwo(!showtwo)
    
    


    }
    // console.log(response.data); // Log response data for debugging or further handling
  } catch (error) {
    // Handle error
    console.error("Error making request:", error);
  }
};
const [checkststus,setcheckststus]=useState()
// useEffect(()=>{
//   const check = async () => {
//     try {
//       const response = await axios.post(
//         "https://creen-program.com/api/zoom/status",
//         null, // No data to send in the request body
//         {
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );
  
//       // Handle response if needed
//       if(response.data.status===true){
//   setcheckststus(response.data.status)
//   localStorage.setItem('check',checkststus)
      
  
  
//       }
//      // Log response data for debugging or further handling
//     } catch (error) {
//       // Handle error
//       console.error("Error making request:", error);
//     }
//   };
//   check();
// })
const deleteMetting = async (meeting_id) => {
  try {
    const response = await axios.post(
    `https://creen-program.com/api/zoom/deleteMeeting?meeting_id=${meeting_id}`,
      null, // No data to send in the request body
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    // Handle response if needed
    if(response.data.status===true){
// window.location.reload()
toast.success("تم حذف البث")
setshowalert(false)
    }
    // console.log(response.data); // Log response data for debugging or further handling
  } catch (error) {
    // Handle error
    console.error("Error making request:", error);
  }
};
const [showalert,setshowalert]=useState(false)
useEffect(() => {
  window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
}, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
useEffect(() => {
  const fetchFollowers = async () => {
    try {
      const followersResponse = await axios.post(`https://creen-program.com/api/followers/followerscount?user_id=${user_id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (followersResponse.status === 200) {
        setfellowerss(followersResponse.data.data);
      }
    } catch (error) {
      console.error("Error fetching followers:", error);
    }
  };

  const fetchLiveVideos = async () => {
    try {
      const liveResponse = await axios.get(`https://creen-program.com/api/video/mylive`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setlive(liveResponse.data.data);
    } catch (error) {
      console.error("Error fetching live videos:", error);
    }
  };

  const checkZoomStatus = async () => {
    try {
      const response = await axios.post("https://creen-program.com/api/zoom/status", null, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.status === true) {
        setcheckststus(response.data.status);
        localStorage.setItem('check', checkststus);
      }
    } catch (error) {
      console.error("Error checking Zoom status:", error);
    }
  };

  fetchFollowers();
  fetchLiveVideos();
  checkZoomStatus();
}, [checkststus, token, user_id]);
return (
    <>
    <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between",flexDirection:"column", gap: "19px" }}>
<div className="live_now" style={{position:"relative",height:"100%",minHeight:"100vh",background:`${showone || showtwo || showthree||showfour ||showalert?"#ddd":"#fff"}`}}>
    <div className="head_live">
        <div className="buttons_live">
            {/* <button onClick={()=>{showmodalthree()}}>اضافة بث مجدول</button> */}
            <button onClick={()=>{showmodaltwo()}}>اضافة بث خارجي</button>
            {/* <button onClick={()=>{showmodalone()}}>اضافة بث</button> */}
        </div>
        <span className='span_live'>البث المباشر</span>
    </div>
    <table className='table_live'>
            <thead className='thead_live'>
                <tr className='tr_live'>
                <th scope="col">#</th>
                <th scope="col">عنوان البث</th>
                <th scope="col">المستضيف</th>
                <th scope="col">وقت البث</th>
                <th scope="col">العمليات</th>
                </tr>
            </thead>
            <tbody className='body_live' style={{position:"relative"}}>
            {
                          live.length===0?<p style={{    color: "#000",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            fontSize: "40px"}}>لا يوجد بث</p>:live.map((item,index)=>(
                            <>
                            <tr className='td_live' key={index}>
                            <td>{item.id}</td>
                            <td>{item.title.slice(0,10)}</td>
                            <td>{item.user.name.slice(0,10)}</td>
                            <td style={{direction:"ltr"}}>{item.time_ago.slice(0,8)}</td>
                            <td className='buttonstd_live'>
                                <button className='table_button' ><Link style={{textDecoration:"none",color:"#fff"}} to={item.join_url}>الدخول للبث</Link></button>
                                <button className='table_button' onClick={()=>{setshowalert(!showalert)}} >حذف البث</button>
                            </td>
                        </tr>
                        
                            {showalert&&
                              <div className='alert alert-livenow' style={{    width: "400px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#fff"}}>
                                                  <h4>هل تريد حذف البث ؟</h4>
                                                  <div style={{    width: "100%",
                              display: "flex",
                              justifyContent: "space-around",
                              flexDirection: "row-reverse"}}>
                                                    <button style={{    background: "#08ac08",
                              color: "#fff",
                              border: "none",
                              padding: "5px",
                              borderRadius: "4px",
                              width: "50px"}} onClick={()=>{deleteMetting(item.id)}}>نعم</button>
                                                    <button style={{    background: "red",
                              color: "#fff",
                              border: "none",
                              padding: "5px",
                              borderRadius: "4px",
                              width: "50px"}} onClick={()=>{setshowalert(false)}} >لا</button>
                                                  </div>
                                                
                                                </div>
                            }</>
                            
                          ))
                         }
                                
            </tbody>
            </table>

        {
            showone && <div className="one_model">
            <div className="h">
                <span onClick={()=>(close())}><i  class="ri-close-line"></i></span>
                <span>اضافة بث</span>
            </div>
            <div className="formms">
                <input className='formms_text' type="text" placeholder='عنوان البث' />
                
                <input  type="file"  name="image" accept="image/*" />
            </div>
            <div className="butsss">
                <button className='butsss-add'>اضافه</button>
                <button className='butsss-remove'>الغاء</button>
            </div>
            </div> 
        }
        {
            showtwo && <div className="two_model">
            <div className="h">
                    <span onClick={()=>{closetwo()}}><i class="ri-close-line"></i></span>
                    <span>اضافة بث</span>
                </div>
                <div className="formms">
                    <input type="text" onChange={(e)=>{settitle(e.currentTarget.value)}} placeholder='عنوان البث' />
                    <input type="text" onChange={(e)=>{seturl(e.currentTarget.value)}} placeholder='لينك البث' />
                    
                    <input type="file"  name="image" onChange={(e)=>{setimg(e.currentTarget.files[0])}} accept="image/*" />
                </div>
                <div className="butsss">
                    <button className='butsss-add' onClick={()=>{addlive()}} >اضافه</button>
                    <button className='butsss-remove'>الغاء</button>
                </div>
            </div>
        }
 {
    showthree&&       
    <div className="three_model">
    <div className="h">
            <span onClick={()=>{closethree()}}><i class="ri-close-line"></i></span>
            <span>اضافة بث</span>
        </div>
        <div className="formms">
            <input type="text" placeholder='عنوان البث' />
            
            <input type="file"  name="image" accept="image/*" />
            <input type="datetime-local" style={{padding: "12px",
display: "flex",
width: "90%",
borderRadius: "4px",
border: "1px solid green",
outline: "1px solid green"}} />
        </div>
        <div className="butsss">
            <button className='butsss-add'>اضافه</button>
            <button className='butsss-remove'>الغاء</button>
        </div></div>   
 }
 {
  showfour&&
 <div>
   <div className='Exception-Request'>
  <h4>هل لديك الرغبة ان تصنع بث مباشر؟</h4>
  <p>يجب ان يتجاوز عدد المتابعين لك 1000 متابع</p>
  <div style={{    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"}}>
    <button style={{    border: "none",
    padding: "5px",
    background: "#08ac08",
    borderRadius: "4px",color:"#fff",
    width: "215px"}} onClick={()=>{requestt()}}>طلب استثناء</button>
    <button style={{
      background: "#7c7a7a",
      border: "none",
      padding: "3px 5px",
      borderRadius: "4px",
      color: "#fff",
      width: "40px",
      height: "35px"
    }} onClick={closefour}><i class="ri-close-line"></i></button>
  </div>


 </div>
 </div>
 }

</div>

        

      </section>
      <LinksHome />

    </section>
  <Footer/>

  </>  
  )
}

export default Livenow