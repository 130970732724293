import { configureStore } from "@reduxjs/toolkit";
import {persistReducer,persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";


import cartSlice from "./slices/cartSlice";
const persitconfig={
    key :"cart",
    storage,
}
const persistcart=persistReducer(persitconfig,cartSlice)
const store=configureStore({
    reducer:{
        cart:persistcart,
    },
});

export const  presistor= persistStore(store);
export default store;