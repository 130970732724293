import React, { useEffect, useState } from 'react'
import { Link, useParams  } from 'react-router-dom'
// import Header from '../components/Header/Header';
import ReactStars from 'react-stars'
import Headermob from "../components/headermobile/Headermob"
import axios from 'axios';
//import Cookiesservices from '../Services/Cookiesservices';
import "../styles/productTetails.css"
import Cookiesservices from '../Services/Cookiesservices'
import { useDispatch } from 'react-redux'
import { cartActions } from '../redux/slices/cartSlice'
import {  toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Footer from "../components/footer/Footer"




const ProductDetails = () => {
    const { id } = useParams();
    const [counter, setCounter] = useState(0);
    const [data, setData] = useState([]);
    const [dataSpecial, setDataSpecial] = useState([]);
    const [datanew, setDatanew] = useState([]);
    const [page, setPage] = useState(1);
    const token = Cookiesservices.get('jwt');
    const navigate=useNavigate()

useEffect(() => {
    const fetchData = async () => {
        try {
            // التحقق من وجود الـ id والـ token
            if (!id) {
                // console.error('Error fetching data: Invalid id or token');
                return;
            }

            // إرسال طلب لجلب البيانات باستخدام الـ id
            const productResponse = await axios.post(`https://creen-program.com/api/products/view?product_id=${id}`, {}, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json',
                }
            });

            // التحقق من وجود الاستجابة والبيانات
            if (productResponse && productResponse.data && productResponse.data.data) {
                // تحديث البيانات باستخدام البيانات الجديدة
                setData([productResponse.data.data]);
            } else {
                // console.error('Error fetching data: Invalid response format');
            }
        } catch (error) {
            // تسجيل أي أخطاء في جلب البيانات
            // console.error('Error fetching data:', error);
        }
    };

    const fetchSpecialAndNewData = async () => {
        try {
            // إرسال طلب لجلب البيانات الخاصة
            const specialResponse = await axios.post(`https://creen-program.com/api/products/special`, {}, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            // إرسال طلب لجلب البيانات الجديدة
            const newResponse = await axios.post(`https://creen-program.com/api/products/new?page=${page}`, {}, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            // التحقق من وجود الاستجابة والبيانات لكل طلب
            if (specialResponse && specialResponse.data && specialResponse.data.data && specialResponse.data.data.products && specialResponse.data.data.products.data &&
                newResponse && newResponse.data && newResponse.data.data && newResponse.data.data.products && newResponse.data.data.products.data) {
                // تحديث البيانات الخاصة باستخدام البيانات الجديدة
                setDataSpecial(specialResponse.data.data.products.data);
                // تحديث البيانات الجديدة باستخدام البيانات الجديدة
                setDatanew(prevData => [...prevData, ...newResponse.data.data.products.data]);
            } else {
                // console.error('Error fetching special or new data: Invalid response format');
            }
        } catch (error) {
            // تسجيل أي أخطاء في جلب البيانات
            // console.error('Error fetching special or new data:', error);
        }
    };

    fetchData();
    fetchSpecialAndNewData();
}, [id, page, token]);

    const increase = () => {
        setCounter(counter + 1);
    };

    const decrease = () => {
        setCounter(prevCounter => prevCounter > 0 ? prevCounter - 1 : 0);
    };

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            setPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

  const [tabb,settabb]=useState('')
  
    const dispatch=useDispatch()
     const imgurl=data.map((item)=>(item.image !==null ? item.image.url : item.category.icon))
     const title=data.map((item)=>(item.title))
     const price=data.map((item)=>(item.price))
    
    const addToCart=()=>{
        dispatch(cartActions.addItem({
          id:id,
          productName:title,
          price:price,
          imgUrl: imgurl
        }))
        
        toast.success('Product added successfully')
      }
      useEffect(() => {
        window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
      }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  return (
   <>
<Headermob/>  

 {
        data.map((item)=>(
            <div className="productDetails" >
        <section style={{display:"flex" ,justifyContent: "space-around" , background:"#fff",padding:"0px 10px"}}>
            <div className="left" style={{width: "20%",background: "#fff",borderRadius: "5px",marginTop: "10px",padding: "10px",border: "1px solid #ddd",height:"max-content"}}>
                <div className="location" style={{display: "flex",justifyContent: "space-between",borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>
                    <div className="left">مصر <span><i class="ri-map-pin-line"></i></span></div>
                    <div className="right">شحن إلي</div>
                </div>
                <div className="Delivery" style={{paddingTop: "5px",borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>
                    <span style={{display:"flex" , justifyContent:"end",alignItems:"center"}}><i class="ri-arrow-left-line"></i>Delivery</span>
                    <div className="DeliveryShipping" style={{textDecoration: "none",display: "flex",flexDirection: "column",justifyContent: "end",alignItems: "end"}}>
                        <Link style={{textDecoration: "none",color:"#000"}}>شحن مجاني</Link>
                        <Link style={{ textDecoration: "none",color: "green",display: "flex",alignItems: "center",fontWeight: "bolder"}}>
                            <span style={{    display: "block",fontSize: "10px"}}> توصيل سريع على الطلبات أكثر من  <span>145 ريال سعودي</span></span>
                            <span><i class="ri-car-line"></i></span>
                        </Link>
                        <Link style={{textDecoration: "none",color:"#000"}}>توصيل في <span>16 فبراير</span></Link>
                        <Link style={{textDecoration: "none",color:"#000",paddingTop:"15px"}}> او توصيل في <span>23 مارس</span></Link>
                        
                    </div>
                    
                </div>
                <div className="services" style={{paddingTop: "5px",borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>
                    <Link style={{display:"flex" , justifyContent:"end",alignItems:"center" , textDecoration:"none",color:"#000"}}><i class="ri-arrow-left-line"></i>Services</Link>
                    <Link style={{textDecoration: "none",color: "#000",fontSize: "12px",fontWeight: "bold",display: "flex",justifyContent: "end"}}>حمايه المشتري</Link>
                </div>
                <div className="count" style={{display:"flex",flexDirection: "column",gap: "10px",alignItems:"end",paddingTop:"5px"}}>
                    <span>الكمية</span>
                    <div className="counter" style={{display:"flex",alignItems: "center",gap: "15px"}}>
                        <span onClick={increase} style={{background: "#ddd",borderRadius:"50%",cursor:'pointer'}}><i class="ri-add-fill" style={{padding:"5px"}}></i></span>
                        <span>{counter}</span>
                        <span onClick={decrease} style={{background: "#ddd",borderRadius:"50%",cursor:'pointer'}}><i class="ri-subtract-fill" style={{padding:"5px"}}></i></span>
                    </div>
                </div>
                <button className="buy" style={{width: "100%",border: "none",padding: "10px",borderRadius: "50px",
                background: "#fd384f",color:"#fff",textAlign: "center",marginTop: "10px",cursor: "pointer"}}>
                    شراء الان</button>
                <button className="addCart" style={{width: "100%",border: "none",padding: "10px",borderRadius: "50px",
                background: "#ffe6e7",color:"#fd384f",textAlign: "center",marginTop: "10px",cursor: "pointer"}} onClick={()=>{token?addToCart():navigate("/login")}}>
                    أضف إلي العربة</button>
                    <div className="share-like" style={{display: "flex",justifyContent: "space-evenly",marginTop: "10px",alignItems:"center"}}>
                        <button style={{ padding: "10px 20px",borderRadius: "24px",background: "transparent"}}><i class="ri-heart-line"></i></button>
                        <button style={{ width: "60%",padding: "10px",borderRadius: "50px",background: "transparent",textAlign: "center",cursor: "pointer"}}>مشاركة<i class="ri-share-forward-line"></i></button>
                    </div>
            </div>
            <div className="right" style={{width:"70%"}} >
             <div className="top" style={{display: "flex",marginTop: "10px",gap:"25px",justifyContent:"end"}}>
             <div className="product-info" style={{height: "max-content",display: "flex",flexDirection: "column",alignItems: "end"}}>
    <div className="price" style={{display: "flex",flexDirection: "row-reverse",alignItems: "center",gap: "6px"}}><span style={{fontSize:"20px"}}>{item.price}</span><span style={{fontSize:"15px"}}>ريال سعودي</span></div>
    <div className="desc" style={{textAlign:"end"}}><span style={{textAlign:"end"}}>{item.description}</span></div>
    <div className="rate">
    <ReactStars
  count={5} 
  value={item.rates}
  size={24}
  color={'#ffd700'} />
    </div>
    
   

</div>
<>
{
    item.image != null ? <div className="product-img" style={{border: "1px solid #ddd",height: "max-content",borderRadius: "10px",padding: "5px"}}><img src={item.image.url} style={{width:"450px",height:"450px"}} alt="" /></div>: item.image == null ?
    <div className="product-img" style={{border: "1px solid #ddd",height: "max-content",borderRadius: "10px",padding: "5px"}}><img src={`${item.category.icon}`} style={{width:"450px",height:"450px"}} alt="" /></div>
    :<></>
}

</>
             </div>
             <div className="bootom">
             <div className="productTetails_buttons" style={{    display: "flex",
    justifyContent: "end",
    marginTop: "10px",
    marginRight: "10px",
    gap: "60px"}}>
                <button className={ `${tabb==='rate' ? 'prodet_active': ''} `} onClick={()=>settabb('rate')} style={{border: "none",background: "transparent"}}>التقيمات</button>
                <button className={ `${tabb==='desc' ? 'prodet_active': ''} `} onClick={()=>settabb('desc')} style={{border: "none",background: "transparent"}}>المواصفات</button>
    
             </div>
             <div className="content" style={{marginTop:"5px",direction:"rtl"}}>
                {
                    tabb === "rate" ? <>
                    <div className="rates">
                    {
                        item.rates === 0 ? <span style={{direction: "rtl",
                            textAlign: "center",
                            display: "block",
                            padding: "10px",
                          fontSize: "22px"}}>لا يوجد تقييمات !</span>: <></>
                    }
                    </div>
                    
                    </>:<>
                    <div className="descr" style={{    display: "flex",
    flexDirection: "column",
    gap: "15px"}}>
                        <span>الاسم : {item.title}</span>
                        <span>السعر : <span>ريال سعودي<span></span>{item.price}<span></span></span></span>
                        <span>الوصف : {item.description}</span>
                        <span>النوع : {item.category.name}</span>
                        <span style={{direction:"rtl"}}>الحاله : {item.details.status}</span>
                    </div>
                    </>
                }
             </div>
             </div>
            </div>
        </section>
          </div>
        ))
    }
      <section style={{background:"#fff",marginTop:"20px",padding:"10px"}}>
      <div className="tit" style={{    padding: "10px",
    display: "flex",
    justifyContent: "end"}}><h3 style={{textAlign: "center",
    borderBottom: "1px solid #ddd",
    paddingBottom: "5px"}}>المنتجات الخاصة</h3></div>
        <div className="products">
               {
        dataSpecial.map((item , index)=>(
            item.image != null ?
            <Link  className="product" key={index}>
            <div className="img_product"><img src={item.image.url} style={{borderRadius:"10px",width:"190px",height:"160px"}} alt="" /></div>
            <div className="desc"><p>{item.description}</p></div>
            <div className="price" style={{display:"flex",justifyContent:"end",alignItems:"center"}}><span>ريال سعودي</span><span style={{fontSize:"larger"}}> {item.price}</span></div>
           <Link ><button>شاهد الملخص</button>
</Link>         </Link>
            :item.image === null ?<Link  className="product" key={index}>
            <div className="img_product"><img src={item.category.icon} style={{borderRadius:"10px",width:"190px",height:"160px"}} alt="" /></div>
            <div className="desc"><p>{item.description}</p></div>
            <div className="price" style={{display:"flex",justifyContent:"end",alignItems:"center"}}><span>ريال سعودي</span><span style={{fontSize:"larger"}}> {item.price}</span></div>
            <Link ><button>شاهد الملخص</button></Link>
        </Link>:<></>
        ))
    }</div>
        </section>
      <section style={{background:"#fff",marginTop:"20px",padding:"10px"}}>
      <div className="tit" style={{    padding: "10px",
    display: "flex",
    justifyContent: "end"}}><h3 style={{textAlign: "center",
    borderBottom: "1px solid #ddd",
    paddingBottom: "5px"}}>المنتجات الجديدة</h3></div>
        <div className="products">
               
        {
            datanew.map((item , index)=>(
                item.image != null ?
                <Link to={`/products/${item.id}`}  className="product" key={index}>
                <div className="img_product"><img src={item.image.url} style={{borderRadius:"10px",width:"190px",height:"160px"}} alt="" /></div>
                <div className="desc"><p>{item.description}</p></div>
                <div className="price" style={{display:"flex",justifyContent:"end",alignItems:"center"}}><span>ريال سعودي</span><span style={{fontSize:"larger"}}> {item.price}</span></div>
               <Link><button>شاهد الملخص</button>
    </Link>         </Link>
                :<></>
            ))
        }
    </div>
        </section>
        <Footer/>

    </>
  )
}

export default ProductDetails