import React , {useState , useEffect} from 'react'
import "../styles/points.css"
// import Header from '../components/Header/Header'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
import Headermob  from '../components/headermobile/Headermob'
import Footer from '../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import ChatHome from '../components/chat-home/ChatHome'
import LinksHome from '../components/links-home/LinksHome'
const Points = () => {
    const token=Cookiesservices.get('jwt')
    const [points,setpoints]=useState([])
    const[totalpoints,settotalpoints]=useState()
    useEffect(() => {
      const fetchPoints = async () => {
        try {
          const response = await axios.post(
            'https://creen-program.com/api/points',
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
          // يمكنك هنا التعامل مع الاستجابة بحسب احتياجاتك
          console.log('Response:', response.data);
          setpoints(response.data.data.points.data)
          settotalpoints(response.data.data.total)
        } catch (error) {
          // يمكنك هنا التعامل مع الخطأ في حال حدوثه
          console.error('Error fetching points:', error);
        }
      };
    
      fetchPoints();
    }, [token]);
   const navigate=useNavigate();
    useEffect(() => {
      window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
    }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  return (
    <>
   <Headermob/>
   < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
        <ChatHome />
        <section className='center' style={{ width: "59%", display: "flex", justifyContent: "center", gap: "19px" }}>

        <div style={{width:"100%"}}><div className="points" style={{    height: "100%",
    minHeight: "100vh"}}>
        
        <div className='point-container'>
        <h2  style={{ fontSize:" 41px",
    paddingTop: "16px",
    fontWeight: "bold"}} className='h-points' class="text-center">النقاط</h2>
        <div  className='contain' >
            <table style={{width:"100%"}}>
               {
                points.map((item,index)=>(
                  <tr key={index} style={{    borderBottom: "2px solid #0699a159"}}>
                  <td style={{    padding: "15px 0", fontSize: "30px",fontWeight: "bold",    paddingRight: "10px"}}>{item.points} نقطة <small style={{fontSize: "21px",display: "block",color: "#03777d8d"}}>{item.description}</small> </td>
              </tr>

                ))
               }             
            </table>
            <div class="main">
                <h4 style={{fontSize: "30px",fontWeight: "bold",textAlign: "center"}}> اجمالي النقاط</h4>
                <p style={{fontSize: "30px",fontWeight: "bold", textAlign: "center"}}>{totalpoints} نقطة</p>
                <button style={{  display: "block",
    width: "65%",
    margin: "10px auto 42px",
    fontSize: "25px",
    fontWeight: "bold"}} type="submit" class="btn btn-success mt-2" onClick={()=>{navigate("/wallet")}}  >OK</button>
            </div>
        </div>
    </div></div></div>

        </section>
        <LinksHome />

      </section>

   <Footer/>
</>    
  )
}

export default Points