import React from 'react'
import Routers from '../routers/Routers'
const Layout = () => {
  return (
    <>
    <div>
        <Routers/>
    </div>
    </>
  )
}

export default Layout