const Links = [
  {
    id: "01",
    name:'الاعلانات',
    imgUrl:'https://cdn0.iconfinder.com/data/icons/advertising-39/512/advertising_television_ads-4096.png',
    src:"https://creen-program.com/promotions"
    
  },
  {
    id: "02",
    name:'الخدمات',
    imgUrl:'https://th.bing.com/th/id/OIP.h0_b4NxpbkrEINrKigl9lgAAAA?rs=1&pid=ImgDetMain',
    src:"/services"
    
  },
  {
    id: "03",
    name:' الأخبار',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/home.svg',
    src:""
    
  },
  {
    id: "04",
    name:'منشورات شائعة',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/popular.svg',
    src:''
  },
  {
    id: "05",
    name:'صفحاتي',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/page.svg',
    src:'/my_topics'
  },
  
  {
    id: "13",
    name:'المتجر',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/market.svg',
    src:'/products' 
  },
 
  {
    id: "07",
    name:'عروض',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/offer.svg',
    src:''
   
  },

  {
    id: "08",
    name:'وظائف',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/job.svg',
    src:''
    
  },
  
  {
    id: "09",
    name:'البحث عن أصدقاء',
  imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/find.svg',
  src:''
    
  },
  {
    id: "10",
    name:'مجموعاتي',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/group.svg',
    src:''
  },
  {
    id: "11",
    name:'المسلسلات',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/watch.svg',
    src:'/towatch'
  },
  {
    id: "12",
    name:'مدونة',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/blog.svg',
    src:''
  },
  {
    id: "13",
    name:'المفضلة',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/save.svg',
    src:''
  
  },
  {
    id: "14",
    name:'الدليل',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/explore.svg',
    src:''
  },
  {
    id: "15",
    name:'أحداث',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/event.svg',
    src:''
  },
  {
    id: "16",
    name:'الألعاب',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/game.svg',
    src:''
  },
  {
    id: "17",
    name:'منتدي',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/forum.svg',
    src:''
  },
  {
    id: "18",
    name:'أفلام',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/movie.svg',
    src:''
  },
  {
    id: "19",
    name:'ذكريات',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/memories.svg',
    src:''
    
  },
  {
    id: "20",
    name:'الألبومات',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/album.svg',
    src:''
  },
  {
    id: "21",
    name:'الوخزات',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/poke.svg',
    src:''
  
  },
  {
    id: "22",
    name:'الأشياء المشتركة',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/common.svg',
    src:''
  },
  {
    id: "23",
    name:'بالتمويل',
    imgUrl:'https://hooyahoo.net/themes/wowonder/img/sidebar/fund.svg',
    src:''
  },

  ];

export default Links;
