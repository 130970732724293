import React,{useEffect,useState} from 'react'
import LinksHome from '../components/links-home/LinksHome'
import ChatHome from '../components/chat-home/ChatHome'
import {  useNavigate, useParams } from 'react-router-dom'
import Cookiesservices from '../Services/Cookiesservices'
import axios from 'axios'
//import ReactStars from 'react-stars'
import Usericon from "../assets/images/user-icon.png"
import Headermob from "../components/headermobile/Headermob"
import Footer from "../components/footer/Footer"
const Details= () => {
const token=Cookiesservices.get('jwt')
  // const usser_id=localStorage.getItem("Number_id")
  const[reserve,setreserve]=useState([])
  // const [items,setitems]=useState()
  const {id}=useParams();
  localStorage.setItem("id_id_id",id)
  const [user , setuser]=useState([])
  useEffect(() => {
    window.scrollTo(0, 0); // يقوم بتمرير الصفحة إلى الأعلى عند تحميل المكون
  }, []); // يتم تنفيذها مرة واحدة عند تحميل المكون
  // useEffect(()=>{
  //   const fetchdetails=async()=>{
  //       const respose=await axios.get(`https://creen-program.com/api/doctors/prescription/${id}`,{
  //           headers:{
  //               Authorization:`Bearer ${token}`
  //           }
  //       })
  //       setreserve(respose.data.data.trainer)
  //       setuser(respose.data.data.trainer.user)


  //   }
  //   fetchdetails()

  // },[id,token])
  const [dayy , setday]=useState()
  const d = new Date(reserve.date);
const time=d.toLocaleTimeString('en-us',{hours:false})
  let day=d.getDay();
//   const formattime=time.toLocaleTimeString('en-us',{hours:false})
  const timee=(time.includes("AM")?"صباحا":"مساء")
  

  // useEffect(()=>{
  //   setday(day===0?"يوم الاحد":day===1?"يوم الاثنين":day===2?"يوم الثلاثاء":day===3?" يوم الاربعاء":day===4?"يوم الخميس":day===5?"يوم الجمعه":day===6?"السبت":"")
  //   localStorage.setItem("dayy",dayy)

  // },[day, dayy])  
  const data=localStorage.getItem("dayy");
const navigate=useNavigate();
useEffect(() => {
  const fetchData = async () => {
      try {
          const response = await axios.get(`https://creen-program.com/api/doctors/prescription/${id}`, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          setreserve(response.data.data.trainer);
          setuser(response.data.data.trainer.user);
      } catch (error) {
          console.error("Error fetching data:", error);
          // يمكنك التعامل مع الأخطاء هنا
      }
  };

  fetchData();

  setday(day === 0 ? "يوم الاحد" : day === 1 ? "يوم الاثنين" : day === 2 ? "يوم الثلاثاء" : day === 3 ? "يوم الاربعاء" : day === 4 ? "يوم الخميس" : day === 5 ? "يوم الجمعه" : day === 6 ? "السبت" : "");
  localStorage.setItem("dayy", dayy);
}, [id, token, day, dayy]);

  return (
    <>
   <Headermob/>
    < section style={{ width: "100%", display: 'flex', justifyContent: "space-between" }}>
      <ChatHome />
      <section className='center' style={{ background:"#fff",width: "59%", display: "flex", justifyContent: "space-between", gap: "19px" }}>
<div style={{height:"100vh",width:"100%"}}>
{
    
        <div className="participants"  style={{    width: "100%"}}>
    <div className="right" style={{display: "flex",
    flexDirection: "row-reverse",
gap:"10px",marginTop:"10px"}}>
        <div className="right">      <img src={user.profile?user.profile:Usericon} alt="" style={{width:"60px",height:"60px",borderRadius:"50%"}} />
</div>
<div className="left"  style={{width:"100%"}}>
    <div className="info-user-subs" style={{    display: "flex",
    justifyContent: "flex-end",gap:"35px",width:"100%"}}>
      <div className="information" style={{    display: "flex",
    flexDirection: "column",width:"100%",alignItems:"end",justifyContent:"end"}}>
        
        <span style={{    fontSize: "18px",
        color: "#000",textAlign:"end"}}>{user.name}</span>
        <span style={{display:"flex",gap:"7px",alignItems:"center",width:"100%"}}>
        <div className="left" style={{    display: "flex",color:"red",
    flexDirection: "column",
    textAlign: "end",width:"100%"}}>
        <span>{data}</span>
<span style={{display:"flex",gap:"5px",justifyContent:"end"}}><span style={{display:"flex",gap:"5px"}}><span>{timee}</span><span style={{direction:"rtl"}}>{reserve.date}</span></span>
</span>
    </div>
            <button style={{border: "2px solid #05bc05",
    background: "transparent",
    padding: "2px 10px",
    borderRadius: "4px",
    color: "#05bc05",width:"35%"}}>مراجع</button></span>
        <div className="buttonsss" style={{    marginTop: "12px",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    alignItems: "end"}}>
            <button style={{border:"none",
    background: "#05bc05",
    padding: "2px 10px",
    borderRadius: "4px",
    color: "#fff",width:"100%"}}>موعد اونلاين</button>
            <button style={{    background: "#05b805",
    padding: "2px 10px",
    border: "none",
    borderRadius: "4px",
    color: "#fff",
    width: "max-content",
    display: "flex",
    direction: "rtl"}} onClick={()=>{navigate(`/memo/${id}`)}}>+تفاصيل</button>
        </div>
        
      </div>
    </div>
    </div>
    </div>
    
    
</div>
    
}
</div>

        

      </section>
      <LinksHome />

    </section>
   <Footer/>

  </>  
  )
}

export default Details